<template>
  <v-dialog
    :value="value"
    scrollable
    max-width="1200"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <v-card-title>
        Consultar registros
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small text fab @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-list two-line dense>
              <v-list-item-group color="primary">
                <!-- CLIENTE -->
                <v-list-item v-for="(i, index) in column1" :key="index">
                  <v-list-item-icon>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="i.text"> </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="i.value"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="i.action">
                    <v-btn
                      color="primary"
                      fab
                      text
                      x-small
                      @click="i.action(i)"
                      :loading="i.loadingAction"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <v-list two-line dense>
              <v-list-item-group color="primary">
                <!-- CLIENTE -->
                <v-list-item v-for="(i, index) in column2" :key="index">
                  <v-list-item-icon>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="i.text"> </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="i.value"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="i.action">
                    <v-btn
                      color="primary"
                      fab
                      text
                      x-small
                      @click="i.action(i)"
                      :loading="i.loadingAction"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>

        <div
          class="row pr-8 pl-8 mt-0 mb-0 pt-0"
          style="padding-top: 0px !important"
        >
          <div class="col-md-12">
            <h4>Adjuntos</h4>
            <v-btn
              v-for="(adj, key) in linkAdjuntos"
              :key="key"
              color="primary"
              outlined
            >
              <v-icon left> mdi-arrow-down-bold </v-icon>
              <a class="no-underline" :href="adj"> Archivo {{ key + 1 }}</a>
            </v-btn>
          </div>
        </div>
        <div
          class="row pr-8 pl-8 mt-0 mb-0 pt-0"
          style="padding-top: 0px !important"
        >
          <div class="col-md-12">
            <label for="solicitud">Solicitud</label>
            <json-viewer
              :expand-depth="5"
              :value="item?.request ?? ''"
              copyable
              boxed
            >
            </json-viewer>
            <!-- <v-form-textarea
                            v-model="solicitud"
                            id="solicitud"
                            type="text"
                            disabled
                            ></v-form-textarea> -->
          </div>
        </div>
        <div
          class="row pr-8 pl-8 mt-0 mb-0 pt-0"
          style="padding-top: 0px !important"
        >
          <div class="col-md-12">
            <label for="solicitud-canal">Solicitud hacia el canal</label>
            <json-viewer
              :expand-depth="5"
              :value="item?.requestCanal ?? ''"
              copyable
              boxed
            >
            </json-viewer>
          </div>
        </div>
        <div
          class="row pr-8 pl-8 mt-0 mb-0 pt-0"
          style="padding-top: 0px !important"
        >
          <div class="col-md-12">
            <label for="respuesta">Respuesta</label>
            <json-viewer
              :expand-depth="5"
              :value="item?.response ?? ''"
              copyable
              boxed
            >
            </json-viewer>
          </div>
        </div>
        <div
          class="row pr-8 pl-8 mt-0 mb-0 pt-0"
          style="padding-top: 0px !important"
        >
          <div class="col-md-12">
            <label for="respuesta-canal">Respuesta del Canal</label>
            <json-viewer
              :expand-depth="5"
              :value="item?.responseCanal ?? ''"
              copyable
              boxed
            >
            </json-viewer>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.row {
  padding-top: 10px !important;
}
</style>

<script>
import dayjs from "dayjs";
import BitacoraService from "@/services/manager/notifications/bitacora2.service.js";

import JsonViewer from "vue-json-viewer";

const bitacoraService = new BitacoraService();
export default {
  name: "ConsultarBitacoraModal",
  components: {
    JsonViewer,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },

    clients: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      proceso: "",
      editor: null,
      codigo: "",
      clientId: "",
      estado: "",
      mensaje: "",
      solicitud: "",
      solicitudCanal: "",
      respuesta: "",
      respuestaCanal: "",
      linkAdjuntos: [],
    };
  },
  computed: {
    column1() {
      return [
        {
          code: "01",
          text: "Código",
          value: this.item?.ack ?? "",
          action: null,
          loadingAction: false,
        },
        {
          code: "02",
          text: "Cliente",
          value: `${
            this.getClient(this.item?.client)?.nombre ?? "Desconocido"
          } - Codigo: ${this.getClient(this.item?.client)?.clientId}`,
          action: null,
          loadingAction: false,
        },
        {
          code: "03",
          text: "Fecha",
          value: dayjs(this.item?.created_at ?? "").format("DD-MM-YYYY HH:mm"),
          action: null,
          loadingAction: false,
        },
        {
          code: "04",
          text: "Método de Notificación",
          value: this.item?.metodoNotificacion,
          action: null,
          loadingAction: false,
        },
      ];
    },
    column2() {
      return [
        {
          code: "05",
          text: "Destinatario",
          value: this.item?.destinatario ?? "",
          action: null,
          loadingAction: false,
        },
        {
          code: "06",
          text: "Proceso",
          value: this.item?.proceso ?? "",
          action: null,
          loadingAction: false,
        },
        {
          code: "07",
          text: "Detalle de Envió",
          value: this.item?.message ?? "",
          action: null,
          loadingAction: false,
        },
        {
          code: "08",
          text: "Url de Servicio",
          value: this.item?.urlServicio ?? "",
          action: null,
          loadingAction: false,
        },
      ];
    },
  },
  methods: {
    getClient(id) {
      return this.clients.find((e) => e._id == id);
    },

    async getLink(url) {
      const resp = await bitacoraService.getFileUrl(url);
      return resp.data.data.url;
    },

    getAdjuntos() {
      this.linkAdjuntos = [];
      const { solicitud } = this;
      const { adjuntos } = solicitud.data;
      adjuntos.forEach(async (adjunto) => {
        const archivo = JSON.parse(adjunto);
        const v1 = await this.getLink(archivo.data.response.obtener_url);
        this.linkAdjuntos.push(v1);
      });
    },
  },
};
</script>

import { baseHttp } from "@/helpers/baseHttp.js";
import { wordGenre } from "@/store/resources/tools.js";

export const GET_COUNTRIES_LIST = ({ commit, getters }) => {
  const url = getters.GetEndpoint.paises;
  return baseHttp({
    method: "GET",
    url,
    customHeader: {},
    messages: {
      error: "Ha ocurrido un error al intentar recuperar el listado de paises.",
    },
    commitResult: true,
    commit,
    commitMethod: "setCountries",
  });
};

export const GET = ({ commit, getters }, code) => {
  const url = `${getters.GetEndpoint.byCode}/${code}`;
  commit("setItems");
  return baseHttp({
    method: "GET",
    url,
    messages: {
      error:
        "Ha ocurrido un error al intentar recuperar el listado de territorios.",
    },
    keys: {
      result: "elementos",
    },
    commitResult: true,
    commit,
    commitMethod: "setItems",
  });
};

export const POST_STATE = ({ getters }, formData) => {
  const url = getters.GetEndpoint.state;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.state) ? "el" : "la";
  return baseHttp({
    method: "POST",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar crear ${genre} ${text.state}.`,
      success: `Se ha creado ${genre} ${text.state} satisfactoriamente`,
    },
  });
};

export const POST_DISTRICT = ({ getters }, formData) => {
  const url = getters.GetEndpoint.district;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.district) ? "el" : "la";
  return baseHttp({
    method: "POST",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar crear ${genre} ${text.district}.`,
      success: `Se ha creado ${genre} ${text.district} satisfactoriamente`,
    },
  });
};

export const POST_CITY = ({ getters }, formData) => {
  const url = getters.GetEndpoint.city;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.city) ? "el" : "la";
  return baseHttp({
    method: "POST",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar crear ${genre} ${text.city}.`,
      success: `Se ha creado ${genre} ${text.city} satisfactoriamente`,
    },
  });
};

export const PUT_STATE = ({ getters }, payload) => {
  const { id, ...formData } = payload;
  const url = `${getters.GetEndpoint.state}/${id}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.state) ? "el" : "la";
  return baseHttp({
    method: "PUT",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar actualizar ${genre} ${text.state}.`,
      success: `Se ha actualizado ${genre} ${text.state} correctamente!`,
    },
  });
};

export const PUT_DISTRICT = ({ getters }, payload) => {
  const { id, ...formData } = payload;
  const url = `${getters.GetEndpoint.district}/${id}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.district) ? "el" : "la";
  return baseHttp({
    method: "PUT",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar actualizar ${genre} ${text.district}.`,
      success: `Se ha actualizado ${genre} ${text.district} correctamente!`,
    },
  });
};

export const PUT_CITY = ({ getters }, payload) => {
  const { id, ...formData } = payload;
  const url = `${getters.GetEndpoint.city}/${id}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.city) ? "el" : "la";
  return baseHttp({
    method: "PUT",
    url,
    formData,
    messages: {
      error: `Ha ocurrido un error al intentar actualizar ${genre} ${text.city}.`,
      success: `Se ha actualizado ${genre} ${text.city} correctamente!`,
    },
  });
};

export const DELETE_STATE = ({ getters }, payload) => {
  const { region, pais } = payload;
  const url = `${getters.GetEndpoint.state}/${region}/codigo_pais/${pais}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.state) ? "el" : "la";
  return baseHttp({
    method: "DELETE",
    url,
    messages: {
      error: `Ha ocurrido un error al intentar eliminar ${genre} ${text.state}.`,
      success: `Se ha eliminado ${genre} ${text.state} correctamente!`,
    },
  });
};

export const DELETE_DISTRICT = ({ getters }, payload) => {
  const { provincia, region } = payload;
  const url = `${getters.GetEndpoint.district}/${provincia}/codigo_region/${region}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.district) ? "el" : "la";
  return baseHttp({
    method: "DELETE",
    url,
    messages: {
      error: `Ha ocurrido un error al intentar eliminar ${genre} ${text.district}.`,
      success: `Se ha eliminado ${genre} ${text.district} correctamente!`,
    },
  });
};

export const DELETE_CITY = ({ getters }, payload) => {
  const { comuna, provincia } = payload;
  const url = `${getters.GetEndpoint.city}/${comuna}/codigo_provincia/${provincia}`;
  const text = getters.GetText[getters.GetCountry ?? "CL"];
  const genre = wordGenre(text.city) ? "el" : "la";
  return baseHttp({
    method: "DELETE",
    url,
    messages: {
      error: `Ha ocurrido un error al intentar eliminar ${genre} ${text.city}.`,
      success: `Se ha eliminado ${genre} ${text.city} correctamente!`,
    },
  });
};

<template>
  <v-container>
    <v-row no-gutters class="pt-0">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <div v-for="i in items" :key="i.ref">
      <h3 class="mt-6">{{ i.name }}</h3>
      <v-divider class="mb-6"></v-divider>

      <v-fade-transition
        group
        tag="div"
        class="row"
        multi-line
        v-if="itemsFiltered(i.items)?.length > 0"
      >
        <v-col
          cols="6"
          sm="4"
          md="3"
          v-for="it in itemsFiltered(i.items)"
          :key="it.ref"
        >
          <v-hover v-slot="{ hover }">
            <v-card @click="redirigir(it.path)">
              <v-card-title primary-title class="justify-center">
                <v-icon x-large :color="'primary darken-4'">
                  mdi-{{ it.icon }}
                </v-icon>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :style="{ height: '90px' }">
                {{ it.text }}
              </v-card-text>
              <v-card-actions
                :class="['overflow-hidden', hover ? 'info' : 'primary']"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="white--text font-weight-bold">{{
                      it.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  class="justify-end"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <v-icon class="mr-1" color="white"> mdi-open-in-new </v-icon>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-fade-transition>
      <p v-else class="caption">No se han encontrado coincidencias.</p>
    </div>
  </v-container>
</template>

<script>
import { integrator } from "@/router/data/index.js";
import SetPageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "ManagerMainPage",
  mixins: [SetPageMixin],
  computed: {
    items: () => integrator,
  },
  data: () => ({
    search: "",
  }),
  methods: {
    redirigir(path) {
      this.$router.push({ name: path });
    },
    itemsFiltered(items) {
      return items.filter((e) =>
        e.name?.toLowerCase()?.includes(this.search.toLowerCase())
      );
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <div class="d-flex pa-3 align-center">
        <!-- BUSCAR -->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar..."
          dense
          class="align-self-end"
          hide-details
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer></v-spacer>
        <!-- ACTUALIZAR -->
        <v-btn
          outlined
          color="primary"
          :disabled="isBusy"
          :loading="isBusy"
          fab
          small
          @click="getPlantillas()"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <!-- NUEVO -->
        <v-btn class="ml-3" color="primary" @click="modals.form = true">
          Nuevo
        </v-btn>
      </div>

      <!-- Table -->
      <div class="table-responsive mb-0">
        <v-data-table
          :items="itemsTable"
          :headers="fields"
          :loading="isBusy"
          loading-text="Cargando... espere un momento"
          :items-per-page="selectMostrar"
          :search="search"
          :footer-props="{
            'items-per-page-text': 'mostrar',
            'items-per-page-options': [5, 10, 25, 50],
          }"
        >
          <template #cell(name)="item">
            {{ item.value.id }}
          </template>

          <template #item.estado_c="{ item }">
            <v-switch
              color="primary"
              :hint="item.estado_c"
              persistent-hint
              :input-value="item.estado == 'a'"
              :false-value="false"
              :true-value="true"
              inset
              dense
              disabled
            ></v-switch>
          </template>

          <!-- ACCIONES -->
          <template v-slot:item.accion="{ item }">
            <!-- EDITAR -->
            <v-btn
              color="primary"
              fab
              x-small
              text
              @click="(selected = item), (modals.form = true)"
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
            <!-- VER -->
            <v-btn
              text
              x-small
              fab
              color="blue-grey"
              @click="(selected = item), (modals.sample = true)"
            >
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <!-- ELIMINAR -->
            <v-btn color="error" fab x-small text @click="deleteItem(item)">
              <v-icon> mdi-delete-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <confirm-modal ref="confirmDialog" />
    <form-plantillas
      ref="formComponent"
      v-model="modals.form"
      :form-data="selected"
      @close="closeForm()"
      @submit="submitForm()"
      v-bind="bindItems"
    />
    <ConsultarPlantilla
      v-bind="bindItems"
      v-model="modals.sample"
      @close="(modals.sample = false), (selected = null)"
      :item="selected"
    />
  </div>
  <!-- </Layout> -->
</template>

<script>
import PlantillaService from "@/services/manager/notifications/plantillas.service.js";
import ClientService from "@/services/manager/notifications/client.service.js";

import ConsultarPlantilla from "@/views/Manager/notificaciones/forms/consultar-plantilla.vue";
import FormPlantillas from "@/views/Manager/notificaciones/forms/formPlantillas.vue";
import ConfirmModal from "@/components/Modal/ConfirmModal.vue";

const plantillaService = new PlantillaService();
const clientService = new ClientService();

import SetPageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "TemplatesCrudPage",
  mixins: [SetPageMixin],
  components: {
    FormPlantillas,
    ConsultarPlantilla,
    ConfirmModal,
  },
  data() {
    return {
      selectMostrar: 10,
      isBusy: false,
      fields: [
        {
          value: "nombre",
          sortable: false,
          text: "Nombre",
        },
        {
          value: "metodoNotificacion",
          sortable: true,
          text: "Notificación",
        },
        {
          value: "asunto",
          sortable: false,
          text: "Asunto",
        },
        {
          value: "estado_c",
          sortable: true,
          text: "Estado",
        },
        {
          value: "accion",
          sortable: false,
          text: "",
          align: "right",
        },
      ],
      selected: null,
      itemsTable: [],
      filter: "",
      cargando: false,

      modals: {
        form: false,
        sample: false,
      },
      itemsOptions: {
        notifOptions: [],
        clientOptions: [],
        statusOptions: [],
      },
      search: "",
    };
  },
  created() {
    this.cargando = true;
    this.getPlantillas();
  },
  mounted() {
    this.getmetodoNotificacion();
    this.getEstadoNotificacion();
    this.getclient();
  },

  computed: {
    bindItems() {
      return {
        statusOptions: this.itemsOptions.statusOptions,
        notifOptions: this.itemsOptions.notifOptions,
        clientOptions: this.itemsOptions.clientOptions,
        service: plantillaService,
      };
    },
  },

  methods: {
    getPlantillas() {
      this.itemsTable = [];
      this.isBusy = true;
      let clientI;
      const plantillas = plantillaService.getAllPlantillas();
      plantillas.then((resp) => {
        const respData = resp.data.data.data;

        // console.log(respData, 'plantilla resp')
        respData.forEach((item) => {
          item.loading = false;
          clientI = item?.clientId ?? item.client ?? null;
          if (item.estado === "a") {
            this.itemsTable.push({
              nombre: item.nombre,
              metodoNotificacion: item.metodoNotificacion,
              asunto: item.asunto,
              estado: item.estado,
              estado_c: "Activo",
              body: item.cuerpo,
              /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
              id: item._id,
              clientId: clientI,
            });
          } else {
            this.itemsTable.push({
              nombre: item.nombre,
              metodoNotificacion: item.metodoNotificacion,
              asunto: item.asunto,
              estado_c: "Inactivo",
              estado: item.estado,
              body: item.cuerpo,
              /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
              id: item._id,
              clientId: clientI,
            });
          }
        });
        this.isBusy = false;
        this.cargando = false;
      });
      plantillas.catch((err) =>
        console.log(`Error respuesta del servidor: ${err}`)
      );
    },

    async getmetodoNotificacion() {
      const metodo = plantillaService.metodosNotificaciones();
      await metodo.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.itemsOptions.notifOptions.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      metodo.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },
    async getclient() {
      const estado = clientService.client();
      await estado.then((resp) => {
        const respData = resp.data.result;
        respData.forEach((item) => {
          /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
          this.itemsOptions.clientOptions.push({
            text: item.nombre,
            value: item._id,
          });
        });
      });
      estado.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },
    async getEstadoNotificacion() {
      const estado = plantillaService.estadoNotificaciones();
      await estado.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.itemsOptions.statusOptions.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      estado.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },

    submitForm() {
      this.closeForm();
      this.getPlantillas();
    },

    closeForm() {
      const formComponent = this.$refs.formComponent;
      this.selected = null;
      formComponent.resetForm();
      formComponent.$refs.form.resetValidation();
      this.modals.form = false;
    },

    deleteItem(item) {
      this.$refs.confirmDialog
        .show({
          title: "Eliminar Plantilla",
          description: `¿Seguro que desea eliminar la plantilla ${item.nombre}?`,
        })
        .then((result) => {
          if (result) {
            item.loading = true;
            plantillaService
              .eliminarPlantilla(item.id)
              .then(() => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: "Se ha eliminado la plantilla correctamente",
                  top: true,
                  right: true,
                  color: "success",
                });
                this.getPlantillas();
              })
              .catch(() => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: "Ha ocurrido un error al intentar eliminar la plantilla.",
                  top: true,
                  right: true,
                  color: "error",
                });
              });
          }
        });
    },
  },
};
</script>

<template>
  <v-dialog
    :value="value"
    width="800"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    scrollable
    :persistent="loading.form"
  >
    <v-card>
      <v-card-title>
        Formulario de Configuración
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          small
          fab
          :disabled="loading.form"
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-3">
        <p class="caption mb-0">
          <v-icon small color="primary">mdi-information</v-icon> Primero
          seleccione un cliente para cargar los listados las plantillas y
          convenios. <br />
          Con el botón de <v-icon small>mdi-eye</v-icon> puede visualizar la
          plantilla seleccionada.
        </p>
        <v-form ref="form">
          <v-row multi-line>
            <!-- CLIENTE -->
            <v-col cols="12" md="6">
              <v-label for="cliente">Cliente</v-label>
              <v-autocomplete
                id="cliente"
                v-model="clientID"
                :items="clients"
                placeholder="ID del cliente"
                outlined
                @change="filterTemplates($event), filterConvenios($event)"
                :rules="[(v) => !!v || 'Debe seleccionar un cliente.']"
              ></v-autocomplete>
            </v-col>

            <!-- PLANTILLA -->
            <v-col cols="12" md="6">
              <v-label for="plantilla">Plantilla</v-label>
              <v-autocomplete
                id="plantilla"
                v-model="plantilla"
                :items="templateFiltered"
                placeholder="Seleccione una plantilla"
                outlined
                @change="modelTemplate($event)"
                append-outer-icon="mdi-eye"
                @click:append-outer="
                  dataPlantilla && !loading.plantillas
                    ? (previewTemplateModal = true)
                    : () => null
                "
                :loading="loading.plantillas"
                :rules="[(v) => !!v || 'Debe seleccionar una plantilla.']"
              ></v-autocomplete>
            </v-col>

            <!-- CONVENIO -->
            <v-col cols="12" md="4">
              <v-label for="convenio">Convenio</v-label>
              <v-autocomplete
                id="convenio"
                v-model="convenio"
                :items="conveniosFiltered"
                :loading="loading.convenios"
                placeholder="Seleccione un convenio"
                outlined
                :rules="[(v) => !!v || 'Debe seleccionar un convenio.']"
              ></v-autocomplete>
            </v-col>

            <!-- PROCESO -->
            <v-col cols="12" md="4">
              <v-label for="proceso">Proceso</v-label>
              <v-autocomplete
                id="proceso"
                v-model="proceso"
                :items="processes"
                placeholder="Seleccione un proceso"
                outlined
                :rules="[(v) => !!v || 'Debe seleccionar un proceso.']"
              ></v-autocomplete>
            </v-col>

            <!-- OPERACION -->
            <v-col cols="12" md="4">
              <v-label for="operacion">Operación</v-label>
              <v-autocomplete
                id="operacion"
                v-model="operacion"
                :items="operations"
                placeholder="Seleccione una operación"
                outlined
                :rules="[(v) => !!v || 'Debe seleccionar una operación.']"
              ></v-autocomplete>
            </v-col>

            <!-- TIPO DE ENVIO -->
            <v-col cols="12" md="6">
              <v-label for="tipoEnvio">Tipo de Envío</v-label>
              <v-select
                id="tipoEnvio"
                v-model="tipoEnvio"
                :items="deliveryList"
                placeholder="Seleccione un tipos de envío"
                outlined
                :rules="[(v) => !!v || 'Debe seleccionar un tipo de envío.']"
              ></v-select>
            </v-col>

            <!-- ESTADO -->
            <v-col cols="12" md="6">
              <v-label for="estado">Estado</v-label>
              <v-select
                id="estado"
                v-model="estado"
                :items="status"
                placeholder="Seleccione un estado"
                :rules="[(v) => !!v || 'Debe asignarle un estado.']"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="submit()" :loading="loading.form">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConsultarPlantilla
      v-model="previewTemplateModal"
      :item="dataPlantilla"
      @close="previewTemplateModal = false"
    />
  </v-dialog>
</template>

<script>
// import Multiselect from "vue-multiselect";
// import { tarjetaVirtualMethods } from "@/state/helpers";

import NotificacionService from "@/services/manager/notifications/notificaciones.service.js";
import PlantillaService from "@/services/manager/notifications/plantillas.service.js";

import ConsultarPlantilla from "@/views/Manager/notificaciones/forms/consultar-plantilla.vue";

const notificacionService = new NotificacionService();
const plantillaService = new PlantillaService();

export default {
  name: "FormConfiguraciones",
  components: {
    ConsultarPlantilla,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },

    processes: {
      type: Array,
      default: () => [],
    },
    convenios: {
      type: Array,
      default: () => [],
    },
    templates: {
      type: Array,
      default: () => [],
    },
    operations: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Array,
      default: () => [],
    },
    deliveryList: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      conveniosFiltered: [],
      templateFiltered: [],

      plantilla: "",
      convenio: "",
      proceso: "",
      operacion: "",
      compania: "",
      producto: "",
      plan: "",
      estado: "",
      tipoEnvio: "",
      clientID: "",
      id: null,

      dataPlantilla: null,

      previewTemplateModal: false,

      loading: {
        form: false,
        convenios: false,
        plantillas: false,
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  methods: {
    async filterTemplates(data) {
      this.plantilla = "";
      this.templateFiltered = [];
      const arrayPlantilla = [];
      this.loading.plantillas = true;

      this.templates.forEach((item) => {
        if (item.clientId === data || item.client === data) {
          arrayPlantilla.push({
            text: item.nombre,
            value: item._id,
          });
        }
      });
      this.templateFiltered = arrayPlantilla;
      this.loading.plantillas = false;
    },
    async filterConvenios(data) {
      this.convenio = "";
      const arrayConvenio = [];
      this.conveniosFiltered = [];
      this.loading.convenios = true;
      await this.convenios.forEach((item) => {
        if (item.clientId === data || item.client === data) {
          arrayConvenio.push(item);
        }
      });
      this.conveniosFiltered = arrayConvenio;
      this.loading.convenios = false;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading.form = true;
        const payload = {
          plantilla: this.plantilla,
          convenio: this.convenio,
          proceso: this.proceso,
          operacion: this.operacion,
          compania: this.compania,
          plan: this.plan,
          producto: this.producto,
          client: this.clientID,
          estado: this.estado,
          tipoEnvio: this.tipoEnvio,
        };
        let method = notificacionService.CrearNotificaciones;
        if (this.formData) {
          (payload.id = this.id),
            (method = notificacionService.ModificarNotificaciones);
        }

        method(payload)
          .then((response) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: response.info,
              top: true,
              right: true,
              color: "success",
            });
            this.$emit("submit");
          })
          .catch((e) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: e.info,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loading.form = false;
          });
      }
    },
    setForm() {
      this.proceso = this.formData.proceso;
      this.operacion = this.formData.operacion;
      this.id = this.formData.id || this.formData._id;
      this.estado = this.formData.estado;
      this.tipoEnvio = this.formData.tipoEnvio;
      this.clientID = this.formData.clientId || this.formData.client;
      this.filterTemplates(this.formData.clientId);
      this.filterConvenios(this.formData.clientId);
      this.convenio = this.formData.convenio;
      this.plantilla = this.formData.plantilla;
    },
    resetForm() {
      this.plantilla = "";
      this.convenio = "";
      this.proceso = "";
      this.operacion = "";
      this.compania = "";
      this.plan = "";
      this.producto = "";
      this.clientID = "";
    },
    modelTemplate(template) {
      this.loading.plantillas = true;
      plantillaService
        .getIdPlantillas(template)
        .then((resp) => {
          this.dataPlantilla = resp.data.data.data;
        })
        .finally(() => {
          this.loading.plantillas = false;
        });
    },
  },
};
</script>

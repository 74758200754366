export const setAllTipoCanales = (state, payload) => {
  state.tipoCanales = payload;
};

export const setNewTipoCanal = (state, data) => {
  state.tipoCanales = [data, ...state.tipoCanales];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateTipoCanal = (state, data) => {
  state.tipoCanales = state.tipoCanales.map((canal) =>
    canal.id === data.id ? data : canal
  );
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteTipoCanal = (state, id) => {
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
  state.tipoCanales = state.tipoCanales.filter((canal) => canal.id !== id);
};

export const setDontSaveNewTipoCanal = (state, mensajeError) => {
  state.openModalNew = true;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = mensajeError;
  state.errorModalNew = true;
};

export const setDontDeleteTipoCanal = (state, mensajeError) => {
  state.openModalEliminar = true;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = mensajeError;
  state.errorModalDelete = true;
};

//MODAL NEW
export const setOpenModalNew = (state) => {
  state.openModalNew = true;
};

export const setCloseModalNew = (state) => {
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

//BUTTON LOADING MODAL NEW
export const setLoadButtonModalNew = (state) => {
  state.loadButtonModalNew = true;
};

//BUTTON LOADING MODAL DELETE
export const setLoadButtonModalDelete = (state) => {
  state.loadButtonModalDelete = true;
};

//MODAL DELETE
export const setOpenModalDelete = (state) => {
  state.openModalEliminar = true;
};

export const setCloseModalDelete = (state) => {
  state.openModalEliminar = false;
};

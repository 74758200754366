import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.tipoCanales;

export const getTipoDeCanales = async ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setAllTipoCanales", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de tipos de canales.");
      });
  });
};

export const saveNewTypeCanal = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data: payload, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewTipoCanal", result);
        resolve({
          result,
          info: "Se ha guardado el tipo de canal satisfactoriamente!",
        });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado guardar el tipo de canal.";
        reject(mensaje);
      });
  });
};

export const updateTypeCanal = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateTipoCanal", result);
        resolve({
          result,
          info: "Se ha actualizado el tipo de canal correctamente!",
        });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado actualizar el tipo de canal.";
        reject(mensaje);
      });
  });
};

export const deleteTypeCanal = async ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteTipoCanal", id);
        resolve({ info: "Se ha eliminado un tipo de canal correctamente!" });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado eliminar un tipo de canal.";
        reject(mensaje);
      });
  });
};

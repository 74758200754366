export const setModulos = (state, payload) => {
  state.modulos = payload.map((modulo) => ({
    ...modulo,
    ...{ es_activo: modulo.es_activo ? "Si" : "No" },
  }));
};

export const setNewModulo = (state) => {
  // state.modulos = [data, ...state.modulos];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateModulo = (state) => {
  // state.modulos = state.modulos.map((row) => (row.id === data.id ? data : row));
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteModulo = (state) => {
  // state.modulos = state.modulos.filter((row) => row.id !== id);
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
};

<template>
  <div>
    <v-card>
      <div class="d-flex align-center pa-3">
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar..."
          class="align-self-end"
          dense
          hide-details
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider> -->
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          class="ml-3"
          :loading="loading"
          @click="getBitacora()"
          fab
          small
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>

      <!-- Table -->
      <div class="table-responsive mb-0">
        <v-data-table
          @pagination="setPagination"
          :items="itemsTable"
          :headers="headers"
          :page="pagination.current"
          :server-items-length="pagination.totalPages"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading"
          :footer-props="{
            'items-per-page-text': 'mostar',
            'items-per-page-options': [5, 10, 25, 50],
          }"
        >
          <template #item.created_at="{ item }">
            {{ item.createdAt | formatoFecha }}
          </template>

          <template v-slot:item.accion="{ item }">
            <v-btn
              color="blue-grey"
              fab
              x-small
              text
              @click="(selected = item), (dialog = true)"
            >
              <v-icon> mdi-eye-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <consultar-seguimiento
      v-model="dialog"
      :item="selected"
      :clients="clients"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import BitacoraService from "@/services/manager/notifications/bitacora2.service.js";
import ClientService from "@/services/manager/notifications/client.service.js";
import ConsultarSeguimiento from "@/views/Manager/notificaciones/forms/consultar-seguimiento.vue";

const bitacoraService = new BitacoraService();
const clientService = new ClientService();

import SetPageMixin from "@/Mixins/SetPageMixin.js";
/**
 * Productos Component
 */
export default {
  mixins: [SetPageMixin],
  components: {
    // PageHeader,
    ConsultarSeguimiento,
  },
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },

  /* props: {
    search: {
      type: [String, Number],
      require: true,
      default: '',
    },
  }, */
  page: {
    title: "Seguimiento",
    meta: [
      {
        name: "Seguimiento",

        // content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Seguimiento de Notificaciones",
      selectMostrar: "10",
      loading: false,
      dialog: false,
      selected: null,
      itemsTable: [],
      search: "",
      clients: [],
      pagination: {
        current: 1,
        totalPages: 0,
        itemsPerPage: 10,
      },
    };
  },
  mounted() {
    this.getClient();
  },
  computed: {
    headers() {
      return [
        {
          value: "ack",
          sortable: false,
          text: "Codigo",
        },
        {
          value: "created_at",
          sortable: false,
          text: "Fecha",
        },
        {
          value: "metodoNotificacion",
          sortable: false,
          text: "Método de Notificación",
        },
        {
          value: "destinatario",
          sortable: false,
          text: "Destinatario",
        },
        {
          value: "proceso",
          sortable: false,
          text: "Proceso",
        },
        {
          value: "estado",
          sortable: false,
          text: "Estado",
        },
        {
          value: "accion",
          sortable: false,
          text: "",
          align: "right",
        },
      ];
    },
  },
  methods: {
    async setPagination(tableData = null) {
      this.pagination.itemsPerPage = tableData.itemsPerPage;
      this.pagination.current = tableData.page;
      await this.getBitacora();
    },
    async getBitacora() {
      this.loading = true;
      await bitacoraService
        .getAllbitacora(this.pagination)
        .then((response) => {
          const { pagination, data } = response.data;
          this.pagination.totalPages = pagination.totalRegistros;
          this.itemsTable = data ?? [];
        })
        .catch((e) => {
          this.snackbar(e.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getClient() {
      clientService.get().then((response) => {
        this.clients = response.result;
      });
    },
  },
};
</script>

<template>
  <v-dialog
    :value="value"
    width="800"
    scrollable
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    :persistent="loading"
  >
    <v-card>
      <v-card-title
        >Formulario de plantilla

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          x-small
          fab
          text
          @click="$emit('close')"
          :disabled="loading"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row multi-line>
            <!-- CLIENTE -->
            <v-col cols="12" md="4">
              <v-label for="clientId">Cliente</v-label>
              <v-select
                id="clientId"
                v-model="clientID"
                :items="clientOptions"
                placeholder="ID del cliente"
                outlined
                dense
                :rules="[(v) => !!v || 'Debe seleccionar un Cliente']"
              ></v-select>
            </v-col>

            <!-- NOMBRE DE LA PLANTILLA -->
            <v-col cols="12" md="8">
              <v-label for="nombre">Nombre de la Plantilla</v-label>
              <v-text-field
                id="nombre"
                v-model="nombre"
                type="text"
                placeholder="Nombre"
                autocomplete="off"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Especifique un nombre para la plantilla',
                ]"
              ></v-text-field>
            </v-col>

            <!-- METODO DE NOTIFICACION -->
            <v-col cols="12" md="6">
              <v-label for="metodoNotificacion">Método de Notificación</v-label>
              <v-select
                id="metodoNotificacion"
                v-model="metodoNotificacion"
                :items="notifOptions"
                placeholder="Método Notificación"
                outlined
                dense
                :rules="[(v) => !!v || 'Seleccione un método de notificación']"
              ></v-select>
            </v-col>

            <!-- ESTADO -->
            <v-col cols="12" md="6">
              <v-label for="estado">Estado</v-label>
              <v-select
                id="estado"
                v-model="estado"
                :items="statusOptions"
                placeholder="Estado"
                outlined
                dense
                :rules="[(v) => !!v || 'Seleccione un estado de la plantilla.']"
              ></v-select>
            </v-col>

            <!-- ASUNTO -->
            <v-col cols="12">
              <v-label for="asunto">Asunto</v-label>
              <v-text-field
                id="asunto"
                v-model="asunto"
                placeholder="Asunto"
                autocomplete="off"
                outlined
                dense
                :rules="[(v) => !!v || 'El asunto es requerido.']"
              ></v-text-field>
            </v-col>

            <!-- PLANTILLA -->
            <v-col cols="12">
              <v-label for="plantilla">Plantilla</v-label>
              <v-textarea
                id="editor"
                v-model="editor"
                type="textarea"
                placeholder="Codigo HTML"
                rows="10"
                outlined
                :rules="[(v) => !!v || 'Debe incluir el cuerpo del mensaje']"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="sumbit()" :loading="loading">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PlantillaFormModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },

    statusOptions: {
      type: Array,
      default: () => [],
    },
    notifOptions: {
      type: Array,
      default: () => [],
    },
    clientOptions: {
      type: Array,
      default: () => [],
    },

    service: null,
  },
  data() {
    return {
      loading: false,

      id: null,
      nombre: "",
      estado: "",
      asunto: "",
      metodoNotificacion: "",

      mensajeError: "",
      editor: "",
      text: "",

      clientID: "",
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  methods: {
    async sumbit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formMethod = this.service.Crearplantilla;
        const data = {
          nombre: this.nombre,
          estado: this.estado,
          asunto: this.asunto,
          metodoNotificacion: this.metodoNotificacion,
          cuerpo: this.editor,
          requiereAdjunto: true,
          client: this.clientID,
        };
        if (this.formData) {
          data.id = this.id;
          formMethod = this.service.ModificarPlantilla;
        }
        formMethod(data)
          .then((result) => {
            if (
              result.data.statusCode === 200 &&
              result.data.success === true
            ) {
              this.$store.commit("setSnackbar", {
                active: true,
                text: "La plantilla se ha guardado satisfactoriamente!",
                top: true,
                right: true,
                color: "success",
              });
              this.$emit("submit");
            } else {
              throw `Corrija los errores indicados por el servidor: ${result.data.message}`;
            }
          })
          .catch(() => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: "Ha ocurrido un error al intentar guardar la plantilla.",
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    setForm() {
      this.nombre = this.formData.nombre;
      this.estado = this.formData.estado;
      this.asunto = this.formData.asunto;
      this.metodoNotificacion = this.formData.metodoNotificacion;
      this.id = this.formData.id;
      this.editor = this.formData.body;
      this.clientID = this.formData.clientId;
    },
    resetForm() {
      this.id = null;
      this.nombre = "";
      this.estado = "";
      this.asunto = "";
      this.metodoNotificacion = "";
      this.clientID = "";
      this.editor = "";
    },
  },
};
</script>

export const darkorlightStyle = (color) => {
  if (color === "white") {
    return false;
  }
  let c;
  try {
    c = color.substring(1); // strip #
  } catch {
    return false;
  }
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  let darkColor = false;
  if (luma < 128) {
    darkColor = true;
  }
  return darkColor;
};

export const wordGenre = (palabra) => {
  // Terminaciones comunes para palabras femeninas
  const femenino = ["a", "gión", "ión", "dad", "tad", "tud", "umbre", "sis"];

  // Comprobar si la palabra termina con alguna terminación femenina
  for (let terminacion of femenino) {
    if (palabra.endsWith(terminacion)) {
      return false;
    }
  }

  // Si no termina con ninguna terminación femenina, consideramos masculino por defecto
  return true;
};

export default {
  darkorlightStyle,
};

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

export default {
  namespaced: true,
  state: {
    parametrizacion: [],
    loadingDataTable: true,
    errorCargandoDataTable: false,
    //modal add
    loadButtonModalNew: false,
    openModalNew: false,
    messageErrorModalNew: "",
    errorModalNew: false,
    //modal eliminar
    openModalEliminar: false,
    loadButtonModalDelete: false,
    messageErrorModalDelete: "",
    errorModalDelete: false,
  },
  actions,
  mutations,
  getters,
};

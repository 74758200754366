import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

export const GetHomologaciones = ({ commit, getters }, query = {}) => {
  const params = {
    limit: query.limit,
    page: query.page,
    search: query.search,
  };
  let path = `${getters.GetEndpoint}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      params,
    })
      .then((response) => {
        const { data, pagination } = response.data.data?.homologaciones ?? {
          data: [],
          pagination: null,
        };
        commit("setItems", data);
        resolve({ result: data, pagination });
      })
      .catch(() =>
        reject(
          "Ha ocurrido un error al intentar recuperar el listado de homologaciones."
        )
      );
  });
};

export const PostHomologacion = ({ getters }, form) => {
  const path = getters.GetEndpoint;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: path,
      headers: bffHeaders,
      data: form,
    })
      .then((response) => {
        const result = response.data.data?.result,
          info = "Se ha guardado la homologación satisfactoriamente";
        resolve({ info, result });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar guardar una homologación.")
      );
  });
};
// PUT
export const PutHomologacion = ({ getters }, payload) => {
  const path = `${getters.GetEndpoint}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: path,
      headers: bffHeaders,
      data: {
        entrada: payload.entrada,
        cliente: payload.cliente,
        homologacion: payload.homologacion,
        salida: payload.salida,
        descripcion: payload.descripcion,
        canal: payload.canal,
      },
    })
      .then((response) => {
        const result = response.data.data?.result,
          info = "Se ha actualizado la homologación satisfactoriamente";
        resolve({ info, result });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar actualizar una homologación.")
      );
  });
};

export const DeleteHomologacion = ({ getters }, id) => {
  const path = `${getters.GetEndpoint}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data?.result,
          info = "Se ha eliminado la homologación correctamente";
        resolve({ info, result });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar eliminar la homologación.")
      );
  });
};

export const GetSucursal = ({ getters }, id) => {
  const url = getters.GetEndpointSucursal?.replace(":id", id);
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.sucursales;
        resolve(result);
      })
      .catch(() =>
        reject(
          "Ha ocurrido un error al intentar recuperar el listado de sucursales."
        )
      );
  });
};

export const GetConvenio = ({ getters }) => {
  const url = getters.GetEndpointConvenio;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: bffHeaders,
    })
      .then((response) => {
        const { result } = response.data.data;
        resolve(result);
      })
      .catch(() =>
        reject(
          "Ha ocurrido un error al intentar recuperar el listado de convenios."
        )
      );
  });
};

<template>
  <div>
    <v-card>
      <v-card-title class="align-center">
        <v-text-field
          v-model="search"
          v-bind="bind.search"
          placeholder="Buscar una restricción...."
        ></v-text-field>

        <v-divider v-bind="bind.divider"></v-divider>

        <!-- FILTROS -->
        <v-menu
          v-model="filterMenu"
          :nudge-width="200"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab small outlined v-on="on" v-bind="attrs">
              <v-icon>{{ icons.filter }}</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-autocomplete
                v-model="filters.pais"
                label="País"
                :items="Countries"
                outlined
                dense
                item-value="codigo"
                item-text="pais_descripcion"
                item-disabled="eliminado"
                :loading="loadingCountries"
                clearable
                @change="getItems()"
              ></v-autocomplete>

              <v-autocomplete
                v-model="filters.tipo"
                label="Tipo de Lista"
                :items="ListType"
                outlined
                dense
                clearable
                @change="getItems()"
              ></v-autocomplete>

              <v-autocomplete
                v-model="filters.instrumento"
                label="Tipo de instrumento"
                :items="Objects"
                outlined
                dense
                clearable
                @change="getItems()"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-menu>
        <!-- /FILTROS -->

        <v-spacer></v-spacer>
        <v-btn v-bind="bind.refresh" v-on="on.refresh">
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
        <v-btn v-bind="bind.new" v-on="on.new">
          {{ labels.new }}
        </v-btn>
      </v-card-title>

      <!-- TABLA DE DATOS -->
      <v-data-table v-bind="bind.table" :search="search">
        <template #item.instrumento="{ item }">
          {{
            Objects.find((e) => e.value == item.instrumento)?.text ||
            item.instrumento ||
            "Desconocido"
          }}
        </template>

        <template #item.tipo="{ item }">
          <v-chip
            :dark="item.tipo === 'deny'"
            :outlined="item.tipo === 'allow'"
            label
          >
            {{
              ListType.find((e) => e.value == item.tipo)?.text ||
              item.tipo ||
              "Sin clasificar"
            }}
          </v-chip>
        </template>

        <template #item.pais="{ item }">
          {{
            Countries?.find((e) => e.codigo == item.pais)?.pais_descripcion ||
            item.pais ||
            ""
          }}
        </template>

        <template #item.actions="{ item }">
          <v-btn color="error" x-small fab text @click="deleteItem(item)">
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- /TABLA DE DATOS -->
    </v-card>

    <!-- MODAL FORMULARIO -->
    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="loadingForm"
      max-width="500px"
      @click:outside="resetForm()"
      @keydown.esc="resetForm()"
    >
      <validation-observer ref="observer">
        <v-form ref="form">
          <v-card>
            <v-card-title primary-title>
              Formulario de Restricciones
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                x-small
                fab
                :disabled="loadingForm"
                @click="resetForm()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <h4 class="mb-3">Información General</h4>

              <validation-provider
                v-slot="{ errors }"
                name="País"
                rules="required"
              >
                <v-autocomplete
                  v-model="form.pais"
                  label="País"
                  :items="Countries"
                  outlined
                  dense
                  item-value="codigo"
                  item-text="pais_descripcion"
                  item-disabled="eliminado"
                  :loading="loadingCountries"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Tipo de lista"
                rules="required"
              >
                <v-autocomplete
                  v-model="form.tipo"
                  label="Tipo de lista"
                  :items="ListType"
                  outlined
                  dense
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-card-text>
              <h4 class="mb-3">Información del Instrumento</h4>

              <validation-provider
                v-slot="{ errors }"
                name="Tipo"
                rules="required"
              >
                <v-autocomplete
                  v-model="form.instrumento"
                  label="Tipo"
                  :items="Objects"
                  outlined
                  dense
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Identificador"
                rules="required"
              >
                <v-text-field
                  label="Identificador"
                  hint="Numero, serial, etc..."
                  v-model="form.numero_instrumento"
                  dense
                  outlined
                  :error-messages="errors"
                  persistent-hint
                ></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="validateForm()"
                :loading="loadingForm"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <!-- /MODAL FORMULARIO -->

    <confirm-modal ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudRestrictionPage",
  mixins: [SetPage],
  data() {
    return {
      loadingCountries: false,
      headers: [
        {
          text: "Instrumento",
          align: "start",
          filterable: false,
          sortable: true,
          value: "instrumento",
        },
        {
          text: "ID",
          align: "start",
          filterable: true,
          sortable: true,
          value: "numero_instrumento",
        },
        {
          text: "Tipo",
          align: "start",
          filterable: false,
          sortable: true,
          value: "tipo",
        },
        {
          text: "País",
          align: "start",
          filterable: false,
          sortable: true,
          value: "pais",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: true,
          align: "right",
        },
      ],
      form: {
        instrumento: "",
        numero_instrumento: "",
        tipo: "",
        pais: "",
      },
      filters: {
        instrumento: "",
        pais: "",
        tipo: "",
      },
    };
  },
  computed: {
    ...mapGetters("Restricciones", ["Items"]),
    items() {
      return this.Items;
    },
    Countries() {
      return this.$store.getters.GetCountries;
    },
    Objects: () => [
      {
        text: "Tarjeta de Crédito",
        value: "TC",
      },
    ],
    ListType: () => [
      {
        text: "Lista negra",
        value: "deny",
      },
      {
        text: "Lista blanca",
        value: "allow",
      },
    ],
  },
  methods: {
    ...mapActions("Restricciones", ["GET", "POST", "DELETE"]),

    setup() {
      this.getItems();
      this.getCountries();
    },

    getItems() {
      this.loading = true;
      //Establecer parametros
      const params = {};
      if (this.filters.instrumento) {
        params.instrumento = this.filters.instrumento;
      }
      if (this.filters.pais) {
        params.pais = this.filters.pais;
      }
      if (this.filters.tipo) {
        params.tipo = this.filters.tipo;
      }
      this.GET(params)
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loading = false));
    },
    getCountries() {
      this.loadingCountries = true;
      this.$store
        .dispatch("REQUEST_COUNTRIES")
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loadingCountries = false));
    },

    validateForm() {
      this.$refs.observer.validate().then((result) => {
        if (result) {
          this.saveItem();
        }
      });
    },

    saveItem() {
      this.loadingForm = true;
      this.POST(this.form)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.resetForm();
          this.getItems();
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loadingForm = false));
    },

    deleteItem(item) {
      this.$refs.confirmDialog
        .show({
          title: "Eliminar una restricción",
          description: "¿Esta seguro de querer eliminar esta restricción?",
        })
        .then((result) => {
          if (result) {
            this.DELETE(item._id)
              .then((response) => {
                this.snackbar(response.info, "info");
                this.getItems();
              })
              .catch((error) => {
                this.snackbar(error);
              });
          }
        });
    },

    resetForm() {
      this.form.instrumento = "";
      this.form.numero_instrumento = "";
      this.form.tipo = "";
      this.form.pais = "";
      this.dialog = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.inspeccionEmpresa;

export const getAllData = async ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setAllData", result);
        resolve(result);
      })
      .catch(() => {
        reject(
          "No se ha logrado recuperar el listado de empresas para inspeccion."
        );
      });
  });
};

export const saveNewData = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", data: payload, url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewData", result);
        resolve({
          result,
          info: "Se ha guardado una nueva empresa de inspeccion satisfactoriamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado guardar la empresa de inspeccion."
        );
      });
  });
};

export const updateData = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      data: payload,
      url: `${url}/${payload.id}`,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateData", result);
        resolve({
          result,
          info: "Se ha actualizado la empresa de inspeccion correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado actualizar la empresa de inspeccion."
        );
      });
  });
};

export const deleteItemData = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${payload}`, headers: bffHeaders })
      .then((response) => {
        const result = response.data?.data?.result;
        commit("setDeleteItemData", payload);
        resolve({
          result,
          info: "Se ha eliminado una empresa de inspeccion correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Error al intentar eliminar una empresa de inspeccion."
        );
      });
  });
};

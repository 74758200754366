import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
const url = endpoints.cruds.tipoMedioPago;
import { bffHeaders } from "@/helpers/headers.js";

export const getAllData = ({ commit }) => {
  return new Promise((resolve, reject) => [
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("setAllData", result);
        resolve({ result });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "No se ha logrado recuperar el listado tipos medios de pago."
        );
      }),
  ]);
};

export const saveNewData = async ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, headers: bffHeaders, data })
      .then((response) => {
        const result = response.data.data;
        commit("setNewData", result);
        resolve({
          result,
          info: result.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "Ha ocurrido un error al intentar guardar un tipo medio de pago."
        );
      });
  });
};

export const updateData = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      headers: bffHeaders,
      data: {
        descripcion: payload.descripcion,
        codigo: payload.codigo,
        codigo_numerico: payload.codigo_numerico,
      },
    })
      .then((response) => {
        const result = response.data.data;
        commit("setUpdateData", result);
        resolve({
          result,
          info: result.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "Ha ocurrido un error al intentar actualizar un tipo medio de pago."
        );
      });
  });
};

export const deleteItemData = async ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then((res) => {
        commit("setDeleteItemData", id);
        resolve({
          info: res.data?.data?.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "No se ha logrado eliminar el elemento."
        );
      });
  });
};

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.manager.propuestas;

export const getPropuestas = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${url}/${payload}/informacion-completa`,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data;

        commit("setPropuestas", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar la propuesta.");
      });
  });
};

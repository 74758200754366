<template>
  <!-- MODAL CREAR Y EDITAR -->
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-radio-group
        v-model="form.nivel"
        :disabled="!!formData"
        row
        @change="$emit('set-level', $event)"
        :rules="[(v) => !!v || 'Debe seleccionar un tipo de territorio.']"
      >
        <v-radio :label="TextLabels.state" :value="1"></v-radio>
        <v-radio :label="TextLabels.district" :value="2"></v-radio>
        <v-radio :label="TextLabels.city" :value="3"></v-radio>
      </v-radio-group>

      <!-- CAMPO REGIONES -->
      <v-slide-y-transition>
        <v-autocomplete
          v-if="form.nivel == 2 || form.nivel == 3"
          v-model="form.codigo_region"
          name="region"
          :label="TextLabels.states"
          :disabled="!!formData"
          :items="stateList"
          :rules="[(v) => !!v || `Debe seleccionar ${TextLabels.state}`]"
        ></v-autocomplete>
      </v-slide-y-transition>

      <!-- CAMPO PROVINCIA -->
      <v-slide-y-transition>
        <v-autocomplete
          v-if="form.nivel == 3"
          v-model="form.codigo_provincia"
          name="provincia"
          :label="TextLabels.districts"
          :disabled="!!formData"
          :items="districtList"
          :rules="[(v) => !!v || `Debe seleccionar ${TextLabels.district}`]"
        ></v-autocomplete>
      </v-slide-y-transition>

      <v-row no-gutters>
        <v-col cols="12" :md="form.nivel === 3 ? 6 : 12" class="pr-2">
          <!-- CAMPO CODIGO -->
          <v-text-field
            name="codigo"
            label="Código"
            v-model="form.codigo"
            :rules="[(v) => !!v || 'El campo código es requerido.']"
            hint="Código unico del territorio"
            persistent-hint
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" v-if="form.nivel === 3" class="pl-2">
          <!-- CAMPO CODIGO POSTAL -->
          <v-text-field
            name="ubigeo"
            label="Ubigeo"
            v-model="form.ubigeo"
            hint="Ubigeo del territorio"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- CAMPO DESCRIPCION -->
      <v-text-field
        name="nombre"
        label="Descripción"
        v-model="form.nombre"
        :rules="[(v) => !!v || 'El campo descripción es requerido.']"
      ></v-text-field>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "TerritoriosCrudForm",
  mixins: [FormMixin],
  props: {
    TextLabels: {
      type: Object,
      default: () => {},
      description: "Textos relacionados al territorio",
    },
    itemList: {
      type: Array,
      default: () => [],
      description: "Arbol de datos del territorio",
    },
  },
  data: () => ({
    defaultTitle: "Formulario de territorio",
    form: {
      codigo_region: "",
      codigo_pais: "",
      codigo_provincia: "",
      ubigeo: "",
      nivel: 1,
      nombre: "",
      codigo: "",
    },
  }),
  computed: {
    stateList() {
      return (
        this.itemList?.map((x) => ({ value: x.codigo, text: x.nombre })) ?? []
      );
    },
    districtList() {
      return (
        this.itemList
          ?.find((x) => x.codigo === this.form.codigo_region)
          ?.elementos?.map((y) => ({ value: y.codigo, text: y.nombre })) ?? []
      );
    },
  },
};
</script>

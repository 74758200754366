import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import { endpoints } from "@/helpers/endpoints.js";

export default {
  namespaced: true,
  state: {
    homologaciones: [],
    loadingDataTable: true,
    errorCargandoDataTable: false,
    //modal add
    loadButtonModalNew: false,
    openModalNew: false,
    messageErrorModalNew: "",
    errorModalNew: false,
    //modal eliminar
    openModalEliminar: false,
    loadButtonModalDelete: false,
    messageErrorModalDelete: "",
    errorModalDelete: false,
    items: [],
    endpoint: endpoints.integrations.homologaciones,
    endpointConvenio: endpoints.integrations.convenio,
    endpointSucursal: endpoints.integrations.sucursal,
  },
  actions,
  mutations,
  getters,
};

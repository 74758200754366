import { homeBreadCrumb, crudBreadCrumb } from "./breadcrumbs.js";

export const Mantenedores = [
  // Caracteristicas para paginas de mantenedores
  {
    ref: "202",
    title: "Gestor de tipo de canales",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipo de canal",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "203",
    title: "Gestor de medios de pagos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Medios de pago",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "204",
    title: "Gestor Empresa para Inspección",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Empresas de inspección",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "205",
    title: "Gestor de Tipos de Inspección",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos de Inspección",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "206",
    title: "Gestor de Bancos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Bancos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "207",
    title: "Gestor de Tipos de Tarjeta",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos de Tarjeta",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "208",
    title: "Gestor de Tasas de Cambio",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tasas de Cambio",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "209",
    title: "Gestor de Restricciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Restricciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "210",
    title: "Gestor de Acceso Pantallas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Acceso Pantallas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "211",
    title: "Gestor de Concesionarios Externos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Concesionarios Externos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "212",
    title: "Gestor de Tipos de Plantillas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos de plantillas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "213",
    title: "Gestor de Tipos Medios de Pago",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos Medios de Pago",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "214",
    title: "Gestor de Roles y Permisos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Roles y Permisos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "215",
    title: "Gestor de Parametrización",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Parametrización",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "216",
    title: "Gestor de Tipos de Documentos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos de Documentos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "217",
    title: "Gestor de Personas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Personas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "301",
    title: "Gestores de Integración con Forum",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Integraciones",
        disabled: false,
        href: "/#/integracion",
      },
      {
        text: "Integración Forum",
        disabled: true,
        href: "#",
      },
    ],
  },
];

export default Mantenedores;

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";
const url = endpoints.cruds.parametrizacion;

export const getParametrizacion = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ url, method: "GET", headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.data;
        commit("setAllParametrizacion", result);
        resolve(result);
      })
      .catch((error) => {
        reject(
          error?.response?.data ??
            "No se ha logrado recuperar el listado de parametrizacion."
        );
      });
  });
};

export const saveNewParametrizacion = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, headers: bffHeaders, data: payload })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewParametrizacion", result);
        resolve({
          result,
          info: "Se ha guardado el Parametrizacion satisfactoriamente!",
        });
      })
      .catch((e) => {
        reject(`${e.response.data?.data?.lista_mensaje?.[0]?.mensaje ?? ""}`);
      });
  });
};

export const updateParametrizacion = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      headers: bffHeaders,
      data: payload,
    })
      .then((response) => {
        const result = response.data.data;
        commit("setUpdateParametrizacion", result);
        resolve({
          result,
          info: "Se ha actualizado el Parametrizacion correctamente!",
        });
      })
      .catch((e) => {
        reject(
          e.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al actualizar un Parametrizacion."
        );
      });
  });
};

export const deleteParametrizacion = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ url: `${url}/${payload}`, method: "DELETE", headers: bffHeaders })
      .then(() => {
        commit("setDeleteParametrizacion", payload);
        resolve("Se ha eliminado el Parametrizacion correctamente!");
      })
      .catch((e) => {
        reject(
          e.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar eliminar un Parametrizacion"
        );
      });
  });
};

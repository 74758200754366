<template>
  <v-dialog
    :value="value"
    width="800"
    scrollable
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <v-card-title>
        {{ item?.nombre ?? "" }}

        <v-spacer></v-spacer>
        <v-btn x-small color="primary" fab text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-list two-line>
          <v-list-item-group color="primary">
            <!-- CLIENTE -->
            <v-list-item v-for="(i, index) in items" :key="index">
              <v-list-item-icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="i.title"> </v-list-item-title>
                <v-list-item-subtitle v-text="i.value"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <h4 class="subtitle-1">
          {{ item?.asunto ?? "" }}
        </h4>
        <v-divider class="mb-3"></v-divider>
        <div v-html="item?.body ?? ''"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConsultarPlantilla",
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: true,
    },

    statusOptions: {
      type: Array,
      default: () => [],
    },
    notifOptions: {
      type: Array,
      default: () => [],
    },
    clientOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    items() {
      return [
        {
          title: "Cliente",
          value:
            this.clientOptions.find(
              (e) =>
                e.value == this.item?.clientId || e.value == this.item?.client
            )?.text ?? "",
        },
        {
          title: "Método de Notificación",
          value: this.item?.metodoNotificacion ?? "",
        },
        {
          title: "Estado",
          value: this.item?.estado_c ?? "Desconocido",
        },
      ];
    },
  },
};
</script>

<template>
  <ListBitacora :query="query" :headers="headers" />
</template>

<script>
import ListBitacora from "@/components/Bitacora/ListBitacora.vue";

export default {
  setup() {
    return {
      query: "app=api-tarifas",
      headers: [
        { text: "Fecha", sortable: true, value: "creadoEl", width: "11%" },
        { text: "App", value: "app" },
        { text: "Evento", value: "tipoEvento" },
        // { text: "Funcion", value: "funcion" },
        { text: "Compañía", value: "key.companiaDescripcion" },
        { text: "Cotización", value: "key.numeroCotizacion" },
        { text: "Canal", value: "key.nombreCanal" },

        // { text: 'Usuario', value: 'key.usuario' },
        // { text: 'Cliente', value: 'key.cliente' },
        // { text: 'Cliente identificacion', value: 'key.identificacionCliente' },
        { text: "Producto", value: "key.nombreProducto" },
        { text: "Meses vigencia", value: "key.mesesVigencia" },
        /* { text: 'Estado', value: 'eliminado' }, */
        { text: "", value: "acciones", align: "right" },
      ],
    };
  },
  components: { ListBitacora },
};
</script>

// Getters generales del modulo de usuarios
export const GetCustomList = (state) => {
  return state.list; // Obtener listado de todos los usuarios
};

export const GetLogo = (state) => {
  return state.logo;
};

export const GetColors = (state) => {
  return state.colors;
};

export const GetTemplates = (state) => {
  return state.templates;
};

export const GetDomain = (state) => {
  return state.domain;
};

export const GetScreens = (state) => {
  return state.screens;
};

export const GetSender = (state) => {
  return state.sender;
};

// Metodos de obtener textos para mensajes o notificaciones
export const GetCustomMessages = (state) => {
  return state.messages;
};

// Metodos para obtener llaves claves
export const GetCustomKeys = (state) => {
  return state.keys.base;
};

export const GetDinScreenKeys = (state) => state.keys.screensDKeys;

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.customization;

export const REQUEST_CUSTOMIZATIONS = (
  { commit, getters, rootGetters },
  payload = {}
) => {
  const messages = getters.GetCustomMessages,
    paginationKeys = rootGetters.GetPaginationKeys;
  let path = `${url.get}?`;
  // Parametros adicionales
  path += [
    `${paginationKeys.current}=${
      "page" in payload && payload.page ? payload.page : 1
    }`,
    `${paginationKeys.limit}=${
      "limit" in payload && payload.limit ? payload.limit : 10
    }`,
  ].join("&");
  if ("filter" in payload && payload.filter) {
    path += `&${paginationKeys.filter}=${payload.filter}`;
  }

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      .then((r) => {
        const data = r.data.data;
        // Guardar el listado en el Store
        commit("SetList", data.result);
        resolve(data._result);
      })
      .catch(() => {
        reject(messages.getError);
      });
  });
};

export const REQUEST_CUSTOMIZATION_BY_ID = (
  { commit, getters, dispatch },
  payload
) => {
  const messages = getters.GetCustomMessages,
    keys = getters.GetCustomKeys;
  let path = `${url.get}/${payload}`;
  // Payload => Id de la personalizacion

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      .then(async (r) => {
        const result = r.data.data.result;
        // Colocar solo el nombre del subdominio en el campo
        result.domainName = result[keys.domain]
          ? result[keys.domain].split(".")[0]
          : "";
        // Obtener la ruta directa del logo
        if (
          keys.logo.getKey in result &&
          keys.logo.url in result[keys.logo.getKey] &&
          result[keys.logo.getKey][keys.logo.url] !== ""
        ) {
          try {
            const { data } = await dispatch(
              "REQUEST_FILE",
              result[keys.logo.getKey][keys.logo.url],
              { root: true }
            );
            commit("SetLogo", data.url);
          } catch {
            commit("ResetLogo");
          }
        } else {
          commit("ResetLogo");
        }
        if (
          keys.screens.getKey in result &&
          result[keys.screens.getKey].length > 0
        ) {
          commit("SetScreenValues", result[keys.screens.getKey]);
        }
        if (
          keys.sender.getKey in result &&
          result[keys.sender.getKey].length > 0
        ) {
          commit("SetSender", result[keys.sender.getKey]);
        }
        resolve(result);
      })
      .catch(() => {
        reject(messages.getItemError);
      });
  });
};

export const REQUEST_CUSTOMIZATIONS_INFO = ({ commit, getters }) => {
  const path = `${url.info}`,
    messages = getters.GetCustomMessages,
    keys = getters.GetCustomKeys;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      .then((r) => {
        const result = r.data.data.result;
        if (
          keys.template.key in result &&
          result[keys.template.key].length > 0
        ) {
          commit("SetTemplates", result[keys.template.key]);
        }
        if (keys.colors.key in result && result[keys.colors.key].length > 0) {
          commit("SetColors", result[keys.colors.key]);
        }
        if (
          keys.screens.getKey in result &&
          result[keys.screens.getKey].length > 0
        ) {
          commit("SetScreen", result[keys.screens.getKey]);
        }
        resolve(result);
      })
      .catch(() => {
        reject(messages.getDataError);
      });
  });
};

export const SAVE_CUSTOMIZATIONS = ({ getters }, payload) => {
  const path = `${url.save}`,
    messages = getters.GetCustomMessages,
    keys = getters.GetCustomKeys;
  // Payload => Datos del formulario de personalizacion
  // ** Condiciones **
  // En caso de que el parametro cambiar logo exista, se elimina
  if (keys.logo.change in payload) {
    delete payload[keys.logo.change];
  }
  // Guardar el valor del dominio o en su defecto un string vacio
  // payload[keys.domain] =
  //   keys.domain in payload &&
  //   payload[keys.domain] &&
  //   payload[keys.domain] !== ""
  //     ? `${payload[keys.domain]}.${rootGetters.GetDomain}`
  //     : "";
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url: path, data: payload, headers: bffHeaders })
      .then(() => {
        resolve(messages.create);
      })
      .catch(() => {
        reject(messages.createError);
      });
  });
};

export const UPDATE_CUSTOMIZATIONS = ({ getters }, payload) => {
  const path = `${url.save}${payload.id || ""}`,
    messages = getters.GetCustomMessages;
  // keys = getters.GetCustomKeys;
  // Payload => Id del Tema y Datos del formulario de personalizacion
  // ** Condiciones **
  // Guardar el valor del dominio o en su defecto un string vacio
  // payload.data[keys.domain] =
  //   keys.domain in payload.data &&
  //   payload.data[keys.domain] &&
  //   payload.data[keys.domain] !== ""
  //     ? `${payload.data[keys.domain]}.${rootGetters.GetDomain}`
  //     : "";
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url: path, data: payload.data, headers: bffHeaders })
      .then(() => {
        resolve(messages.update);
      })
      .catch((e) => {
        reject(e.response.data.error);
      });
  });
};

import Manager from "./managerData.js";
import Mantenedores from "./crudData.js";
import Integraciones from "./integratorData.js";

import { homeBreadCrumb } from "./breadcrumbs.js";

export default [
  {
    ref: "01",
    title: "",
    hideTitle: true,
    breadcrumb: [
      {
        text: "Home",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "02",
    title: "Perfil de Usuario",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Perfil",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "03",
    title: "Características del Canal",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Canal",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "04",
    title: "Personalización Corporativa",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Personalización",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "10",
    title: "Administración de Usuarios",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Usuarios",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "20",
    title: "Gestores de contenido",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Mantenedores",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "30",
    title: "Gestores de Integración",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Integración",
        disabled: true,
        href: "#",
      },
    ],
  },

  ...Mantenedores,
  ...Manager,
  ...Integraciones,
];

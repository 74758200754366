import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import * as messages from "@/assets/lang/es/messages.json";

import { userKeys } from "@/store/resources/userkeys";
import { userPosition, userState } from "@/store/resources/userdata.js";

export default {
  namespaced: true,
  state: {
    users: [],
    messages: messages.data.user,
    state: userState,
    position: userPosition,
    keys: {
      base: userKeys,
    },
  },
  actions,
  mutations,
  getters,
};

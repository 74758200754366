<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <Modal v-model="dialog" v-on="on.form" v-bind="bind.form" />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import Modal from "@/components/Modal/ModalInspeccionEmpresa.vue";
import { mapState, mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudInspeccionEmpresas",
  mixins: [PageMixin],
  data() {
    return {
      headers: [
        {
          text: "Empresa",
          align: "start",
          value: "descripcion",
        },
        { text: "Codigo", value: "codigo" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },

  components: {
    TableMantenedor,
    Modal,
  },
  computed: {
    ...mapState("InspeccionesEmpresa", ["allData"]),
    items() {
      return this.allData;
    },
  },
  methods: {
    ...mapActions("InspeccionesEmpresa", [
      "getAllData",
      "saveNewData",
      "updateData",
      "deleteItemData",
    ]),

    setup() {
      this.loading = true;
      this.getAllData().finally(() => {
        this.loading = false;
      });
    },
    saveItem(formData) {
      this.loadingForm = true;
      let method = this.saveNewData;
      let payload = {
        empresa: formData.descripcion,
        plazo_maximo_inspeccion: formData.plazo_maximo,
        es_gestionable: formData.es_gestionable,
        url_servicio: formData.url_servicio,
        codigo: formData.codigo,
      };
      if (this.selected) {
        payload.id = this.selected.id;
        method = this.updateData;
      }

      method(payload)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar empresa de inspección",
          description: "¿Desea eliminar esta empresa de inspección?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteItemData(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

<template>
  <div class="text-center">
    <v-dialog
      max-width="800"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Detalle
          <v-spacer></v-spacer>
          <v-btn color="primary" x-small fab text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <json-viewer :expand-depth="5" :value="data" copyable boxed>
          </json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";
export default {
  components: {
    JsonViewer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

import Matenedores from "@/views/Mantenedores/index.vue";

import ChannelType from "@/views/Mantenedores/tipoCanales.vue";
import PaymentMethods from "@/views/Mantenedores/mediosDePago.vue";
import CompanyInspections from "@/views/Mantenedores/InspeccionesEmpresa.vue";
import InspectionTypes from "@/views/Mantenedores/InspeccionesTipos.vue";
import Banks from "@/views/Mantenedores/Bancos.vue";
import CardTypes from "@/views/Mantenedores/TiposTarjetas.vue";
import ExchangeHouse from "@/views/Mantenedores/TasaCambio.vue";
import Restrictions from "@/views/Mantenedores/Restricciones.vue";
import AccesoPantallas from "@/views/Mantenedores/AccesoPantallas.vue";
import ConcesionariosExternos from "@/views/Mantenedores/ConcesionariosExternos.vue";
import RolesPermisos from "@/views/Mantenedores/RolesPermisos";
import TiposDePlantillas from "@/views/Mantenedores/tiposDePlantillas.vue";
import TiposDePago from "@/views/Mantenedores/TiposPago.vue";
import Parametrizacion from "@/views/Mantenedores/Parametrizacion.vue";
import PersonasPais from "@/views/Mantenedores/PersonasPais.vue";
import TiposDocumentos from "@/views/Mantenedores/TiposDocumentos.vue";

export const cruds = [
  {
    path: "mantenedores",
    name: "Mantenedores",
    component: Matenedores,
  },

  {
    path: "tipos-de-canal",
    name: "TipoCanales",
    component: ChannelType,
    meta: {
      page: "202",
    },
  },
  {
    path: "mediospago",
    name: "MediosDePago",
    component: PaymentMethods,
    meta: {
      page: "203",
    },
  },
  {
    path: "inspecciones-empresa",
    name: "InspeccionesEmpresa",
    component: CompanyInspections,
    meta: {
      page: "204",
    },
  },
  {
    path: "inspecciones-tipos",
    name: "InspeccionesTipos",
    component: InspectionTypes,
    meta: {
      page: "205",
    },
  },
  {
    path: "bancos",
    name: "Bancos",
    component: Banks,
    meta: {
      page: "206",
    },
  },
  {
    path: "tipos-tarjetas",
    name: "TiposTarjetas",
    component: CardTypes,
    meta: {
      page: "207",
    },
  },
  {
    path: "tasas-cambio",
    name: "TasaCambio",
    component: ExchangeHouse,
    meta: {
      page: "208",
    },
  },
  {
    path: "restricciones",
    name: "Restrictions",
    component: Restrictions,
    meta: {
      page: "209",
    },
  },
  {
    path: "acceso-pantallas",
    name: "accesoPantallas",
    component: AccesoPantallas,
    meta: {
      page: "210",
    },
  },
  {
    path: "concesionarios-externos",
    name: "concesionariosExternos",
    component: ConcesionariosExternos,
    meta: {
      page: "211",
    },
  },
  {
    path: "tipos-plantillas",
    name: "TiposDePlantillas",
    component: TiposDePlantillas,
    meta: {
      page: "212",
    },
  },
  {
    path: "tipos-pago",
    name: "TiposDePago",
    component: TiposDePago,
    meta: {
      page: "213",
    },
  },
  {
    path: "roles-permisos",
    name: "RolesPermisos",
    component: RolesPermisos,
    meta: {
      page: "214",
    },
  },
  {
    path: "parametrizacion",
    name: "Parametrizacion",
    component: Parametrizacion,
    meta: {
      page: "215",
    },
  },
  {
    path: "tipos-documentos",
    name: "TiposDocumentos",
    component: TiposDocumentos,
    meta: {
      page: "216",
    },
  },
  {
    path: "personasPais",
    name: "PersonasPais",
    component: PersonasPais,
    meta: {
      page: "217",
    },
  },
];

export default cruds;

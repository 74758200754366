<template>
  <div class="container">
    <v-dialog
      :value="value"
      max-width="1100"
      :persistent="loading"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Detalle
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="$emit('close')"
            :disabled="loading"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form>
            <div class="row pr-8 pl-8 pt-5 mt-0 mb-0">
              <div class="col-12">
                <label for="mensaje">Mensaje</label>
                <v-alert
                  border="left"
                  color="primary"
                  colored-border
                  class="caption"
                  elevation="1"
                >
                  <template #append>
                    <v-btn
                      color="primary"
                      x-small
                      fab
                      text
                      @click="copiarAlPortapapeles(mensaje)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  {{ mensaje }}
                </v-alert>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-md-12">
                <label for="detalle">Detalle</label>
                <json-viewer
                  v-if="isJson(detalle)"
                  :expand-depth="5"
                  :value="detalle"
                  copyable
                  boxed
                >
                </json-viewer>
                <v-alert
                  v-else
                  border="left"
                  color="primary"
                  colored-border
                  elevation="1"
                  class="caption"
                >
                  <template #append>
                    <v-btn
                      color="primary"
                      x-small
                      fab
                      text
                      @click="copiarAlPortapapeles(detalle)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  {{ detalle }}
                </v-alert>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-12">
                <label for="key">Key</label>
                <json-viewer :expand-depth="5" :value="key" copyable boxed>
                </json-viewer>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-12">
                <label for="url">Url</label>
                <v-alert
                  border="left"
                  color="primary"
                  colored-border
                  elevation="1"
                  class="caption orange--text text--darken-2"
                >
                  <template #append>
                    <v-btn
                      color="primary"
                      x-small
                      fab
                      text
                      @click="copiarAlPortapapeles(url)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <b>{{ url }}</b>
                </v-alert>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-12">
                <label for="request">Request</label>
                <json-viewer :expand-depth="5" :value="request" copyable boxed>
                </json-viewer>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-12">
                <label for="response">Response</label>
                <json-viewer :expand-depth="5" :value="response" copyable boxed>
                </json-viewer>
              </div>
            </div>
            <div
              class="row pr-8 pl-8 mt-0 mb-0 pt-0"
              style="padding-top: 0px !important"
            >
              <div class="col-12">
                <label for="stack">Stack</label>
                <json-viewer :expand-depth="5" :value="stack" copyable boxed>
                </json-viewer>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";
import BitacoraService from "@/services/manager/bitacoras/bitacora.service";

const bitacoraService = new BitacoraService();

export default {
  name: "ConsultarDetalle",
  components: {
    JsonViewer,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: true,
    },
    snackbar: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      mensaje: "",
      detalle: "",
      key: "",
      url: "",
      request: "",
      response: "",
      stack: "",
      detail: {},
    };
  },

  watch: {
    value: function (newVal) {
      if (newVal) {
        this.getById();
      }
    },
  },

  methods: {
    async getById() {
      this.loading = true;
      const response = await bitacoraService.getById(this.item._id);
      if (!response.success) {
        this.snackbar(
          "Ha ocurrido un error al intentar recuperar la información."
        );
        return false;
      }
      if (response.data === null) {
        this.snackbar("No existe información para el registro consultado.");
        return;
      }
      this.detail = response.data;
      this.url = response.data.url;
      await this.setup();
      this.loading = false;
    },

    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }

      return true;
    },

    copiarAlPortapapeles(text) {
      const textoACopiar = text; // Puedes reemplazar esto con tu texto dinámico

      // Intenta usar la API moderna
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textoACopiar)
          .then(() => {
            this.snackbar("Texto copiado al portapapeles", "success");
          })
          .catch((err) => {
            this.snackbar(`Error al copiar al portapapeles: ${err}`);
          });
      } else {
        // Si la API moderna no está disponible, intenta usar la API antigua (puede no funcionar en algunos navegadores)
        const input = document.createElement("input");
        input.value = textoACopiar;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        this.snackbar("Texto copiado al portapapeles", "success");
      }
    },

    reset() {
      this.mensaje = "";
      this.detalle = "";
      this.key = "";
      this.url = "";
      this.response = "";
      this.request = "";
      this.stack = "";
    },

    setup() {
      return new Promise((resolve) => {
        const detalle = this.detail?.detalle ?? "";
        const response = this.detail?.response ?? "";
        const request = this.detail?.request ?? "";
        const stack = this.detail?.stack ?? "";
        this.mensaje = this.detail?.mensaje ?? "";
        this.detalle = this.isJson(detalle)
          ? JSON.parse(detalle)
          : detalle ?? "";
        this.key = this.detail?.key ?? "";
        this.url = this.detail?.url ?? "";
        this.response = this.isJson(response)
          ? JSON.parse(response)
          : response ?? "";
        this.request = this.isJson(request)
          ? JSON.parse(request)
          : request ?? "";
        this.stack = this.isJson(stack) ? JSON.parse(stack) : stack ?? "";
        resolve();
      });
    },
  },
};
</script>
<style></style>

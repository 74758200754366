// type = ['title', 'divider', 'box']
// Box => Caja normal con direccion de enlace
// Title => Unicamente un titulo
// Divider => Linea divisora
import Pages from "./pages.js";
import Cruds from "./crudPages.js";
import Integrator from "./integrator.js";
import Forum from "./forum.js";
import Data from "./data.js";

export const pages = Pages;
export const crudsPages = Cruds;
export const integrator = Integrator;
export const forum = Forum;

export const data = Data;

export default data;

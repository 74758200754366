<template>
  <div>
    <v-card>
      <div class="d-flex pa-3">
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-btn
          color="primary"
          class="mr-3"
          outlined
          fab
          small
          @click="getItems()"
          :loading="loading"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" @click="dialog = true">
          {{ text.button }}
        </v-btn>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :items-per-page="pagination.limit"
        :page="pagination.page"
        :server-items-length="pagination.objects"
        @pagination="handlerSetPagination($event)"
        :footer-props="{
          'items-per-page-text': 'mostar',
          'items-per-page-options': [5, 10, 25, 50],
        }"
      >
        <template #item.eliminado="{ item }">
          <v-switch
            :input-value="!item.eliminado"
            :true-value="true"
            :false-value="false"
            dense
            inset
            color="primary"
            :hint="item.eliminado ? 'Inactivo' : 'Activo'"
            persistent-hint
            :loading="item.loading"
            @change="activateItem($event, item)"
          ></v-switch>
        </template>

        <template #item.createdAt="{ item }">
          {{ item.createdAt | formatoFecha }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="handlerSelectItem(item)"
          >
            <v-icon small> mdi-pencil-outline </v-icon>
          </v-btn>

          <v-btn color="error" x-small fab text @click="deleteItem(item)">
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="formLoading"
      max-width="500px"
      @click:outside="handlerCloseDialog()"
      @keydown.esc="handlerCloseDialog()"
    >
      <v-card>
        <v-card-title primary-title>
          {{ text.formTitle }}
          <v-spacer></v-spacer>
          <v-btn color="primary" text fab x-small @click="handlerCloseDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre del Servicio"
                  :rules="[(val) => !!val || 'El campo Nombre es obligatorio.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.codigo"
                  label="Código"
                  :rules="[(val) => !!val || 'El campo Código es obligatorio.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.descripcion"
                  label="Descripción"
                  :rules="[
                    (val) => !!val || 'El campo Descripción es obligatorio.',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="formLoading"
            @click="handlerActionForm()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AuthService from "@/services/manager/auth/services.service.js";
import SetPageMixin from "@/Mixins/SetPageMixin.js";
import dayjs from "dayjs";

const Service = new AuthService();

export default {
  name: "CrudAuthServices",
  mixins: [SetPageMixin],
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      title: "Listado de Servicios",
      text: {
        formTitle: "Formulario de Servicios",
        button: "Nuevo",
      },
      items: [],
      headers: [
        {
          value: "nombre",
          sortable: false,
          text: "Nombre",
        },
        {
          value: "codigo",
          sortable: false,
          text: "Código",
        },
        {
          value: "descripcion",
          sortable: true,
          text: "Descripción",
        },

        {
          value: "createdAt",
          sortable: true,
          text: "Fech. Creación",
        },
        {
          value: "eliminado",
          sortable: true,
          text: "Estado",
        },
        {
          value: "actions",
          sortable: false,
          align: "right",
          text: "",
        },
      ],
      loading: false,
      search: "",
      dialog: false,
      selected: null,
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
        objects: 0,
      },
      form: {
        nombre: "",
        codigo: "",
        descripcion: "",
      },
      formLoading: false,
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      Service.get(this.pagination)
        .then((response) => {
          this.items = response.result;

          this.pagination.limit = response.pagination.limit;
          this.pagination.page = response.pagination.page;
          this.pagination.pages = response.pagination.totalPages;
          this.pagination.objects = response.pagination.totalDocs;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlerSetPagination(e) {
      this.pagination.limit = e.itemsPerPage;
      this.pagination.page = e.page;
      this.pagination.pages = e.pageCount;
      this.pagination.objects = e.itemsLength;

      this.getItems();
    },

    handlerCloseDialog() {
      this.resetForm();
      this.dialog = false;
    },

    handlerActionForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },

    handlerSelectItem(item) {
      this.selected = item;
      this.form.nombre = item.nombre;
      this.form.codigo = item.codigo;
      this.form.descripcion = item.descripcion;

      this.dialog = true;
    },

    save() {
      this.formLoading = true;
      Service.post(this.form)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    update() {
      this.formLoading = true;
      Service.put(this.form, this.selected?.id ?? "")
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    activateItem(e, item) {
      const itemId = item.id;
      item.loading = true;
      let METHOD;
      if (e) {
        METHOD = Service.activate(itemId);
      } else {
        METHOD = Service.deactivate(itemId);
      }
      METHOD.then((response) => {
        this.$store.commit("setSnackbar", {
          active: true,
          text: response.message,
          top: true,
          right: true,
          color: e ? "success" : "primary",
        });
        this.getItems();
      })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          item.loading = false;
        });
    },

    deleteItem(item) {
      Service.delete(item.id)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "primary",
          });
          this.getItems();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        });
    },

    resetForm() {
      this.selected = null;
      this.form.nombre = "";
      this.form.codigo = "";
      this.form.descripcion = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>

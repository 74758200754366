<template>
  <v-dialog
    width="500"
    :persistent="loading"
    :value="value"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <v-card-title>
        Formulario Tipos inspección
        <v-btn
          x-small
          color="primary"
          fab
          text
          @click="$emit('close')"
          :disabled="loading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form ref="form">
          <v-text-field
            label="Nombre de la inspección"
            outlined
            :rules="[
              (v) => !!v || 'El campo nombre de inspección es requerido.',
            ]"
            v-model="form.inspeccion"
            dense
          ></v-text-field>

          <v-autocomplete
            outlined
            :items="inspectionList"
            dense
            item-text="label"
            item-value="value"
            :rules="[(v) => !!v || 'Debe seleccionar un tipo.']"
            label="Seleccione el tipo de Inspección"
            v-model="form.tipo_inspeccion"
          >
          </v-autocomplete>

          <v-text-field
            v-if="formData"
            label="Codigo"
            :rules="[(v) => !!v || 'El campo Codigo es requerido.']"
            v-model="form.codigo"
            dense
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="loading" @click="submit()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalInspeccionTipo",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    inspectionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        inspeccion: "",
        tipo_inspeccion: "",
        codigo: "",
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setter();
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },

    setter() {
      this.form.inspeccion = this.formData.descripcion;
      this.form.tipo_inspeccion = this.formData.tipo_inspeccion;
      this.form.codigo = this.formData.codigo;
    },
    reset() {
      this.form.inspeccion = "";
      this.form.tipo_inspeccion = "";
      this.form.codigo = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.tipoTarjetas;

export const getTiposTarjetas = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setAllTipoTarjetas", result);
        resolve({ result });
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de tipos de tarjetas.");
      });
  });
};

export const saveNewTipoTarjeta = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewTipoTarjeta", result);
        resolve({
          result,
          info: "Se ha guardado un nuevo tipo de tarjeta satisfactoriamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado guardar un nuevo tipo de tarjeta."
        );
      });
  });
};

export const updateTipoTarjeta = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateTipoTarjeta", result);
        resolve({
          result,
          info: "Se ha actualizado un tipo de tarjeta correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado actualizar un tipo de tarjeta."
        );
      });
  });
};

export const deleteTipoTarjeta = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteTipoTarjeta", id);
        resolve({ info: "Se ha eliminado un tipo de tarjeta correctamente!" });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado eliminar un tipo de tarjeta."
        );
      });
  });
};

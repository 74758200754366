import { homeBreadCrumb } from "./breadcrumbs.js";

export const integratorBreadCrumb = {
  text: "Integraciones",
  disabled: false,
  href: "/#/integracion",
};

export default [
  {
    ref: "501",
    title: "Forum - Gestor de Homologaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Homologaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "502",
    title: "Forum - Gestor de Operaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Operaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "503",
    title: "Forum - Informe de Simulaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Info. Simulaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
];

// Metodos obtener canales
export const GetChannels = (state) => {
  return state.channels;
};

export const GetAllChannelList = (state) => {
  const channels = [],
    keys = state.keys.base;
  function setChannels(preList, depId = "") {
    for (var i in preList) {
      let item = preList[i];
      const depCondition = !!item[keys.list]?.length > 0;
      channels.push({
        id: item[keys.getId],
        code: item[keys.code],
        description: item[keys.description],
        hasDependencies: depCondition,
        dependId: depId,
        main: item[keys.main],
      });
      if (depCondition) {
        setChannels(item[keys.list], item[keys.getId]);
      }
    }
  }
  setChannels(state.channels);
  return channels;
};
// Metodo de obtener el listado de las urls de acceso
export const GetAccessUrls = (state) => state.accessUrls;
// Metodos obtener informacion general
export const GetGeneralInfo = (state) => {
  return state.generalInfo;
};

export const GetCountries = (state) => {
  return state.generalInfo?.countries;
};

export const GetChannelsType = (state) => {
  return state.generalInfo?.channelsType;
};

export const GetCompanies = (state) => {
  return state.generalInfo?.companies;
};

export const GetPaymentMethods = (state) => {
  return state.generalInfo?.paymentMethods;
};

// Metodos obtener informacion avanzada
export const GetAdvanceInfo = (state) => {
  return state.advanceInfo;
};

export const GetSignatureType = (state) => state.advanceInfo?.signature;

export const GetInspections = (state) => {
  return state.advanceInfo?.inspections;
};

export const GetInspectionBusiness = (state) => {
  return state.advanceInfo?.inspections?.business;
};

export const GetInspectionTypes = (state) => {
  return state.advanceInfo?.inspections?.types;
};

// Metodos obtener configuracion avanzada
export const GetAdvanceConfig = (state) => state.advanceConfig;

// Metodos obtener productos
export const GetProducts = (state) => {
  return state.products;
};
// Metodos obtener usuarios
export const GetUsers = (state) => {
  return state.users?.list;
};
// Metodos de obtener Llaves
export const GetBaseKeys = (state) => {
  return state.keys?.base;
};

export const GetGeneralInfoKeys = (state) => {
  return state.keys?.generalInfo;
};

export const GetAdvanceInfoKeys = (state) => {
  return state.keys?.advanceInfo;
};

export const GetAdvanceConfigKeys = (state) => {
  return state.keys?.advanceConfig;
};

export const GetCompanyKeys = (state) => {
  return state.keys?.company;
};

export const GetProductKeys = (state) => {
  return state.keys?.products;
};

// Metodos de obtener textos para mensajes o notificaciones
export const GetChannelMessages = (state) => {
  return state.messages;
};

export const GetChannelDomainMessages = (state) => {
  return state.messages?.domain;
};

export const GetGeneralInfoMessages = (state) => {
  return state.messages?.generalInfo;
};

export const GetAdvanceInfoMessages = (state) => {
  return state.messages?.advanceInfo;
};

export const GetAdvanceConfigMessages = (state) => {
  return state.messages?.advanceConfig;
};

export const GetProductMessages = (state) => {
  return state.messages?.products;
};

export const GetPricingRulesMessages = (state) => {
  return state.messages?.pricingRules;
};

export const GetUserMessages = (state) => {
  return state.messages?.user;
};

export const GetCompanyMessages = (state) => {
  return state.messages?.company;
};

<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  mounted() {
    var url = window.location.href.split("/");
    this.$store.commit("SET_URL_CONVENIO", url[2]);
  },
};
</script>

<style>
@media print {
  body,
  HTML * {
    visibility: hidden;
  }
  .table * {
    visibility: visible;
  }
  .table {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
</style>

import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

const extraEndpoints = endpoints.manager.parametros.dominio;

class PlantillaService {
  constructor() {
    this.URL_API = endpoints.manager.notificaciones.plantillas;
    this.API_HEADER = bffHeaders;
  }

  getAllPlantillas = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  getIdPlantillas = async (id) => {
    try {
      const respuesta = await axios.get(`${this.URL_API}/${id}`, {
        headers: this.API_HEADER,
      });

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };

  Crearplantilla = async (data) => {
    try {
      const respuesta = await axios.post(this.URL_API, data, {
        headers: this.API_HEADER,
      });

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };

  ModificarPlantilla = async (data) => {
    const { id } = data;
    const datos = {
      nombre: data.nombre,
      estado: data.estado,
      asunto: data.asunto,
      metodoNotificacion: data.metodoNotificacion,
      cuerpo: data.cuerpo,
      requiereAdjunto: data.requiereAdjunto,
      client: data.client_id,
    };
    try {
      const respuesta = await axios.put(`${this.URL_API}/${id}`, datos, {
        headers: this.API_HEADER,
      });

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };

  metodosNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: extraEndpoints.notificacionesMetodoNotificacion,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  estadoNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: extraEndpoints.notificacionesEstadoPlantilla,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  eliminarPlantilla = async (id) => {
    try {
      const respuesta = await axios.delete(`${this.URL_API}/${id}`, {
        headers: this.API_HEADER,
      });

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };
}

export default PlantillaService;

<template>
  <div>
    <v-card>
      <div class="d-flex pa-3 align-center">
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-btn
          color="primary"
          class="mr-3"
          outlined
          fab
          small
          @click="getItems()"
          :loading="loading"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" @click="dialog = true">
          {{ text.button }}
        </v-btn>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :items-per-page="pagination.limit"
        :page="pagination.page"
        :server-items-length="pagination.objects"
        @pagination="handlerSetPagination($event)"
        :footer-props="{
          'items-per-page-text': 'mostar',
          'items-per-page-options': [5, 10, 25, 50],
        }"
      >
        <template #item.nombre="{ item }">
          {{ `${item.nombre} ${item.primer_apellido}` }}
        </template>

        <template #item.eliminado="{ item }">
          <v-switch
            :input-value="!item.eliminado"
            :true-value="true"
            :false-value="false"
            dense
            inset
            color="primary"
            :hint="item.eliminado ? 'Inactivo' : 'Activo'"
            persistent-hint
            :loading="item.loading"
            @change="activateItem($event, item)"
          ></v-switch>
        </template>

        <template #item.createdAt="{ item }">
          {{ item.createdAt | formatoFecha }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="handlerSelectItem(item)"
          >
            <v-icon small> mdi-pencil-outline </v-icon>
          </v-btn>
          <v-btn
            color="blue-grey"
            x-small
            fab
            text
            @click="(selected = item), (passDialog = true)"
          >
            <v-icon small> mdi-key-variant </v-icon>
          </v-btn>
          <v-btn color="error" x-small fab text @click="deleteItem(item)">
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- PASSWORD DIALOG -->
    <v-dialog
      v-model="passDialog"
      :persistent="loadingPassword"
      max-width="300px"
      @click:outside="(form.clave = ''), (passDialog = false)"
    >
      <v-card>
        <v-card-title primary-title>
          Cambiar contraseña <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click:outside="(form.clave = ''), (passDialog = false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="passForm" autocomplete="off">
            <h4 class="subtitle-2 text-center">
              {{ getSelectedUserName() }}
            </h4>
            <v-text-field
              v-model="form.clave"
              label="Contraseña"
              placeholder="Ingrese la nueva contraseña"
              :rules="[
                (v) => !!v || 'Debe escribir una contraseña.',
                (v) =>
                  (v && v.length >= 8) ||
                  'La contraseña debe tener un mínimo de 8 carácteres.',
              ]"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="loadingPassword"
            @click="savePassword()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- FORM DIALOG -->
    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="formLoading"
      max-width="500px"
      @click:outside="handlerCloseDialog()"
      @keydown.esc="handlerCloseDialog()"
    >
      <v-form ref="form" autocomplete="off">
        <v-card>
          <v-card-title primary-title>
            {{ text.formTitle }}
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              x-small
              fab
              @click="handlerCloseDialog()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <h4 class="subtitle-2">Información de usuario</h4>
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-model="form.usuario"
                  autocomplete="off"
                  :disabled="!!selected"
                  label="Usuario"
                  :rules="[(val) => !!val || 'El campo usuario es requerido.']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.tipo_usuario"
                  :items="userType"
                  :rules="[(val) => !!val || 'Debe seleccionar un tipo.']"
                  label="Tipo"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-model="form.celular"
                  label="Telefono"
                  :rules="[(val) => !!val || 'El campo telefono es requerido.']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.estado"
                  :items="userEstatus"
                  :rules="[(val) => !!val || 'Debe seleccionar un estatus.']"
                  label="Estatus"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="form.correo_electronico"
                  label="Correo electronico"
                  :rules="[
                    (v) => !!v || 'El campo correo es requerido.',
                    (v) =>
                      /.+@.+\..+/.test(v) ||
                      'Debe escribir un correo electronico válido.',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <h4 class="subtitle-2">Información personal</h4>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre"
                  :rules="[(val) => !!val || 'El campo Nombre es obligatorio.']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.primer_apellido"
                  label="Primer apellido"
                  :rules="[
                    (val) =>
                      !!val || 'El campo primer apellido es obligatorio.',
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.segundo_apellido"
                  label="Segundo apellido"
                  :rules="[
                    (val) =>
                      !!val || 'El campo segundo apellido es obligatorio.',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <h4 class="subtitle-2">Permisos del usuario</h4>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.id_roles"
                  label="Roles"
                  :items="roleItems"
                  item-text="nombre"
                  item-value="id"
                  :loading="roleLoading"
                  :rules="[
                    (val) =>
                      val.length > 0 || 'Debe seleccionar al menos un rol.',
                  ]"
                  multiple
                >
                  <template
                    v-if="roleItems.length < rolePagination.objects"
                    v-slot:append-item
                  >
                    <div
                      v-intersect="getRoleItems()"
                      class="pa-4 primary--text"
                    >
                      Cargando más elementos...
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text v-if="!selected">
            <h4 class="subtitle-2">Contraseña</h4>
            <v-text-field
              v-model="form.clave"
              autocomplete="off"
              placeholder="Escriba su contraseña"
              :rules="[
                (v) => !!v || 'La contraseña no puede quedar vacía',
                (v) =>
                  (v && v.length >= 8) ||
                  'La contraseña debe tener un mínimo de 8 carácteres.',
              ]"
            ></v-text-field>

            <!-- <v-text-field
              v-model="repeatPassword"
              autocomplete="off"
              placeholder="Escriba su nuevamente su contraseña"
              type="password"
              :rules="[
                (val) => !!val || 'Este campo no puede quedar vacío',
                (val) => val === form.clave || 'Las contraseñas no coincíden.'
              ]"
            ></v-text-field> -->
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :loading="formLoading"
              @click="handlerActionForm()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import AuthAppService from "@/services/manager/auth/app.service.js";
import AuthRoleService from "@/services/manager/auth/roles.service.js";
import AuthService from "@/services/manager/auth/user.service.js";

import SetPageMixin from "@/Mixins/SetPageMixin.js";
import dayjs from "dayjs";

const Service = new AuthService();
const RoleService = new AuthRoleService();
const AppService = new AuthAppService();

export default {
  name: "CrudAuthUsers",
  mixins: [SetPageMixin],
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      title: "Listado de Usuarios",
      text: {
        formTitle: "Formulario de Usuarios",
        formNameField: "Nombre de Usuario",
        button: "Nuevo",
      },
      items: [],
      roleItems: [],
      appItems: [],
      userEstatus: [
        {
          text: "Activo",
          value: "ACTIVO",
        },
        {
          text: "Pendiente",
          value: "PENDIENTE",
        },
        {
          text: "Inactivo",
          value: "INACTIVO",
        },
        {
          text: "Bloqueado",
          value: "BLOQUEADO",
        },
      ],
      userType: [
        {
          text: "RUT",
          value: "RUT",
        },
        {
          text: "Código Forum",
          value: "CODIGO_FORUM",
        },
      ],
      headers: [
        {
          value: "usuario",
          sortable: true,
          text: "Usuario",
        },
        {
          value: "nombre",
          sortable: true,
          text: "Nombre",
        },
        {
          value: "correo_electronico",
          sortable: true,
          text: "Correo",
        },
        {
          value: "celular",
          sortable: true,
          text: "Telefono",
        },
        {
          value: "createdAt",
          sortable: true,
          text: "Fech. Creación",
        },
        {
          value: "eliminado",
          sortable: true,
          text: "Estado",
        },
        {
          value: "actions",
          sortable: false,
          align: "right",
          text: "",
        },
      ],

      loading: false,
      loadingPassword: false,
      roleLoading: false,
      appLoading: false,

      search: "",

      dialog: false,
      passDialog: false,

      selected: null,
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
        objects: 0,
      },
      rolePagination: {
        limit: 10,
        page: 1,
        pages: 1,
        objects: 1,
      },
      appPagination: {
        limit: 10,
        page: 1,
        pages: 1,
        objects: 1,
      },
      form: {
        tipo_usuario: "",
        estado: "",
        nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
        celular: "",
        correo_electronico: "",
        usuario: "",
        clave: "",
        id_roles: [],
        id_aplicaciones: [],
      },
      repeatPassword: "",
      formLoading: false,
      errors: {
        app: false,
      },
    };
  },
  watch: {
    dialog(newVal, oldVal) {
      if (newVal && !oldVal) {
        if (this.roleItems.length <= 0) {
          this.getRoleItems();
        }
        if (this.appItems.length <= 0) {
          this.getAppItems();
        }
      }
    },
  },
  methods: {
    getItems() {
      this.loading = true;
      Service.get(this.pagination)
        .then((response) => {
          this.items = response.result;

          this.pagination.limit = response.pagination.limit;
          this.pagination.page = response.pagination.page;
          this.pagination.pages = response.pagination.totalPages;
          this.pagination.objects = response.pagination.totalDocs;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getRoleItems() {
      if (
        !this.roleLoading &&
        this.rolePagination.objects > this.roleItems.length
      ) {
        this.roleLoading = true;
        RoleService.get(this.rolePagination)
          .then((response) => {
            this.roleItems = [...this.roleItems, ...response.result];

            this.rolePagination.limit = response.pagination.limit;
            this.rolePagination.page += 1;
            this.rolePagination.pages = response.pagination.totalPages;
            this.rolePagination.objects = response.pagination.totalDocs;
          })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.roleLoading = false;
          });
      }
    },

    getAppItems() {
      if (
        !this.appLoading &&
        this.appPagination.objects > this.appItems.length
      ) {
        this.appLoading = true;
        AppService.get(this.appPagination)
          .then((response) => {
            this.appItems = [...this.appItems, ...response.result];

            this.appPagination.limit = response.pagination.limit;
            this.appPagination.page += 1;
            this.appPagination.pages = response.pagination.totalPages;
            this.appPagination.objects = response.pagination.totalDocs;
          })
          .catch((error) => {
            this.errors.app = true;
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.appLoading = false;
          });
      }
    },

    getSelectedUserName() {
      return this.selected?.usuario ?? "";
    },

    handlerSetPagination(e) {
      this.pagination.limit = e.itemsPerPage;
      this.pagination.page = e.page;
      this.pagination.pages = e.pageCount;
      this.pagination.objects = e.itemsLength;

      this.getItems();
    },

    handlerCloseDialog() {
      this.resetForm();
      this.dialog = false;
    },

    handlerActionForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },

    handlerSelectItem(item) {
      this.selected = item;
      this.form.nombre = item.nombre;
      this.form.id_roles = item.id_roles;
      this.form.id_aplicaciones = item.id_aplicaciones;

      this.form.tipo_usuario = item.tipo_usuario;
      this.form.estado = item.estado;
      this.form.primer_apellido = item.primer_apellido;
      this.form.segundo_apellido = item.segundo_apellido;
      this.form.celular = item.celular;
      this.form.correo_electronico = item.correo_electronico;
      this.form.usuario = item.usuario;

      this.dialog = true;
    },

    save() {
      this.formLoading = true;
      Service.post(this.form)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    savePassword() {
      const isValid = this.$refs.passForm.validate();
      if (isValid) {
        this.loadingPassword = true;
        Service.changePassword(
          { password: this.form.clave },
          this.selected.usuario
        )
          .then((response) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: response.message,
              top: true,
              right: true,
              color: "success",
            });
            this.passDialog = false;
            this.selected = null;
            this.form.clave = "";
          })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingPassword = false;
          });
      }
    },

    update() {
      this.formLoading = true;
      const payload = {
        tipo_usuario: this.form.tipo_usuario,
        estado: this.form.estado,
        nombre: this.form.nombre,
        primer_apellido: this.form.primer_apellido,
        segundo_apellido: this.form.segundo_apellido,
        celular: this.form.celular,
        correo_electronico: this.form.correo_electronico,
        id_roles: this.form.id_roles,
        id_aplicaciones: this.form.id_aplicaciones,
      };
      Service.put(payload, this.selected?.usuario ?? "")
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    activateItem(e, item) {
      const itemId = item.id;
      item.loading = true;
      let METHOD;
      if (e) {
        METHOD = Service.activate(itemId);
      } else {
        METHOD = Service.deactivate(itemId);
      }
      METHOD.then((response) => {
        this.$store.commit("setSnackbar", {
          active: true,
          text: response.message,
          top: true,
          right: true,
          color: e ? "success" : "primary",
        });
        this.getItems();
      })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          item.loading = false;
        });
    },

    deleteItem(item) {
      Service.delete(item.id)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "primary",
          });
          this.getItems();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        });
    },

    resetForm() {
      this.selected = null;
      this.form.nombre = "";
      this.form.id_aplicaciones = [];
      this.form.id_roles = [];

      this.form.tipo_usuario = "";
      this.form.estado = "";
      this.form.primer_apellido = "";
      this.form.segundo_apellido = "";
      this.form.celular = "";
      this.form.correo_electronico = "";
      this.form.usuario = "";
      this.form.clave = "";

      this.repeatPassword = "";

      this.$refs.form.resetValidation();

      this.errors.app = false;
    },
  },
};
</script>

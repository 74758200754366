import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

const httpMethod = (
  payload = {
    method: "",
    path: "",
    form: null,
    params: null,
    messages: {
      error: "",
      success: "",
    },
    commitResult: false,
    commit: null,
    commitMethod: "",
  }
) => {
  return new Promise((resolve, reject) => {
    const axiosData = {
      method: payload.method,
      url: payload.path,
      headers: bffHeaders,
    };
    if (payload.form) {
      axiosData.data = payload.form;
    }
    if (payload.params) {
      axiosData.params = payload.params;
    }
    axios(axiosData)
      .then((response) => {
        const result = response.data.data,
          info = payload.messages.success ?? "";
        if (payload.commitResult) {
          payload.commit(payload.commitMethod, result);
        }
        resolve({
          result,
          info,
        });
      })
      .catch(() => reject(new Error(payload.messages.error).toString()));
  });
};

export const GET = ({ commit, getters }, params) => {
  const path = getters.GetEndpoint;
  return httpMethod({
    method: "GET",
    path,
    params,
    messages: {
      error:
        "Ha ocurrido un error al intentar recuperar el listado de restricciones.",
    },
    commitResult: true,
    commit,
    commitMethod: "setItems",
  });
};

export const POST = ({ getters }, form) => {
  const path = getters.GetEndpoint;
  return httpMethod({
    method: "POST",
    path,
    form,
    messages: {
      error: "Ha ocurrido un error al intentar guardar una restricción.",
      success: "Se ha guardado la restricción satisfactoriamente",
    },
  });
};

export const DELETE = ({ getters }, id) => {
  const path = `${getters.GetEndpoint}/${id}`;
  return httpMethod({
    method: "DELETE",
    path,
    messages: {
      error: "Ha ocurrido un error al intentar eliminar una restricción.",
      success: "Se ha eliminado una restricción correctamente",
    },
  });
};

export default [
  {
    ref: "202",
    path: "TipoCanales",
    disabled: false,
    title: "Tipo canales",
    name: "Tipo canales",
    text: "Mantenedor para los diferentes tipo de canales",
    type: "box",
    icons: {
      menu: "apache-kafka",
      index: "apache-kafka",
    },
  },
  {
    ref: "203",
    path: "MediosDePago",
    disabled: false,
    title: "Medios de pago",
    name: "Medios de pago",
    text: "Mantenedor para los diferentes medios de pago",
    type: "box",
    icons: {
      menu: "arrange-bring-to-front",
      index: "arrange-bring-to-front",
    },
  },
  {
    ref: "204",
    path: "InspeccionesEmpresa",
    disabled: false,
    title: "Inspec. Empresas",
    name: "Empresas para inspección",
    text: "Mantenedor empresas de inspección",
    type: "box",
    icons: {
      menu: "domain",
      index: "domain",
    },
  },
  {
    ref: "205",
    path: "InspeccionesTipos",
    disabled: false,
    title: "Tipos de inspección",
    name: "Tipos de inspección",
    text: "Mantenedor tipos de inspección",
    type: "box",
    icons: {
      menu: "arrow-expand-all",
      index: "arrow-expand-all",
    },
  },
  {
    ref: "206",
    path: "Bancos",
    disabled: false,
    title: "Bancos",
    name: "Bancos",
    text: "Mantenedor de Bancos",
    type: "box",
    icons: {
      menu: "bank-outline",
      index: "bank-outline",
    },
  },
  {
    ref: "207",
    path: "TiposTarjetas",
    disabled: false,
    title: "Tipos de Tarjetas",
    name: "Tipos de Tarjetas",
    text: "Mantenedor de Tipos de Tarjetas",
    type: "box",
    icons: {
      menu: "credit-card-settings-outline",
      index: "credit-card-settings-outline",
    },
  },
  {
    ref: "208",
    path: "TasaCambio",
    disabled: false,
    title: "Tasas de Cambio",
    name: "Tasas de Cambio",
    text: "Mantenedor de Tasas de Cambio",
    type: "box",
    icons: {
      menu: "swap-horizontal",
      index: "swap-horizontal",
    },
  },
  {
    ref: "209",
    path: "Restrictions",
    disabled: false,
    title: "Restricciones",
    name: "Restricciones",
    text: "Configurador de tipos de restricciones",
    type: "box",
    icons: {
      menu: "lock-open-outline",
      index: "lock-open-outline",
    },
  },
  {
    ref: "210",
    path: "accesoPantallas",
    disabled: false,
    title: "Acceso Pantallas",
    name: "AccesoPantallas",
    text: "Configurador de Acceso Pantallas",
    type: "box",
    icons: {
      menu: "card-multiple-outline",
      index: "card-multiple-outline",
    },
  },
  {
    ref: "211",
    path: "concesionariosExternos",
    disabled: false,
    title: "Concesionarios Ext.",
    name: "ConcesionariosExternos",
    text: "Configurador de Concesionarios Externos",
    type: "box",
    icons: {
      menu: "card-multiple-outline",
      index: "card-multiple-outline",
    },
  },
  {
    ref: "212",
    path: "TiposDePlantillas",
    disabled: false,
    title: "Tipos de Plantillas",
    name: "TiposDePlantillas",
    text: "Configurador de categorías para plantillas",
    type: "box",
    icons: {
      menu: "window-open",
      index: "window-open",
    },
  },
  /*
  {
    ref: "213",
    path: "TiposDePago",
    disabled: false,
    title: "Tipos Medios de Pago",
    name: "TiposDePago",
    text: "Configurador de tipos medios de pago",
    type: "box",
    icons: {
      menu: "account-credit-card",
      index: "account-credit-card",
    },
  },
  */
  {
    ref: "214",
    path: "RolesPermisos",
    disabled: false,
    title: "Roles y Permisos",
    name: "RolesPermisos",
    text: "Mantenedor de Roles y Permisos",
    type: "box",
    icons: {
      menu: "lock",
      index: "lock",
    },
  },
  {
    ref: "215",
    path: "Parametrizacion",
    disabled: false,
    title: "Parametrización",
    name: "Parametrizacion",
    text: "Mantenedor de Parametrización",
    type: "box",
    icons: {
      menu: "cube-scan",
      index: "cube-scan",
    },
  },
  {
    ref: "216",
    path: "TiposDocumentos",
    disabled: false,
    title: "Tipos de Documentos",
    name: "Tipos de Documentos",
    text: "Mantenedor de Tipos de Documentos",
    type: "box",
    icons: {
      menu: "file-document",
      index: "file-document",
    },
  },
  {
    ref: "217",
    path: "PersonasPais",
    disabled: false,
    title: "Personas",
    name: "PersonasPais",
    text: "Mantenedor de Personas",
    type: "box",
    icons: {
      menu: "account-group",
      index: "account-group",
    },
  },
];

<template>
  <ListBitacora :query="query" :headers="headers" />
</template>

<script>
import ListBitacora from "@/components/Bitacora/ListBitacora.vue";

export default {
  name: "Ventas",
  components: {
    ListBitacora,
  },
  setup() {
    return {
      query: "app=api-venta",
      headers: [
        { text: "Fecha", sortable: true, value: "creadoEl" },
        { text: "App", value: "app" },
        { text: "Funcion", value: "funcion" },
        { text: "Número Documento", value: "key.numeroDocumento" },
        { text: "Evento", value: "tipoEvento" },
        /* { text: 'Estado', value: 'eliminado' }, */
        { text: "", value: "acciones", align: "right" },
      ],
    };
  },
};
</script>

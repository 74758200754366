import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import createPersistedState from "vuex-persistedstate";

import Authentication from "./Modules/Authentication/Authentication";
import Channels from "./Modules/Channel";
import Users from "./Modules/Users";
import Customization from "./Modules/Customization";

// Matenedores
import TipoCanal from "./Mantenedores/TipoCanal";
import Bancos from "./Mantenedores/Bancos";
import MediosDePago from "./Mantenedores/MediosPago";
import InspeccionesEmpresa from "./Mantenedores/InspeccionesEmpresa";
import InspeccionesTipos from "./Mantenedores/InspeccionesTipos";
import TiposTarjetas from "./Mantenedores/TiposTarjetas";
import TasaCambio from "./Mantenedores/TasaCambio";
import Restricciones from "./Mantenedores/Restricciones";
import Homologaciones from "./Mantenedores/Homologaciones";
import AccesoPantallas from "./Mantenedores/AccesoPantallas";
import Concesionarios from "./Mantenedores/Concesionarios";
import Sucursales from "./Mantenedores/Sucursales";
import Vendedores from "./Mantenedores/Vendedores";
import TiposMediosPago from "./Mantenedores/TiposMediosPago";
import Roles from "./Mantenedores/Roles";
import Modulos from "./Mantenedores/Modulos";
import Permisos from "./Mantenedores/Permisos";
import Cotizaciones from "./Mantenedores/Cotizaciones";
import Propuestas from "./Mantenedores/Propuestas";
import Parametrizacion from "./Mantenedores/Parametrizacion";
import Personas from "./Mantenedores/Personas";
import TiposDocumentos from "./Mantenedores/TiposDocumentos";
import Territorios from "./Mantenedores/Territorios";

// JSON
import * as messages from "@/assets/lang/es/messages.json";
import * as esText from "@/assets/lang/es/text.json";

import PageData from "@/router/data/index.js";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

import { messagesKeys, paginationKeys } from "@/store/resources/generalKeys.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    version: "",
    domain: process.env.VUE_APP_DOMAIN
      ? process.env.VUE_APP_DOMAIN
      : "mesos.cl",
    convenio: process.env.VUE_APP_CONVENIO
      ? process.env.VUE_APP_CONVENIO
      : "negocio",
    page: {
      title: "Dashboard",
      breadcrumb: [],
    },
    pageData: PageData,
    snackbar: {
      active: false,
      text: "",
      timeout: 5000,
      color: "",
      top: false,
      bottom: false,
      right: false,
      left: false,
      centered: false,
    },
    messages: {
      errors: messages.data.errors,
    },
    keys: {
      pagination: paginationKeys,
      messages: messagesKeys,
    },
    text: esText.data.general,
    endpoints: endpoints.general,
    panelClass:
      "secondary primary--text text--darken-4 font-weight-bold text-h6 py-2",
    countries: [],
  }),
  mutations: {
    setVersion(state, payload) {
      state.version = payload;
    },
    setPage(state, payload) {
      if ("title" in payload) state.page.title = payload.title;
      if ("breadcrumb" in payload) state.page.breadcrumb = payload.breadcrumb;
      if ("page" in payload) {
        let dataFiltered = state.pageData.find((e) => e.ref == payload.page);
        if (dataFiltered) {
          state.page.title = dataFiltered.title;
          state.page.breadcrumb = dataFiltered.breadcrumb;
        }
      }
    },
    setSnackbar(state, payload) {
      if ("text" in payload) state.snackbar.text = payload.text;
      if ("active" in payload) state.snackbar.active = payload.active;
      if ("timeout" in payload) state.snackbar.timeout = payload.timeout;
      if ("color" in payload) state.snackbar.color = payload.color;
      if ("top" in payload) state.snackbar.top = payload.top;
      if ("left" in payload) state.snackbar.left = payload.left;
      if ("right" in payload) state.snackbar.right = payload.right;
      if ("bottom" in payload) state.snackbar.bottom = payload.bottom;
      if ("centered" in payload) state.snackbar.centered = payload.centered;
    },
    setContries: (state, payload) => (state.countries = payload),
  },
  getters: {
    GetVersion: (state) => state.version,
    GetPage(state) {
      return state.page;
    },
    GetSnackbar(state) {
      return state.snackbar;
    },
    GetDomain(state) {
      return state.domain;
    },
    GetConvenio(state) {
      return `${state.convenio}.${state.domain}`.replace(/\s/g, "");
    },
    GetErrorMessages(state) {
      return state.messages.errors;
    },
    GetPaginationKeys(state) {
      return state.keys.pagination;
    },
    GetText: (state) => state.text,
    GetEndpoints: (state) => state.endpoints,
    GetError(state) {
      const keys = state.keys.messages,
        errors = state.messages.errors;
      return function (
        responseError,
        defaultMessage = "Ops, ha ocurrido un error!",
        defaultType = "warning"
      ) {
        let response = {
          message: "",
          type: "error",
        };
        try {
          const error = responseError.response.data;
          if (errors[error[keys.code]]) {
            response.message = errors[error[keys.code]];
            response.type = defaultType;
          } else if (error.data[keys.list]?.length > 0) {
            response.message = error.data[keys.list][0][keys.message];
          } else {
            response.message = defaultMessage;
          }
        } catch {
          response.message = defaultMessage;
        }
        return response;
      };
    },
    GetCountries: (state) => state.countries,
  },
  actions: {
    REQUEST_FILE({ commit }, url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((r) => resolve(r.data))
          .catch(() => {
            commit("setSnackbar", {
              active: true,
              color: "warning",
              top: true,
              right: true,
              text: "No se ha logrado recuperar la imagen o archivo del servidor.",
            });
            reject("error");
          });
      });
    },
    REQUEST_COUNTRIES({ commit, getters }) {
      const path = getters.GetEndpoints.countries;
      return new Promise((resolve, reject) => {
        axios({ method: "GET", url: path, headers: bffHeaders })
          .then((response) => {
            const result = response.data.data.result;
            commit("setContries", result);
            resolve(result);
          })
          .catch(() => {
            reject(
              "Ha ocurrido un error al intentar recuperar el listado de paises."
            );
          });
      });
    },
  },
  modules: {
    Authentication,
    Channels,
    Users,
    Customization,

    //Mantenedores
    TipoCanal,
    MediosDePago,
    InspeccionesEmpresa,
    InspeccionesTipos,
    Bancos,
    TiposTarjetas,
    TasaCambio,
    Restricciones,
    Homologaciones,
    AccesoPantallas,
    Concesionarios,
    Sucursales,
    Vendedores,
    TiposMediosPago,
    Roles,
    Modulos,
    Permisos,
    Cotizaciones,
    Propuestas,
    Parametrizacion,
    Personas,
    TiposDocumentos,
    Territorios,
  },
  /*plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],*/
});

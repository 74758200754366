<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-on="on.close" v-bind="bind.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-3">
        <v-form :ref="formRef">
          <v-autocomplete
            v-model="form.canal"
            :items="canales"
            item-text="canal_descripcion"
            return-object
            :loading="loadingLists.canal"
            label="Convenio"
            no-data-text="No hay información"
            :rules="[(v) => !!v || 'Debe seleccionar un Convenio.']"
            @change="getSucursal($event?.id_canal_secuencia)"
          ></v-autocomplete>
          <v-autocomplete
            label="Sucursal"
            v-model="form.sucursal"
            :loading="loadingLists.sucursal"
            :items="sucursales"
            item-text="sucursal_descripcion"
            item-value="canalId"
            :disabled="!form.canal"
            :rules="[(v) => !!v || 'Debe seleccionar una Sucursal.']"
            no-data-text="No hay información"
          ></v-autocomplete>
          <v-text-field
            label="Sucursal Forum"
            :rules="[(v) => !!v || 'El campo sucursal forum es requerido.']"
            v-model="form.homologacion"
          ></v-text-field>
          <v-text-field
            label="Descripción"
            v-model="form.homologacionDescripcion"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import RegisterMixin from "@/Mixins/RegisterMixin.js";

export default {
  mixins: [FormMixin, RegisterMixin],
  name: "ModalHomologaciones",
  data() {
    return {
      defaultTitle: "Formulario Homologaciones Sucursales",
      form: {
        canal: null,
        sucursal: "",
        homologacion: "",
        homologacionDescripcion: "",
      },
      canales: [],
    };
  },
  methods: {
    setter() {
      this.form.canal = {
        _id: this.formData.canal,
        canal_descripcion: this.formData.canal_descripcion,
      };
      const canalSecuencia = this.canales.find(
        (e) => e._id === this.formData.canal
      )?.id_canal_secuencia;
      this.getSucursal(canalSecuencia);
      this.form.sucursal = this.formData.salida;
      this.form.homologacion = this.formData.entrada;
      this.form.homologacionDescripcion = this.formData.descripcion;
    },
    reset() {
      this.form.canal = "";
      this.form.sucursal = "";
      this.form.homologacion = "";
      this.form.homologacionDescripcion = "";
    },
  },
};
</script>

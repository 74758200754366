export const setRoles = (state, payload) => {
  state.roles = payload.map((rol) => ({
    ...rol,
    ...{ es_activo: rol.es_activo ? "Si" : "No" },
  }));
};

export const setNewRol = (state) => {
  // state.modulos = [data, ...state.modulos];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateRol = (state) => {
  // state.modulos = state.modulos.map((row) => (row.id === data.id ? data : row));
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteRol = (state) => {
  // state.modulos = state.modulos.filter((row) => row.id !== id);
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
};

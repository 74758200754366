import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class AppService {
  constructor() {
    this.PATH = endpoints.manager.aplicaciones;
    this.HEADERS = bffHeaders;
  }

  static httpMethod(data = {}, messages = { success: "", error: "" }) {
    return new Promise((resolve, reject) => {
      axios(data)
        .then((response) => {
          resolve({
            message: response.data.message,
            result: response.data.data,
            pagination: response.data.pagination,
          });
        })
        .catch((error) => {
          const errorData = error.response?.data?.error ?? messages.error;
          reject(new Error(errorData));
        });
    });
  }

  get(pagination = {}) {
    const path = `${this.PATH}?${[
      `page=${pagination?.page ?? 1}`,
      `limit=${pagination?.limit ?? 10}`,
    ].join("&")}`;

    const http = {
      url: path,
      method: "GET",
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      error: "No se ha logrado recuperar el listado de aplicaciones.",
    });
  }

  post(formData = {}) {
    const http = {
      url: this.PATH,
      method: "POST",
      data: formData,
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      success: "¡Se ha creado una nueva aplicación satisfactoriamente!",
      error: "Ha ocurrido un error al intentar crear una aplicación.",
    });
  }

  put(formData = {}, itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "PATCH",
      data: formData,
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      success: "¡Se ha actualizado una aplicación correctamente!",
      error: "Ha ocurrido un error al intentar actualizar una aplicación.",
    });
  }

  activate(itemId = "") {
    const http = {
      url: `${this.PATH}/activar/${itemId}`,
      method: "PATCH",
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      success: "¡Se ha reactivado una aplicación correctamente!.",
      error: "Ha ocurrido un error al reactivar una aplicación.",
    });
  }

  deactivate(itemId = "") {
    const http = {
      url: `${this.PATH}/desactivar/${itemId}`,
      method: "PATCH",
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      success: "Se ha desactivado una aplicación satisfactoriamente.",
      error: "Ha ocurrido un error al intentar desactivar una aplicación.",
    });
  }

  delete(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "DELETE",
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      success: "Se ha eliminado una aplicación correctamente.",
      error: "Ha ocurrido un error al intentar eliminar una aplicación.",
    });
  }
  getAplicacionesParaUsuario() {
    const path = `${this.PATH}/aplicacionesp-para-usuario`;
    const http = {
      url: path,
      method: "GET",
      headers: this.HEADERS,
    };

    return AppService.httpMethod(http, {
      error:
        "No se ha logrado recuperar el listado de aplicaciones para usuarios.",
    });
  }
}

export default AppService;

<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <modal-bancos v-model="dialog" v-on="on.form" v-bind="bind.form" />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalBancos from "@/components/Modal/ModalBancos.vue";
import { mapState, mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "BanksCrudPage",
  mixins: [PageMixin],
  data() {
    return {
      headers: [
        {
          text: "Banco",
          align: "start",
          value: "banco_descripcion",
        },
        { text: "Codigo", value: "codigo" },
        { text: "", align: "right", value: "actions", sortable: false },
      ],
    };
  },
  components: {
    TableMantenedor,
    ModalBancos: ModalBancos,
  },
  computed: {
    ...mapState("Bancos", ["bancos"]),
    items() {
      return this.bancos;
    },
  },
  methods: {
    ...mapActions("Bancos", [
      "getBancos",
      "saveNewBanco",
      "updateBanco",
      "deleteBanco",
    ]),

    setup() {
      this.loading = true;
      this.getBancos().finally(() => {
        this.loading = false;
      });
    },

    saveItem(formData) {
      this.loadingForm = true;
      let method = this.saveNewBanco,
        payload = {
          banco_descripcion: formData.descripcion,
          codigo: formData.codigo.toUpperCase().replace(/ /g, "_"),
        };
      if (this.selected) {
        payload.id = this.selected.id;
        method = this.updateBanco;
      }
      method(payload)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar un Banco",
          description: "¿Está seguro que desea eliminar este banco?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteBanco(item.id)
              .then((response) => {
                this.snackbar(response, "success");
                this.setup();
              })
              .catch((response) => {
                this.snackbar(response);
              });
          }
        });
    },
  },
};
</script>

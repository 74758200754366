import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
const url = endpoints.cruds.tipoDocumentos;
const urlPaises = endpoints.general.countries;
import { bffHeaders } from "@/helpers/headers.js";

export const getTiposDocumentos = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.data;
        commit("setAllTipoDocumentos", result);
        resolve({ result });
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de tipos de documentos.");
      });
  });
};

export const saveNewTipoDocumento = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewTipoDocumento", result);
        resolve({
          result,
          info: "Se ha guardado un nuevo tipo de documento satisfactoriamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado guardar un nuevo tipo de documento."
        );
      });
  });
};

export const updateTipoDocumento = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateTipoDocumento", result);
        resolve({
          result,
          info: "Se ha actualizado un tipo de documento correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado actualizar un tipo de documento."
        );
      });
  });
};

export const deleteTipoDocumento = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteTipoDocumento", id);
        resolve({
          info: "Se ha eliminado un tipo de documento correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado eliminar un tipo de documento."
        );
      });
  });
};

export const getPaises = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: urlPaises, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result.sort((a, b) =>
          a.pais_descripcion.localeCompare(b.pais_descripcion)
        );
        commit(
          "setPaises",
          result.map((e) => {
            return {
              codigo: e.codigo,
              moneda_destino: e.moneda_destino,
              valor_impuesto: e.valor_impuesto,
              _id: e.pais,
              pais_descripcion:
                e.pais_descripcion.charAt(0).toUpperCase() +
                e.pais_descripcion.slice(1).toLowerCase(),
            };
          })
        );
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de paises.");
      });
  });
};

<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        v-bind="bind.search"
        placeholder="Buscar un tipo de plantilla..."
      ></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <v-data-table :search="search" v-bind="bind.table">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" text fab x-small @click="openForm(item)">
          <v-icon small>{{ icons.edit }}</v-icon>
        </v-btn>
        <v-btn color="error" text fab x-small @click="deleteItem(item)">
          <v-icon small>{{ icons.delete }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudTipoPlantillas",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          align: "start",
          filterable: true,
          sortable: true,
          value: "nombre_plantilla",
        },
        {
          text: "Descripción",
          align: "start",
          filterable: true,
          sortable: true,
          value: "descripcion_plantilla",
        },
        {
          text: "Valor",
          align: "start",
          filterable: false,
          sortable: false,
          value: "valor_plantilla",
        },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
    };
  },
  computed: {
    items() {
      return [];
    },
  },
  methods: {},
};
</script>

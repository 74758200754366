<template>
  <div class="text-center">
    <v-dialog
      width="500"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario medios de pago
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  label="Código"
                  required
                  v-model="form.codigo"
                  outlined
                  dense
                  persistent-hint
                  hint="Escriba un código para el medio de pago"
                  @blur="slug"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Descripción"
                  required
                  v-model="form.descripcion"
                  outlined
                  dense
                  persistent-hint
                  hint="Escriba un nombre para el medio de pago"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  outlined
                  dense
                  v-model="form.tiempo"
                  item-text="descripcion"
                  item-value="codigo"
                  :items="tiempoPagoItems"
                  label="Tiempo de pago"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="payments"
                  v-model="form.tipo"
                  item-text="descripcion"
                  item-value="valor"
                  label="Tipo de pago"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="form.recordable"
                  label="Es recordable"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" text @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const slugify = require("slugify");
//import { mapState } from "vuex";

export default {
  name: "ModalMediosDePago",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
      description: "Data previamente guardada para un elemento en especifico.",
    },
    tiempoPagoItems: {
      type: Array,
      default: () => [],
    },
    payments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        descripcion: "",
        tiempo: "",
        tipo: "",
        codigo: "",
        recordable: true,
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    submit() {
      if (this.formRef.validate()) {
        this.$emit("submit", this.form);
      }
    },
    setForm() {
      this.form.codigo = this.formData.codigo;
      this.form.descripcion = this.formData.medio_descripcion;
      this.form.tiempo = this.formData.tiempo_pago?.codigo;
      this.form.tipo = this.formData.tipo_pago?.codigo;
      this.form.recordable = this.formData.recordable;
    },
    reset() {
      this.form.codigo = "";
      this.form.descripcion = "";
      this.form.tiempo = "";
      this.form.tipo = "";
      this.form.recordable = true;
      this.formRef.resetValidation();
    },
    slug() {
      this.form.codigo = slugify(this.form.codigo, {
        replacement: "_",
      }).toUpperCase();
    },
  },
};
</script>

<template>
  <v-container class="not-extended">
    <v-row no-gutters>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search"
          label="Buscar mantenedor..."
          hide-details
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-fade-transition
      group
      tag="div"
      class="row"
      multi-line
      v-if="itemsFiltered(items)?.length > 0"
    >
      <v-col
        v-for="item in itemsFiltered(items) ?? []"
        :key="item.ref"
        cols="6"
        sm="4"
        md="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card @click="redirigir(item.path)">
            <v-card-title primary-title class="justify-center">
              <v-icon x-large :color="'primary darken-4'">
                mdi-{{ item.icons.index }}
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80px">
              {{ item.text }}
            </v-card-text>
            <v-card-actions
              :class="[
                'overflow-hidden',
                hover ? 'info' : 'primary',
                $vuetify.breakpoint.mdAndUp ? 'pa-2' : 'pa-0',
              ]"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="white--text font-weight-bold subtitle-2"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="justify-end"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-icon class="mr-1" color="white"> mdi-open-in-new </v-icon>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-fade-transition>
  </v-container>
</template>
<script>
import { crudsPages } from "@/router/data/index.js";
export default {
  data() {
    return {
      page: "20",
      isActive: false,
      search: "",
    };
  },
  methods: {
    redirigir(path) {
      this.$router.push({ name: path });
    },
    itemsFiltered(items) {
      return items.filter((e) =>
        e.title?.toLowerCase()?.includes(this.search.toLowerCase())
      );
    },
  },
  created() {
    this.$store.commit("setPage", { page: this.page });
  },
  computed: {
    items() {
      return crudsPages.sort((a, b) => {
        const titleA = a.title.toUpperCase(); // Convertir a mayúsculas para ordenar sin distinción entre mayúsculas y minúsculas
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1; // 'a' va antes que 'b'
        }
        if (titleA > titleB) {
          return 1; // 'b' va antes que 'a'
        }
        return 0; // Son iguales
      });
    },
  },
};
</script>

import { baseKeys } from "@/store/resources/channelkeys";

export const userDataKeys = {
  name: "nombre_corto",
  fullName: "nombre_completo",
  photo: "foto_usuario",
  role: "perfil",
  token: "token",
};

export const userKeys = {
  id: "_id",
  agreement: "convenio",
  request_source: "origen_solicitud",
  name: "nombre",
  lastName1: "primer_apellido",
  lastName2: "segundo_apellido",
  email: "correo_electronico",
  phone: "celular",
  username: "usuario",
  modules: "modulos",
  roles: "roles",
  roles_permisos: {
    key: "roles_permisos",
    id_rol: "id",
    nombre: "nombre",
  },
  clientId: "client_id",
  clientSecret: "client_secret",
  channel: baseKeys,
  data: userDataKeys,
  roles_ids: "roles_ids",
};

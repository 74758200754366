<template>
  <v-dialog
    :value="value"
    width="800"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    scrollable
  >
    <v-card>
      <v-card-title primary-title>
        Data de la Configuración
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small fab text @click="$emit('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <json-viewer :expand-depth="8" :value="body" copyable boxed>
        </json-viewer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlantillaService from "@/services/manager/notifications/plantillas.service.js";
import ClientService from "@/services/manager/notifications/client.service.js";
import JsonViewer from "vue-json-viewer";

const plantillaService = new PlantillaService();
const clientService = new ClientService();

export default {
  name: "RequestBody",
  components: {
    JsonViewer,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },

    clients: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      body: {
        clientId: "",
        convenio: "",
        proceso: "",
        operacion: "",
        compania: "",
        producto: "",
        plan: "",
        data: {
          para: "correo_a_enviar@mesos.cl",
          remitente: "soporte@e.mesos.cl",
          nombreRemitente: "Mesos",
          cc: "correo_a_copiar@mesos.cl",
          cco: "correo_a_copiar_oculto@mesos.cl",
          tipo_envio: "transaccional",
          parametros: {
            parametroDePrueba: "Valor del parametro de pruebas",
          },
          adjuntos: [
            {
              nombreArchivo: "archivo.csv",
              archivo: "77u/QXJjaGl2byBkZSBQcnVlYmFzOzs7DQo=",
            },
          ],
        },
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal) {
        this.setup();
      }
    },
  },
  methods: {
    setup() {
      this.getClientId(this.item?.clientId || this.item?.client);
      this.getParametrosFromPlantilla(this.item.plantilla);
      this.body.convenio = this.item?.convenio;
      this.body.proceso = this.item?.proceso;
      this.body.operacion = this.item?.operacion;
      this.body.tipoEnvio = this.item?.tipoEnvio;
      this.body.convenio = this.item?.convenio;
    },

    // Obtiene el ClientId a partir del objectId de la tabla client
    async getClientId(client) {
      await clientService.clientID(client).then((response) => {
        console.log(response);
        this.body.clientId = response.data.result;
      });
    },

    // Se ejecuta al cerrar el modal, para limpiar campos
    /* closeModal() {
      this.open = !this.open
    }, */

    // Lee la plantilla y el asunto en busca de parametros par alistarlos en en body.data.parametros
    async getParametrosFromPlantilla(plantilla) {
      const regexp = /(?<={{)\w+(?=}})/g;
      let parametros = {};

      await plantillaService.getIdPlantillas(plantilla).then((response) => {
        const baseparams =
          response.data.data.data.cuerpo + response.data.data.data.asunto;
        const regparam = baseparams.match(regexp);
        if (regparam !== null) {
          const matches = [...baseparams.match(regexp)];
          let textparam = "{";
          matches.map((param) => {
            textparam += `"${param}":"",`;

            return param;
          });
          textparam = `${textparam}"":""}`;
          parametros = JSON.parse(textparam);
          delete parametros[""];
          this.body.data.parametros = parametros;
        } else {
          this.body.data.parametros = {};
        }
      });
    },
  },
};
</script>

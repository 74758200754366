<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>
      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
    </v-card-title>

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import { mapState, mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  mixins: [PageMixin],
  components: { TableMantenedor },
  data() {
    return {
      headers: [{ text: "Nombre", value: "nombre" }],
    };
  },
  computed: {
    ...mapState("Permisos", ["permisos"]),
    items() {
      return this.permisos;
    },
  },
  methods: {
    ...mapActions("Permisos", ["getPermisos"]),
    setup() {
      this.loading = true;
      this.getPermisos().finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

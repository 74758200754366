<template>
  <v-card>
    <v-tabs v-model="tab" grow>
      <v-tab>Roles</v-tab>
      <v-tab>Módulos</v-tab>
      <v-tab>Permisos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card-text>
          <Roles :modulos="modulos" :permisos="permisos" />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <Modulos />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <Permisos />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

import Roles from "./Roles.vue";
import Modulos from "./Modulos.vue";
import Permisos from "./Permisos.vue";

export default {
  components: {
    Roles,
    Modulos,
    Permisos,
  },
  data() {
    return {
      tab: null,
      modulos: [],
      permisos: [],
    };
  },
  methods: {
    ...mapActions("Modulos", ["getModulos"]),
    ...mapActions("Permisos", ["getPermisos"]),
  },
  watch: {
    tab(index) {
      if (index === 0) {
        this.getModulos().then((modulos) => {
          this.modulos = modulos;
        });

        this.getPermisos().then((permisos) => {
          this.permisos = permisos;
        });
      }
    },
  },
};
</script>

<template>
  <div class="text-center">
    <v-dialog
      max-width="800"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario de Roles
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre"
                  :rules="[(v) => !!v || 'El campo Nombre es requerido']"
                  v-model="form.nombre"
                ></v-text-field>
                <v-text-field
                  label="Slug"
                  :rules="[(v) => !!v || 'El campo Slug es requerido']"
                  v-model="form.slug"
                  @blur="slug"
                ></v-text-field>
                <v-switch label="Activo" v-model="form.esActivo"></v-switch>

                <h3 style="text-align: center">
                  Selección de Módulos y Permisos
                </h3>
                <v-divider style="margin-top: 10px"></v-divider>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">MÓDULO</th>
                        <th v-for="permiso in permisos" :key="permiso">
                          {{ permiso }}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(modulo, index) in form.modulos"
                        :key="modulo._id"
                      >
                        <td>
                          <v-select
                            v-model="form.modulos[index].id_modulo"
                            :items="modulos_filtrados"
                            item-text="nombre"
                            item-value="_id"
                            label="Seleccione módulo"
                            solo
                          ></v-select>
                        </td>
                        <td v-for="permiso in permisos" :key="permiso">
                          <v-checkbox
                            v-model="form.modulos[index].permisos"
                            :value="permiso"
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-btn
                            color="error"
                            fab
                            text
                            x-small
                            @click="deleteModule(index)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                  "
                >
                  <v-btn color="primary" @click="agregarModulo"
                    >Agregar módulo</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :loading="loading" @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const slugify = require("slugify");

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modulos: {
      type: Array,
    },
    permisos: {
      type: Array,
    },
  },
  data() {
    return {
      tab: null,
      form: {
        nombre: "",
        slug: "",
        esActivo: false,
        modulos: [],
      },
    };
  },
  computed: {
    modulos_filtrados() {
      return this.modulos.filter((modulo) => modulo.es_activo);
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },
    setForm() {
      this.form.nombre = this.formData?.nombre ?? "";
      this.form.slug = this.formData?.slug ?? "";
      this.form.esActivo = this.formData?.es_activo === "Si" ? true : false;
      this.form.modulos = this.formData?.modulos ?? [];
    },
    reset() {
      this.form.nombre = "";
      this.form.slug = "";
      this.form.esActivo = false;
      this.form.modulos = [];
      this.$refs.form.resetValidation();
    },
    agregarModulo() {
      this.form.modulos.push({
        id_modulo: "",
        permisos: [],
      });
    },
    deleteModule(index) {
      this.form.modulos = this.form.modulos.filter((modulo, i) => i !== index);
    },
    slug() {
      this.form.slug = slugify(this.form.slug, { lower: true });
    },
  },
};
</script>

<style scoped>
:deep(.v-data-table .v-text-field__details) {
  display: none;
}
:deep(.v-data-table .v-input__slot) {
  margin: 0;
}
</style>

<template>
  <div>
    <v-card>
      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="itemsProcesados"
        class="elevation-1"
        :loading-text="table.loadingText"
        no-data-text="Sin información"
        no-results-text="Sin información"
        :page="pagination.page"
        :footer-props="table.footerProps"
        :items-per-page="pagination.limit"
        :loading="loadingTable"
        @pagination="
          setPaginationFromTableConcesionarios($event), (firstTime = false)
        "
      >
        <v-divider inset></v-divider>
        <template v-slot:top>
          <div class="d-flex pa-3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  outlined
                  small
                  fab
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-text-field
                    v-model="filters.rutVendedor"
                    label="Rut Vendedor"
                    dense
                    @input="handlerFormatRutFilterVendedor"
                  ></v-text-field>
                  <v-text-field
                    v-model="filters.rutConcesionario"
                    label="Rut Concesionario"
                    dense
                    @input="handlerFormatRutFilterConcesionario"
                  ></v-text-field>
                  <v-text-field
                    v-model="filters.concesionario"
                    label="Concesionario"
                    dense
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="handlerResetFilters()" color="error" small>
                    Borrar
                  </v-btn>
                  <v-btn
                    @click="(cleanFilters = true), handlerSetFilter()"
                    color="primary"
                    small
                  >
                    Filtrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <div v-if="cleanFilters" class="ml-5">
              <v-btn
                outlined
                color="primary"
                class="white--text"
                @click="handlerResetFilters()"
              >
                Limpiar filtros
              </v-btn>
            </div>

            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="white--text"
              :disabled="editModeNew"
              @click="nuevo"
            >
              Nuevo
            </v-btn>
          </div>
        </template>

        <template v-slot:item.nombreVendedor="{ item }">
          <v-row no-gutters v-if="item.id === editedItem.id">
            <v-text-field
              v-model="editedItem.nombreVendedor"
              label="Nombre"
              class="mt-3"
              dense
              outlined
              autofocus
              :disabled="editModeNewVendedor"
              :loading="loadingVendedor"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.primerApellidoVendedor"
              label="Primer Apellido"
              dense
              outlined
              autofocus
              :disabled="editModeNewVendedor"
              :loading="loadingVendedor"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.segundoApellidoVendedor"
              label="Segundo Apellido"
              dense
              outlined
              autofocus
              :disabled="editModeNewVendedor"
              :loading="loadingVendedor"
            ></v-text-field>
          </v-row>
          <span v-else>{{
            item.nombreVendedor +
            " " +
            (item?.primerApellidoVendedor ?? "") +
            " " +
            (item?.segundoApellidoVendedor ?? "")
          }}</span>
        </template>

        <template v-slot:item.rutVendedor="{ item }">
          <v-row no-gutters v-if="item.id === editedItem.id">
            <v-text-field
              v-model="editedItem.rutVendedor"
              v-mask="rutMask"
              label="RUT"
              :rules="rules.rutRules"
              dense
              outlined
              autofocus
              @change="GetDataVendedor"
            ></v-text-field>
          </v-row>
          <span v-else>{{ item.rutVendedor }}</span>
        </template>

        <template v-slot:item.correoVendedor="{ item }">
          <v-row no-gutters v-if="item.id === editedItem.id">
            <v-text-field
              class="mb-3"
              v-model="editedItem.correoVendedor"
              label="Correo"
              :rules="rules.emailRules"
              dense
              outlined
              autofocus
              :disabled="editModeNewVendedor"
              :loading="loadingVendedor"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.telefonoVendedor"
              label="Teléfono (Opcional)"
              v-mask="phoneMask"
              hide-details
              dense
              outlined
              autofocus
              :disabled="editModeNewVendedor"
              :loading="loadingVendedor"
            ></v-text-field>
          </v-row>
          <span v-else>{{ item.correoVendedor }}</span>
        </template>

        <template v-slot:item.nombreConcesionario="{ item }">
          <v-row no-gutters v-if="item.id === editedItem.id">
            <v-col cols="12" class="pa-0">
              <v-select
                :items="concesionarios"
                label="Concesionario"
                item-text="nombre"
                item-value="_id"
                v-model="editedItem.idConcesionario"
                hide-details
                dense
                outlined
                @change="concesionarioSelect"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 text-end">
              <v-btn
                color="primary"
                fab
                text
                small
                class="mx-1"
                @click="(editMode = false), (tipo = 1), openModal()"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                text
                small
                :disabled="disabledConcesionario"
                @click="(editMode = true), (tipo = 1), openModal(item)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                color="red"
                fab
                text
                small
                @click="deleteItemConcesionario(item.idConcesionario)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <span v-else>{{ item.nombreConcesionario }}</span>
        </template>
        <template v-slot:item.nombreSucursal="{ item }">
          <v-row no-gutters v-if="item.id === editedItem.id">
            <v-col cols="12" class="pa-0">
              <v-select
                :items="sucursales"
                label="Sucursal"
                item-text="nombre"
                item-value="_id"
                v-model="editedItem.idSucursal"
                hide-details
                dense
                outlined
                @change="sucursalSelect"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 text-end">
              <v-btn
                color="primary"
                fab
                text
                small
                class="mx-1"
                @click="(editMode = false), (tipo = 2), openModal()"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                text
                small
                :disabled="disabledSucursal || !editedItem.idSucursal"
                @click="(editMode = true), (tipo = 2), openModal(item)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                color="red"
                fab
                text
                small
                @click="deleteItemSucursal(item.idSucursal)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <span v-else>{{ item.nombreSucursal }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="item.id === editedItem.id">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  x-small
                  text
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="close"
                >
                  <v-icon small> mdi-window-close </v-icon>
                </v-btn>
              </template>
              <span>Cancelar edicion</span>
            </v-tooltip>
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  x-small
                  text
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="addNew"
                  :loading="loadingSave"
                >
                  <v-icon small> mdi-content-save </v-icon>
                </v-btn>
              </template>
              <span>Guardar {{ item.nombreVendedor }}</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  x-small
                  text
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="(editModeVendedor = true), editItem(item)"
                >
                  <v-icon small> mdi-pencil-outline </v-icon>
                </v-btn>
              </template>
              <span>Editar {{ item.nombreVendedor }}</span>
            </v-tooltip>
            <v-tooltip left color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  x-small
                  text
                  fab
                  v-on="on"
                  v-bind="attrs"
                  :loading="loadingDelete"
                  @click="deleteItem(item)"
                >
                  <v-icon small> mdi-delete-outline </v-icon>
                </v-btn>
              </template>
              <span>Eliminar {{ item.nombreVendedor }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <!-- /TABLA DE DATOS -->
    </v-card>

    <!-- MODAL FORMULARIO CONCESIONARIO -->
    <v-dialog
      v-model="dialogConcesionario"
      scrollable
      persistent
      max-width="500px"
    >
      <v-form ref="formConcesionario">
        <v-card>
          <v-card-title primary-title> Formulario Concesionario </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="modalForm.rutConcesionario"
              label="Rut"
              dense
              outlined
              persistent-hint
              :rules="rulesConcesionario.rutConcesionario"
              @input="handlerFormatRut"
            ></v-text-field>
            <v-text-field
              v-model="modalForm.razonSocial"
              label="Razón Social"
              dense
              outlined
              persistent-hint
              :rules="rulesConcesionario.razonSocialConcesionario"
            ></v-text-field>
            <v-text-field
              v-model="modalForm.nombreConcesionario"
              label="Nombre"
              dense
              outlined
              persistent-hint
              :rules="rulesConcesionario.nombreConcesionario"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingForm"
              @click="resetForm()"
              >Cerrar</v-btn
            >
            <v-btn
              color="primary"
              @click="saveConcesionario()"
              :loading="loadingForm"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- MODAL FORMULARIO SUCURSAL -->
    <v-dialog v-model="dialogSucursal" scrollable persistent max-width="500px">
      <v-form ref="formSucursal">
        <v-card>
          <v-card-title primary-title> Formulario Sucursal </v-card-title>
          <v-card-text>
            <v-select
              v-model="modalForm.nombreConcesionarioSelect"
              :items="concesionarios"
              item-text="nombre"
              item-value="_id"
              label="Concesionario"
              dense
              outlined
              disabled
              persistent-hint
              :rules="rulesSucursal.razonSocialConcesionario"
            ></v-select>
            <v-text-field
              v-model="modalForm.nombreSucursal"
              label="Nombre Sucursal"
              dense
              outlined
              persistent-hint
              :rules="rulesSucursal.nombreSucursal"
            ></v-text-field>
            <v-text-field
              v-model="modalForm.calleSucursal"
              label="Dirección"
              dense
              outlined
              persistent-hint
              :rules="rulesSucursal.direccionSucursal"
            ></v-text-field>
            <v-select
              v-model="modalForm.comunaSucursal"
              :items="comunas"
              item-value="value"
              item-text="label"
              label="Comuna"
              dense
              outlined
              persistent-hint
              :rules="rulesSucursal.comunaSucursal"
            ></v-select>
            <v-text-field
              v-model="modalForm.numeroSucursal"
              label="Nro"
              dense
              outlined
              persistent-hint
              :rules="rulesSucursal.numeroSucursal"
            ></v-text-field>
            <v-text-field
              v-model="modalForm.pisoSucursal"
              label="Piso"
              dense
              outlined
              persistent-hint
              :rules="rulesSucursal.pisoSucursal"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingForm"
              @click="resetForm()"
              >Cerrar</v-btn
            >
            <v-btn
              color="primary"
              @click="saveSucursal()"
              :loading="loadingForm"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <confirm-modal ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";
import { rutMask, phoneMask } from "@/store/resources/masks.js";
import { validarEmail } from "@/store/resources/validate.js";
import { validate, clean, format } from "rut.js";

export default {
  name: "CrudConcesionariosExternos",
  mixins: [PaginationMixin],
  components: {
    ConfirmModal: () => import("@/components/Modal/ConfirmModal.vue"),
  },
  data() {
    return {
      page: "211",
      search: "",
      loadingForm: false,
      loadingSave: false,
      loadingDelete: false,
      loadingTable: false,
      loadingVendedor: false,
      editMode: false,
      editModeVendedor: false,
      editModeNew: false,
      editModeNewVendedor: false,
      dialogSucursal: false,
      dialogConcesionario: false,
      errorSeller: false,
      menu: false,
      firstTime: true,
      cleanFilters: false,
      headers: [
        {
          text: "Rut",
          align: "start",
          filterable: true,
          sortable: true,
          value: "rutVendedor",
          width: "15%",
        },
        {
          text: "Nombre",
          align: "start",
          filterable: true,
          sortable: true,
          value: "nombreVendedor",
          width: "20%",
        },
        {
          text: "Email",
          align: "start",
          filterable: true,
          sortable: true,
          value: "correoVendedor",
        },
        {
          text: "Concesionario",
          align: "start",
          filterable: true,
          sortable: true,
          value: "nombreConcesionario",
        },
        {
          text: "Sucursal",
          align: "start",
          filterable: true,
          sortable: true,
          value: "nombreSucursal",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          align: "right",
        },
      ],
      form: {
        id: "",
        idVendedor: "",
        nombreVendedor: "",
        primerApellidoVendedor: "",
        segundoApellidoVendedor: "",
        rutVendedor: "",
        correoVendedor: "",
        telefonoVendedor: "",
        nombreConcesionario: "",
        idConcesionario: "",
        nombreSucursal: "",
        idSucursal: "",
      },
      modalForm: {
        idConcesionario: "",
        rutConcesionario: "",
        razonSocial: "",
        nombreConcesionario: "",
        nombreConcesionarioSelect: "",
        idSucursal: "",
        nombreSucursal: "",
        comunaSucursal: "",
        calleSucursal: "",
        numeroSucursal: "",
        pisoSucursal: "",
      },
      editedIndex: -1,
      editedItem: {
        id: "",
        idVendedor: "",
        nombreVendedor: "",
        primerApellidoVendedor: "",
        segundoApellidoVendedor: "",
        rutVendedor: "",
        correoVendedor: "",
        telefonoVendedor: "",
        nombreConcesionario: "",
        idConcesionario: "",
        nombreSucursal: "",
        idSucursal: "",
      },
      defaultItem: {
        id: "",
        idVendedor: "",
        nombreVendedor: "",
        primerApellidoVendedor: "",
        segundoApellidoVendedor: "",
        rutVendedor: "",
        correoVendedor: "",
        telefonoVendedor: "",
        nombreConcesionario: "",
        idConcesionario: "",
        nombreSucursal: "",
        idSucursal: "",
      },
      filters: {
        rutVendedor: "",
        rutConcesionario: "",
        concesionario: "",
        sucursal: "",
      },
      itemsProcesados: [],
      sucursales: [],
      concesionarios: [],
      tipo: 0,
      esNuevo: false,
      disabledConcesionario: true,
      disabledSucursal: true,
      concesionarioSelected: "",
      sucursalSelected: "",
      oldSucursal: "",
      comunas: [],
      rules: {
        rutRules: [
          (v) => !!v || "Debe escribir el RUT de la persona",
          (v) => validate(v) || "No se ha logrado reconocer el RUT",
        ],
        emailRules: [
          (v) => !!v || "Debe escribir un correo electrónico",
          (v) => validarEmail(v) || "El correo debe ser válido",
        ],
      },
      rulesConcesionario: {
        rutConcesionario: [
          (v) => !!v || "RUT es obligatorio",
          (v) => validate(v) || "RUT no valido",
        ],
        razonSocialConcesionario: [(v) => !!v || "Razón Social es obligatorio"],
        nombreConcesionario: [(v) => !!v || "Nombre es obligatorio"],
      },
      rulesSucursal: {
        razonSocialConcesionario: [(v) => !!v || "Razón Social es obligatorio"],
        nombreSucursal: [(v) => !!v || "Nombre es obligatorio"],
        direccionSucursal: [(v) => !!v || "Dirección es obligatorio"],
        comunaSucursal: [(v) => !!v || "Comuna es obligatorio"],
        numeroSucursal: [(v) => !!v || "Numero es obligatorio"],
        pisoSucursal: [(v) => !!v || "Piso es obligatorio"],
      },
    };
  },
  computed: {
    ...mapGetters("Concesionarios", ["Items"]),
    rutMask: () => rutMask,
    phoneMask: () => phoneMask,
  },
  methods: {
    ...mapActions("Concesionarios", [
      "GetConcesionariosTabla",
      "GetConcesionarios",
      "PostConcesionario",
      "PutConcesionario",
      "DeleteConcesionario",
      "GetLocations",
    ]),
    ...mapActions("Sucursales", [
      "GetSucursales",
      "PostSucursal",
      "PutSucursal",
      "DeleteSucursal",
    ]),
    ...mapActions("Vendedores", [
      "GetVendedores",
      "GetVendedor",
      "PostVendedor",
      "PutVendedor",
      "DeleteVendedor",
    ]),

    setup() {
      this.pagination.concesionario = this.filters.concesionario;
      this.pagination.rutConcesionario = this.filters.rutConcesionario;
      this.pagination.rutVendedor = this.filters.rutVendedor;
      this.pagination.sucursal = this.filters.sucursal;
      this.loadingTable = true;
      this.GetConcesionariosTabla(this.pagination, this.filters)
        .then((response) => {
          this.itemsProcesados = response?.data.flatMap((c) => {
            return c?.sucursales.flatMap((s) => {
              return s?.vendedores.map((v) => {
                return {
                  idConcesionario: c?._id,
                  nombreConcesionario: c?.nombre,
                  idSucursal: s?._id,
                  nombreSucursal: s?.nombre,
                  idVendedor: v?._id,
                  nombreVendedor: v?.nombre,
                  primerApellidoVendedor: v?.primer_apellido,
                  segundoApellidoVendedor: v?.segundo_apellido,
                  rutVendedor: format(v?.numero_documento),
                  correoVendedor: v?.email,
                  telefonoVendedor: v?.telefono,
                  id: c?._id + s?._id + v?._id,
                };
              });
            });
          });
          const pagination = response.pagination;
          this.setPaginationFromResponse(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error.message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
      this.GetLocations().then((response) => (this.comunas = response));
      this.sucursalesGet();
      this.concesionariosGet();
    },

    sucursalesGet() {
      const pagination = {
        page: 1,
        limit: 0,
        search: "",
        concesionario:
          this.concesionarioSelected == null || this.concesionarioSelected == ""
            ? this.editedItem.idConcesionario
            : this.concesionarioSelected,
      };
      this.GetSucursales(pagination)
        .then((response) => {
          this.sucursales = response?.data?.sort((a, b) =>
            a.nombre?.localeCompare(b?.nombre)
          );
          const pagination = response.pagination;
          this.setPaginationFromResponse(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error.message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },

    concesionariosGet() {
      const pagination = {
        page: 1,
        limit: 0,
        search: "",
      };
      this.GetConcesionarios(pagination)
        .then((response) => {
          this.concesionarios = response?.data?.sort((a, b) =>
            a.razon_social?.localeCompare(b?.razon_social)
          );
          const pagination = response.pagination;
          this.setPaginationFromResponse(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error.message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },

    saveConcesionario() {
      if (this.$refs.formConcesionario.validate()) {
        this.loadingForm = true;
        if (this.tipo == 1) {
          const form = {
            concesionario_descripcion: this.modalForm.razonSocial.replace(
              " ",
              "_"
            ),
            eliminado: false,
            codigo: this.modalForm.razonSocial.replace(" ", "_").toUpperCase(),
            nombre: this.modalForm.nombreConcesionario,
            numero_documento: this.modalForm.rutConcesionario
              .split(".")
              .join("")
              .split("-")
              .join(""),
            razon_social: this.modalForm.razonSocial,
          };
          if (this.editMode) {
            form.id = this.modalForm.idConcesionario;
            this.PutConcesionario(form)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "success",
                });
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error.message,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                this.loadingForm = false;
                this.resetForm();
                this.concesionariosGet();
              });
          } else {
            this.PostConcesionario(form)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "success",
                });
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error.message,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                this.loadingForm = false;
                this.resetForm();
                this.concesionariosGet();
              });
          }
        }
      }
    },

    GetDataVendedor(item) {
      this.loadingVendedor = true;
      const form = {
        numero_documento: item,
      };
      this.GetVendedor(form)
        .then((response) => {
          const vendedor = response?.data?.find(
            (v) =>
              v.numero_documento == item.split(".").join("").split("-").join("")
          );
          this.editedItem.nombreVendedor = vendedor?.nombre;
          this.editedItem.primerApellidoVendedor = vendedor?.primer_apellido;
          this.editedItem.segundoApellidoVendedor = vendedor?.segundo_apellido;
          this.editedItem.correoVendedor = vendedor?.email;
          this.editedItem.telefonoVendedor = vendedor?.telefono;
          if (vendedor) {
            this.editModeNewVendedor = true;
          } else {
            this.editModeNewVendedor = false;
          }
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error.message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingVendedor = false;
        });
    },

    saveSucursal() {
      if (this.$refs.formSucursal.validate()) {
        this.loadingForm = true;
        if (this.tipo == 2) {
          let METHOD = this.PostSucursal;
          const form = {
            codigo: this.modalForm.nombreSucursal.replace(" ", "_"),
            eliminado: false,
            sucursal_descripcion: this.modalForm.nombreSucursal
              .replace(" ", "_")
              .toUpperCase(),
            // concesionario: this.modalForm.nombreConcesionarioSelect,
            //concesionario_descripcion: this.modalForm.nombreConcesionarioSelect,
            nombre: this.modalForm.nombreSucursal,
            comuna: this.modalForm.comunaSucursal,
            calle: this.modalForm.calleSucursal,
            numero: this.modalForm.numeroSucursal,
            piso: this.modalForm.pisoSucursal,
          };
          if (this.editMode) {
            METHOD = this.PutSucursal;
            form.id = this.modalForm.idSucursal;
          } else {
            form.concesionario = this.modalForm.nombreConcesionarioSelect;
          }
          METHOD(form)
            .then((response) => {
              this.$store.commit("setSnackbar", {
                active: true,
                text: response.message,
                top: true,
                right: true,
                color: "success",
              });
            })
            .catch((error) => {
              this.$store.commit("setSnackbar", {
                active: true,
                text: error.message,
                top: true,
                right: true,
                color: "error",
              });
            })
            .finally(() => {
              this.loadingForm = false;
              this.resetForm();
              this.sucursalesGet();
            });
        }
      }
    },

    addNew() {
      this.loadingSave = true;
      const nombreCompleto =
        this.editedItem?.nombreVendedor +
        " " +
        (this.editedItem?.primerApellidoVendedor != "" &&
        this.editedItem?.primerApellidoVendedor != null
          ? this.editedItem?.primerApellidoVendedor
          : "") +
        " " +
        (this.editedItem?.segundoApellidoVendedor != "" &&
        this.editedItem?.segundoApellidoVendedor != null
          ? this.editedItem?.segundoApellidoVendedor
          : "");
      const form = {
        codigo: nombreCompleto?.replace(" ", "_").toUpperCase(),
        vendedor_descripcion: nombreCompleto?.replace(" ", "_"),
        sucursal:
          this.form?.idSucursal != ""
            ? this.form?.idSucursal
            : this.editedItem?.idSucursal,
        /*sucursal_descripcion:
          this.form?.nombreSucursal != ""
            ? this.form?.nombreSucursal
            : this.editedItem?.nombreSucursal,*/
        numero_documento: this.editedItem?.rutVendedor,
        nombre: this.editedItem?.nombreVendedor,
        primer_apellido: this.editedItem?.primerApellidoVendedor,
        segundo_apellido: this.editedItem?.segundoApellidoVendedor,
        email: this.editedItem?.correoVendedor,
        telefono: this.editedItem?.telefonoVendedor,
      };
      if (this.editModeVendedor) {
        if (this.form.idSucursal != "") {
          form.ex_sucursal = this.oldSucursal;
        }
        form.id = this.editedItem.idVendedor;
        this.PutVendedor(form)
          .then((response) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: response.message,
              top: true,
              right: true,
              color: "success",
            });
          })
          .catch((error) => {
            this.errorSeller = true;
            this.$store.commit("setSnackbar", {
              active: true,
              text: error.message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            if (!this.errorSeller) {
              this.close();
              this.setup();
              this.errorSeller = false;
            }
            this.loadingSave = false;
            this.editModeNew = false;
          });
      } else {
        form.concesionario = this.editedItem?.idConcesionario;
        this.PostVendedor(form)
          .then((response) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: response.message,
              top: true,
              right: true,
              color: "success",
            });
            this.setup();
          })
          .catch((error) => {
            this.errorSeller = true;
            this.$store.commit("setSnackbar", {
              active: true,
              text: error.message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            if (!this.errorSeller) {
              this.close();
              this.setup();
              this.errorSeller = false;
              this.handlerResetFormVendedor();
            }
            this.loadingSave = false;
            this.editModeNew = false;
          });
      }
    },

    nuevo() {
      this.editModeVendedor = false;
      this.esNuevo = true;
      const addObj = Object.assign({}, this.form);
      addObj.id = this.itemsProcesados?.length + 1;
      this.itemsProcesados.unshift(addObj);
      this.editItem(addObj);
    },

    editItem(item) {
      this.editModeNewVendedor = false;
      this.oldSucursal = item.idSucursal;
      this.editModeNew = true;
      this.concesionarioSelect(item.idConcesionario);
      this.disabledConcesionario =
        item.idConcesionario === null || item.idConcesionario === "";
      this.disabledSucursal =
        item.idSucursal === null || item.idSucursal === "";
      this.editedIndex = this.itemsProcesados.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    close() {
      if (this.esNuevo) {
        this.esNuevo = false;
        this.setup();
      }
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.handlerResetFormVendedor();
        this.editedIndex = -1;
        this.editModeNew = false;
      }, 300);
    },

    concesionarioSelect(item) {
      const editConcesionario = this.concesionarios.find((c) => {
        return c._id == item;
      });
      this.form = {
        idConcesionario: item,
        nombreConcesionario: editConcesionario?.concesionario_descripcion,
      };
      this.concesionarioSelected = item;
      this.disabledConcesionario = false;
      this.editedItem.idSucursal = "";
      this.sucursalesGet();
    },

    sucursalSelect(item) {
      const editSucursal = this.sucursales.find((v) => {
        return v._id == item;
      });
      this.form = {
        idSucursal: item,
        nombreSucursal: editSucursal.sucursal_descripcion,
      };
      this.sucursalSelected = item;
      this.disabledSucursal = false;
    },

    openModal(item) {
      if (this.tipo == 1) {
        this.dialogConcesionario = true;
        if (this.editMode) {
          const editConcesionario = this.concesionarios.find((c) => {
            return (
              c._id ==
              (this.concesionarioSelected != null &&
              this.concesionarioSelected != ""
                ? this.concesionarioSelected
                : item.idConcesionario)
            );
          });
          this.modalForm = {
            idConcesionario: editConcesionario?._id,
            nombreConcesionario: editConcesionario?.nombre,
            rutConcesionario: format(editConcesionario?.numero_documento),
            razonSocial: editConcesionario?.razon_social,
          };
        }
      } else {
        this.modalForm.nombreConcesionarioSelect =
          this.editedItem.idConcesionario;
        this.dialogSucursal = true;
        if (this.editMode) {
          const editSucursal = this.sucursales.find((v) => {
            return (
              v._id ==
              (this.sucursalSelected != null && this.sucursalSelected != ""
                ? this.sucursalSelected
                : item.idSucursal)
            );
          });
          this.modalForm = {
            ...this.modalForm,
            ...{
              idSucursal: editSucursal?._id,
              nombreSucursal: editSucursal?.nombre,
              comunaSucursal: editSucursal?.comuna ?? "",
              calleSucursal: editSucursal?.calle,
              numeroSucursal: editSucursal?.numero,
              pisoSucursal: editSucursal?.piso,
            },
          };
        }
      }
    },

    handlerCleanRut() {
      this.modalForm.rutConcesionario = clean(this.modalForm.rutConcesionario);
    },

    handlerFormatRut(item) {
      this.modalForm.rutConcesionario = format(item);
    },
    handlerFormatRutFilterConcesionario(item) {
      this.filters.rutConcesionario = format(item);
    },
    handlerFormatRutFilterVendedor(item) {
      this.filters.rutVendedor = format(item);
    },

    deleteItem(item) {
      const form = {
        sucursal: item.idSucursal,
        id: item.idVendedor,
      };
      this.$refs.confirmDialog
        .show({
          title: "Eliminar un vendedor",
          description: "¿Esta seguro de querer eliminar este vendedor?",
        })
        .then((result) => {
          this.loadingTable = true;
          if (result) {
            this.DeleteVendedor(form)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "info",
                });
                this.setup();
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error.message,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                this.setup();
              });
            this.loadingTable = false;
          }
        });
    },

    deleteItemConcesionario(item) {
      const id =
        this.concesionarioSelected != null ? this.concesionarioSelected : item;
      this.$refs.confirmDialog
        .show({
          title: "Eliminar un concesionario",
          description: "¿Esta seguro de querer eliminar este concesionario?",
        })
        .then((result) => {
          if (result) {
            this.DeleteConcesionario(id)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "info",
                });
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error.message,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                this.concesionariosGet();
              });
          }
        });
    },

    deleteItemSucursal(item) {
      const id =
        this.sucursalSelected != null && this.sucursalSelected != ""
          ? this.sucursalSelected
          : item;
      this.$refs.confirmDialog
        .show({
          title: "Eliminar una sucursal",
          description: "¿Esta seguro de querer eliminar esta sucursal?",
        })
        .then((result) => {
          if (result) {
            this.DeleteSucursal(id)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "info",
                });
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error.message,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                this.sucursalesGet();
              });
          }
        });
    },

    resetForm() {
      if (this.tipo == 1) {
        this.modalForm = {
          idConcesionario: "",
          rutConcesionario: "",
          razonSocial: "",
          nombreConcesionario: "",
        };
        this.$refs.formConcesionario.resetValidation();
        this.dialogConcesionario = false;
      } else {
        this.modalForm = {
          nombreConcesionarioSelect: "",
          idSucursal: "",
          nombreSucursal: "",
          comunaSucursal: "",
          calleSucursal: "",
          numeroSucursal: "",
          pisoSucursal: "",
        };
        this.$refs.formSucursal.resetValidation();
        this.dialogSucursal = false;
      }
    },

    handlerSetFilter() {
      if (this.cleanFilters) {
        this.editModeNew = true;
      }
      this.pagination.page = 1;
      this.setup();
    },

    handlerResetFilters() {
      this.filters = {
        rutConcesionario: "",
        concesionario: "",
        rutVendedor: "",
        sucursal: "",
      };
      this.editModeNew = false;
      this.cleanFilters = false;
      this.handlerSetFilter();
    },

    handlerResetFormVendedor() {
      this.editedItem = {
        id: "",
        idVendedor: "",
        nombreVendedor: "",
        primerApellidoVendedor: "",
        segundoApellidoVendedor: "",
        rutVendedor: "",
        correoVendedor: "",
        telefonoVendedor: "",
        nombreConcesionario: "",
        idConcesionario: "",
        nombreSucursal: "",
        idSucursal: "",
      };
    },

    setPaginationFromTableConcesionarios(pagination = null) {
      if (pagination) {
        this.pagination.limit = pagination.itemsPerPage ?? 10;
        this.pagination.page = pagination.page ?? 1;
        if (this.firstTime) {
          this.setup();
        }
      }
    },
  },
  created() {
    this.$store.commit("setPage", { page: this.page });
  },
};
</script>
<style scoped>
#container {
  display: flex;
  justify-content: space-between; /* Can be changed in the live sample */
}
</style>

import { mapActions } from "vuex";

export const Register = {
  data: () => ({
    loadingLists: {
      canal: false,
      sucursal: false,
    },
    form: {
      canal: "",
      sucursal: "",
    },
    sucursales: [],
    canales: [],
  }),
  created() {
    this.getCanal();
  },
  methods: {
    ...mapActions("Homologaciones", ["GetSucursal", "GetConvenio"]),

    getCanal() {
      this.loadingLists.canal = true;
      this.GetConvenio()
        .then((response) => {
          this.canales = response;
        })
        .finally(() => {
          this.loadingLists.canal = false;
        });
    },

    getSucursal(canalId = null) {
      this.sucursales = [];
      this.form.sucursal = "";
      if (canalId) {
        this.loadingLists.sucursal = true;
        this.GetSucursal(canalId)
          .then((response) => {
            this.sucursales = response;
          })
          .finally(() => {
            this.loadingLists.sucursal = false;
          });
      }
    },
  },
};

export default Register;

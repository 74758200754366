<template>
  <div>
    <v-card>
      <div class="d-flex align-center pa-3">
        <v-text-field
          v-model="search"
          dense
          label="Buscar..."
          hide-details
          append-icon="mdi-magnify"
          class="align-self-end"
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-btn
          color="primary"
          class="mr-3"
          @click="getItems()"
          :loading="loading"
          outlined
          fab
          small
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" :loading="loadingClient" @click="dialog = true">
          Nuevo
        </v-btn>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'mostar',
          'items-per-page-options': [5, 10, 25, 50],
        }"
      >
        <template #item.activo="{ item }">
          <v-switch
            :input-value="item.activo"
            :true-value="true"
            :false-value="false"
            dense
            inset
            color="primary"
            :hint="item.activo ? 'Activo' : 'Inactivo'"
            persistent-hint
            :loading="item.loading"
            @change="activateItem($event, item)"
          ></v-switch>
        </template>

        <template #item.client="{ item }">
          <v-chip>{{ findClient(item) }}</v-chip>
        </template>

        <template #item.createdAt="{ item }">
          {{ item.createdAt | formatoFecha }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            x-small
            fab
            text
            :disabled="loadingClient"
            @click="handlerSelectItem(item)"
          >
            <v-icon small> mdi-pencil-outline </v-icon>
          </v-btn>

          <v-btn color="error" text fab x-small @click="remove(item)">
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      @click:outside="handlerCloseDialog()"
      @keydown.esc="handlerCloseDialog()"
    >
      <v-card>
        <v-card-title primary-title>
          Formulario de Parametros <v-spacer></v-spacer>
          <v-btn x-small fab text color="primary" @click="handlerCloseDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="paramForm">
            <v-text-field
              v-model="form.dominio"
              label="Dominio"
              :rules="[(val) => !!val || 'El campo Dominio es obligatorio.']"
            ></v-text-field>

            <v-text-field
              v-model="form.descripcion"
              label="Descripción"
              :rules="[
                (val) =>
                  !!val || 'Debe escribir una descripción del parametro.',
              ]"
            ></v-text-field>

            <v-text-field
              v-model="form.valor"
              label="Valor"
              :rules="[(val) => !!val || 'El campo Valor es obligatorio.']"
            ></v-text-field>

            <v-autocomplete
              v-model="form.client"
              label="Cliente"
              :items="clients"
              item-text="nombre"
              item-value="_id"
              :rules="[(val) => !!val || 'Debe seleccionar un Cliente.']"
            >
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="formLoading"
            @click="handlerActionForm()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ClientService from "@/services/manager/notifications/client.service.js";
import ParamService from "@/services/manager/notifications/param.service.js";
import SetPageMixin from "@/Mixins/SetPageMixin.js";

import dayjs from "dayjs";

const Service = new ParamService();
const clientService = new ClientService();

export default {
  name: "CrudParametros",
  mixins: [SetPageMixin],
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      title: "Listado de Parametros",
      tableItems: [],
      clients: [],
      headers: [
        {
          value: "dominio",
          sortable: true,
          text: "Dominio",
        },
        {
          value: "valor",
          sortable: true,
          text: "Valor",
        },
        {
          value: "descripcion",
          sortable: false,
          text: "Descripción",
        },
        {
          value: "client",
          sortable: false,
          text: "Cliente",
        },
        {
          value: "createdAt",
          sortable: true,
          text: "Fech. Creación",
        },
        {
          value: "activo",
          sortable: true,
          text: "Estado",
        },
        {
          value: "actions",
          sortable: false,
          align: "right",
          text: "",
        },
      ],
      loading: false,
      loadingClient: false,
      search: "",
      dialog: false,
      selected: null,
      form: {
        dominio: "",
        clientId: "",
        valor: "",
        client: "",
        activo: true,
      },
      formLoading: false,
    };
  },
  created() {
    this.setup();
  },
  computed: {
    items() {
      return this.tableItems;
    },
  },
  methods: {
    async setup() {
      this.getClients();
      this.getItems();
    },
    getItems() {
      this.loading = true;
      Service.get()
        .then((response) => {
          this.tableItems = response.result;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getClients() {
      this.loadingClient = true;
      clientService
        .get()
        .then((response) => {
          this.clients = response.result;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingClient = false;
        });
    },

    findClient(item) {
      const idKey = "_id";

      return this.clients.find((e) => e[idKey] === item.client)?.nombre ?? "--";
    },

    handlerCloseDialog() {
      this.resetForm();
      this.dialog = false;
    },

    handlerActionForm() {
      const isValid = this.$refs.paramForm.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },

    handlerSelectItem(item) {
      this.selected = item;
      this.form.dominio = item.dominio;
      this.form.valor = item.valor;
      this.form.descripcion = item.descripcion;
      this.form.client = item.client;

      this.dialog = true;
    },

    save() {
      this.formLoading = true;
      Service.post(this.form)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    update() {
      const idKey = "_id";
      this.formLoading = true;
      Service.put(this.form, this.selected?.[idKey] ?? "")
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.getItems();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    remove(item) {
      const idKey = "_id";
      Service.delete(item?.[idKey] ?? "")
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "primary",
          });
          this.getItems();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        });
    },

    activateItem(value, item) {
      item.loading = true;
      const idKey = "_id";
      const form = {
        activo: value,
        nombre: item.nombre,
        clientId: item.clientId,
      };
      Service.put(form, item?.[idKey] ?? "")
        .then(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: value
              ? "El parametro ha sido reactivado"
              : "Se ha desactivado el parametro",
            top: true,
            right: true,
            color: value ? "success" : "primary",
          });
          this.getItems();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          item.loading = false;
        });
    },

    resetForm() {
      this.selected = null;
      this.form.descripcion = "";
      this.form.valor = "";
      this.form.dominio = "";
      this.form.client = "";
      this.form.activo = true;
      this.$refs.paramForm.resetValidation();
    },
  },
};
</script>

import axios from "axios";
import router from "@/router";
import { bffHeaders } from "@/helpers/headers.js";

const state = {
  loadingstatus: false,
  token: sessionStorage.getItem("token") || "",
  user: sessionStorage.getItem("user") || null,
  status: "",
  usermodal: false,
  updateState: null,
  perfil: "",
  token_temporal: sessionStorage.getItem("token_temporal") || {},
  clave_temporal: sessionStorage.getItem("clave_temporal") || {},
  convenio_url: sessionStorage.getItem("url_convenio") || "",
};
const actions = {
  login({ commit, getters, dispatch }, payload) {
    const path = `/ventas/auth`;
    let formData = {
      usuario: payload.username,
      clave: payload.password,
      convenio: getters.GetConvenio,
      returnSecureToken: true,
      modulo: "negocios",
    };
    return new Promise((resolve, reject) => {
      const axiosData = {
        method: "POST",
        url: path,
        data: formData,
        headers: bffHeaders,
      };
      axios(axiosData)
        .then(async (response) => {
          let token = response.data.data.token;
          commit("REFRESH_TOKEN", token);

          const userResponse = await dispatch("user");
          userResponse.token = token;
          resolve(userResponse);
        })
        .catch((e) => {
          const error = e.response.data;

          let code = error.codigo_accion || 0;

          localStorage.removeItem("token");
          reject({
            code,
          });
        });
    });
  },

  user({ commit }) {
    const path = `/ventas/usuarios/data`;
    return new Promise((resolve, reject) => {
      axios({ method: "GET", url: path, headers: bffHeaders })
        //.get(path)
        .then((response) => {
          const data = response.data.data;
          commit("SET_USERDATA", data.userData);
          resolve(data);
        })
        .catch(() =>
          reject("No se ha logrado recuperar la información de usuario")
        );
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit("CLEAR_USER_DATA");
      resolve("ok");
    });
  },

  closeDialog({ commit }) {
    commit("usermodal");
  },

  // DEFINE NEW PASSWORD

  async updateClave({ commit }, password) {
    commit("loadingstatus", true);
    //Authorization: `Bearer ${password.token}`
    axios({
      url: "/ventas/usuarios/clave",
      method: "PUT",
      headers: bffHeaders,
      data: {
        clave_actual: password.clave_temporal,
        clave_nueva: password.newpassword,
      },
    })
      .then(() => {
        commit("loadingstatus", false);
        // console.log(resp.data);
        router.push("/");
      })
      .catch(() => {
        // console.log("errr:", err);
        commit("loadingstatus", false);
      });
  },

  async UpdateProfile({ commit }, data) {
    // console.log("data: ", data);
    commit("changeUpdateStatus", true);
    axios
      .put(
        "/ventas/usuarios",
        {
          nombre: data.nombre,
          primer_apellido: data.primer_apellido,
          segundo_apellido: data.segundo_apellido,
          celular: data.celular,
          correo_electronico: data.correo_electronico,
          usuario: data.usuario,
          clave_actual: data.clave_actual,
          clave_nueva: data.clave_nueva,
          foto_perfil: data.foto_perfil,
          extension: data.extension,
          tipo_archivo: "img",
          nombre_archivo: "foto_perfil.png",
        },
        { headers: bffHeaders }
      )
      .then(() => {
        // console.log("res: ", res);
        commit("changeUpdateStatus", false);
      })
      .catch((err) => {
        console.log("error: ", err);
        commit("changeUpdateStatus", false);
      });
  },
};

const getters = {
  loadingstatus: (state) => state.loadingstatus,
  usermodal: (state) => state.usermodal,
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  token: (state) => state.token,
  user: (state) => {
    let data;
    try {
      data = JSON.parse(state.user);
    } catch {
      data = state.user || null;
    }
    return data;
  },
  updateStatus: (state) => state.updateState,
  clave: (state) => state.clave_temporal,
  token_temporal: (state) => state.token_temporal,
  url: (state) => state.convenio_url,
};

const mutations = {
  loadingstatus(state, newloadingstatus) {
    state.loadingstatus = newloadingstatus;
  },
  changeUpdateStatus(state, newStatus) {
    state.updateState = newStatus;
  },
  usermodal(state, newusermodal) {
    state.usermodal = newusermodal;
  },
  SET_CLAVE_TEMPORAL(state, clave) {
    sessionStorage.setItem("clave_temporal", clave);
    state.clave_temporal = clave;
  },
  SET_TOKEN_TEMPORAL(state, token) {
    sessionStorage.setItem("token_temporal", token);
    state.token_temporal = token;
  },
  REFRESH_TOKEN(state, token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    sessionStorage.setItem("token", token);
    state.token = token;
  },
  SET_USERDATA(state, payload) {
    sessionStorage.setItem("user", JSON.stringify(payload));
    sessionStorage.setItem("user", JSON.stringify(payload));
    state.user = payload;
    state.perfil = payload.perfil;
  },

  CLEAR_USER_DATA() {
    sessionStorage.clear();
    localStorage.clear();
    router.push({ name: "/" });
    location.reload();
  },
  SET_URL_CONVENIO(state, data) {
    state.convenio_url = data;
    sessionStorage.setItem("url_convenio", data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class BitacoraService {
  constructor() {
    this.URL_API = endpoints.manager.bitacora;
    this.API_HEADER = bffHeaders;
  }

  getAllbitacora = async (pagination) =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API,
        method: "GET",
        params: {
          page: pagination.current,
          limit: pagination.itemsPerPage,
        },
        headers: this.API_HEADER,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          const rejectData = {
            info: "Ha ocurrido un error al intentar recuperar las bitacoras del servidor.",
            result: e.response?.data,
          };
          reject(rejectData);
        });
    });

  getFileUrl = async (url) => {
    try {
      const respuesta = await axios.get(`${url}`);

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };
}
export default BitacoraService;

import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class NotificacionService {
  constructor() {
    this.URL_API = endpoints.manager.notificaciones.base;
    this.URL_API_PARAM = endpoints.manager.parametros.dominio;
    this.API_HEADER = bffHeaders;
  }

  getAllNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  CrearNotificaciones = async (data) => {
    const url = this.URL_API,
      headers = this.API_HEADER;
    return new Promise((resolve, reject) => {
      axios({
        url,
        headers,
        data,
        method: "POST",
      })
        .then((response) => {
          resolve({
            data: response.data,
            info: "Se ha creado la configuración satisfactoriamente!",
          });
        })
        .catch(() =>
          reject({
            info: "Ha ocurrido un error al intentar guardar la configuración.",
          })
        );
    });
  };

  ModificarNotificaciones = async (payload) => {
    const { id, ...resto } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.URL_API}/${id}`, resto, {
          headers: this.API_HEADER,
        })
        .then((response) => {
          resolve({
            data: response.data,
            info: "Se ha actualizado la configuración correctamente!",
          });
        })
        .catch(() =>
          reject({
            info: "Ha ocurrido un error al intentar actualizar la configuración.",
          })
        );
    });
  };

  convenioNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API_PARAM.notificacionesConvenios,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  procesoNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API_PARAM.notificacionesProcesos,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  operacionNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API_PARAM.notificacionesOperaciones,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  estadoNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API_PARAM.notificacionesEstadoNotificacion,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  tipoEnvioNotificaciones = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.URL_API_PARAM.notificacionesTiposEnvios,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
}

export default NotificacionService;

<template>
  <div>
    <v-card>
      <div class="d-flex align-center pa-3">
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar..."
          class="mt-0"
          single-line
          hide-details
        ></v-text-field> -->
        <slot name="table-header-left-1" />
        <v-divider
          inset
          vertical
          class="mx-3"
          v-if="tableHeaderDivider"
        ></v-divider>

        <!-- FILTROS -->
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined small fab v-bind="attrs" v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="subtitle-2"> Filtrar por: </v-card-title>
            <v-card-text>
              <v-text-field
                v-if="
                  !!headers.find(
                    (e) =>
                      e.value === quotKey.value ||
                      e.value === proposalKeyVentas.value
                  )
                "
                v-model="filters.cotizacion"
                label="Cotización"
                placeholder="Escribe un nro de cotización para filtrar..."
                dense
              ></v-text-field>
              <v-text-field
                v-if="
                  !!headers.find(
                    (e) =>
                      e.value === proposalKey.value ||
                      e.value === proposalKeyVentas.value
                  )
                "
                v-model="filters.propuesta"
                label="Propuesta"
                placeholder="Escribe un nro de propuesta para filtrar..."
                dense
              ></v-text-field>
              <v-text-field
                v-if="!!headers.find((e) => e.value === rutKey.value)"
                v-model="filters.taxid"
                label="Numero de documento"
                placeholder="Escribe un numero de documento para filtrar..."
                dense
              ></v-text-field>
              <v-text-field
                v-model="filters.funcion"
                label="Función"
                placeholder="Escribe una función para filtrar..."
                dense
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text small @click="resetFilters()">
                Borrar
              </v-btn>
              <v-btn color="primary" text small @click="filter()">
                Filtrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <slot name="table-header-left-2" />
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <slot name="table-header-right" />
        <v-btn v-bind="bind.refresh" v-on="on.refresh">
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table v-bind="{ ...bind.table, ...tableBind }" v-on="tableOn">
        <template #item.creadoEl="{ item }">
          {{ item.creadoEl | formatoFecha }}
        </template>

        <template #item.acciones="{ item }">
          <v-btn color="primary" x-small fab text @click="openForm(item)">
            <v-icon small> {{ icons.detail }} </v-icon>
          </v-btn>
          <v-btn color="error" x-small fab text @click="deleteItem(item._id)">
            <v-icon small> {{ icons.delete }} </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <confirm-modal ref="confirm" />
    <ConsultarPlantilla
      ref="formDialog"
      v-model="dialog"
      :item="selected"
      :snackbar="snackbar"
      @close="closeForm()"
    />
  </div>
</template>

<script>
import ConsultarPlantilla from "@/components/Bitacora/modal-detalle.vue";
import dayjs from "dayjs";
import BitacoraService from "@/services/manager/bitacoras/bitacora.service";

import SetPageMixin from "@/Mixins/SetPageMixin.js";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";
import {
  quotKey,
  quotKeyMpay2,
  proposalKey,
  proposalKeyVentas,
  rutKey,
} from "@/views/Manager/bitacoras/bitacora.table.data.js";

const bitacoraService = new BitacoraService();

export default {
  name: "ListBitacora",
  mixins: [SetPageMixin, PaginationMixin],
  props: {
    query: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    tableHeaderDivider: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConsultarPlantilla,
  },
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      tableItems: [],
      filters: {
        cotizacion: "",
        funcion: "",
        propuesta: "",
        taxid: "",
      },
    };
  },
  watch: {
    query: function () {
      this.resetFilters();
      this.filter();
    },
  },
  computed: {
    items() {
      return this.tableItems;
    },
    quotKey: () => quotKey,
    quotKeyMpay2: () => quotKeyMpay2,
    proposalKey: () => proposalKey,
    proposalKeyVentas: () => proposalKeyVentas,
    rutKey: () => rutKey,
  },
  methods: {
    setup() {
      const params = {
        app: this.query,
        page: this.pagination.page,
        limit: this.pagination.limit,
        ...this.filters,
      };
      this.loading = true;
      bitacoraService
        .GET(params)
        .then((response) => {
          const { success, message, data, pagination } = response;
          if (!success) {
            throw message;
          }
          this.tableItems = Array.isArray(data) ? data : [];
          this.setPaginationFromResponse(pagination);
        })
        .catch((e) => {
          const defaultError = "No se ha logrado recuperar el listado.";
          const error =
            typeof e === "string" ? e || defaultError : defaultError;
          this.snackbar(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(id) {
      this.$refs.confirm
        .show({
          title: "Eliminar una bitacora",
          description: "Confirme que desea realmente eliminar esta bitacora.",
        })
        .then((answer) => {
          if (answer) {
            bitacoraService
              .deleteItemById(id)
              .then(() => {
                this.snackbar("Bitacora eliminada correctamente!", "success");
                this.setup();
              })
              .catch(() =>
                this.snackbar("No se ha logrado eliminar una bitacora")
              );
          }
        });
    },
  },
};
</script>

<template>
  <ListBitacora :query="query" :headers="headers" />
</template>

<script>
import ListBitacora from "@/components/Bitacora/ListBitacora.vue";

export default {
  setup() {
    return {
      query: "app=mce-api-integracion-forum",
      headers: [
        { text: "Fecha", sortable: true, value: "creadoEl", width: "11%" },
        { text: "App", value: "app" },
        { text: "Evento", value: "tipoEvento" },
        { text: "Funcion", value: "funcion" },
        { text: "Intension", value: "key.idIntension" },

        // { text: 'Transaccion', value: 'key.idTransaccion' },
        // { text: 'Usuario', value: 'key.usuario' },
        // { text: 'Cliente', value: 'key.cliente' },
        // { text: 'Cliente Identificacion', value: 'key.clienteIdentificacion' },
        { text: "Corredora", value: "key.idTrxCorredora" },
        /* { text: 'Estado', value: 'eliminado' }, */
        { text: "", value: "acciones", align: "right" },
      ],
    };
  },
  components: { ListBitacora },
};
</script>

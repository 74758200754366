<template>
  <v-card>
    <v-tabs v-model="tab" grow>
      <v-tab>Cotizaciones</v-tab>
      <v-tab>Propuestas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card-text>
          <Cotizaciones />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <Propuestas />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
//import { mapActions } from "vuex";

import Cotizaciones from "./Cotizaciones.vue";
import Propuestas from "./Propuestas.vue";

export default {
  components: {
    Propuestas,
    Cotizaciones,
  },
  data() {
    return {
      tab: null,
      propuesta: [],
      cotizacion: [],
    };
  },
  methods: {
    /* ...mapActions("Cotizaciones", ["getCotizaciones"]),
    ...mapActions("Propuestas", ["getPropuestas"]), */
  },
  /* watch:{
    tab(index){
      if(index === 0) {
        this.getCotizaciones().then((res) => {
          this.cotizacion = res;
        });
      }
      
      if(index === 0) {
        this.getPropuestas().then((res) => {
          this.propuesta = res;
        });
      }
    }
  } */
};
</script>

export const baseKeys = {
  id: "_id",
  name: "personalizacion_descripcion",
  customized: "personalizacion_corporativa",
  domain: "dominio",
  upperDomain: "dominio_padre",
  template: {
    text: "plantilla",
    value: "id",
    key: "plantillas_fidelizacion",
    getKey: "plantillas",
    options: {
      key: "opciones",
      id: "_id",
      text: "opcion",
      body: "cuerpo",
      head: "asunto",
    },
    form: {
      template: "id_plantilla",
      option: "id_opcion",
    },
  },
  logo: {
    url: "url",
    getKey: "logo",
    change: "cambiar_logo",
    name: "nombre_archivo",
    type: "extension",
    file: "archivo",
  },
  colors: {
    text: "tipo_descripcion",
    value: "tipo",
    color: "color",
    key: "tipos_colores",
    getKey: "colores",
    description: "texto",
  },
  screens: {
    id: "_id",
    active: "activo",
    code: "codigo",
    text: "nombre",
    section: "seccion",
    sectionName: "seccion_nombre",
    getKey: "acceso_pantallas",
    /* dashboardDocs: "",
    dashboardQuot: "",
    clientDocs: "",
    clientQuot: "",
    clientEdit: "",
    clientBack: "",
    clientProds: "",
    clientMat: "",
    lefBarCons: "", */
  },
  sender: {
    name: "remitente_nombre",
    email: "remitente_correo",
    getKey: "correo",
  },
};

export default baseKeys;

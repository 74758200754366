<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <ModalTipoCanales v-model="dialog" v-on="on.form" v-bind="bind.form" />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalTipoCanales from "@/components/Modal/ModalTipoCanales.vue";
import { mapState, mapActions } from "vuex";

import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudTipoCanales",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        {
          text: "Canal",
          align: "start",
          value: "tipo_canal_descripcion",
        },
        { text: "Codigo", value: "codigo" },
        { text: "", align: "right", value: "actions", sortable: false },
      ],
    };
  },

  components: {
    TableMantenedor,
    ModalTipoCanales,
  },

  computed: {
    ...mapState("TipoCanal", ["tipoCanales"]),

    items() {
      return this.tipoCanales;
    },
  },

  methods: {
    ...mapActions("TipoCanal", [
      "getTipoDeCanales",
      "saveNewTypeCanal",
      "updateTypeCanal",
      "deleteTypeCanal",
    ]),

    setup() {
      this.loading = true;
      this.getTipoDeCanales().finally(() => {
        this.loading = false;
      });
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.saveNewTypeCanal;
      const formData = {
        tipo_canal_descripcion: form.descripcion,
        codigo: form.codigo,
      };
      if (this.selected) {
        method = this.updateTypeCanal;
        formData.id = this.selected.id;
      }
      method(formData)
        .then((response) => {
          this.closeForm();
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar un tipo de canal",
          description: "Proceder a eliminar el tipo de canal?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteTypeCanal(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

export default [
  {
    ref: "50",
    name: "Forum",
    icon: "equal",
    items: [
      {
        ref: "50-1",
        name: "Sucursales",
        path: "HomologacionesForum",
        icon: "equal",
        text: "Configurador de homologaciones para Sucursales",
      },
      {
        ref: "50-2",
        name: "Operaciones",
        path: "OperacionesForum",
        icon: "transit-connection-horizontal",
        text: "Configurador de Operaciones Forum",
      },
      {
        ref: "50-3",
        name: "Inf. Simulaciones",
        path: "QuotDownloadForum",
        icon: "application-brackets-outline",
        text: "Configurador de Operaciones Forum",
      },
    ],
  },
];

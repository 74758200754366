import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class PermissionsService {
  constructor() {
    this.PATH = endpoints.manager.permisos;
    this.HEADERS = bffHeaders;
  }

  static httpMethod(data = {}, messages = { success: "", error: "" }) {
    return new Promise((resolve, reject) => {
      axios(data)
        .then((response) => {
          const result = response.data?.data?.result;
          resolve({
            message: messages?.success ?? "",
            result: result?.data ?? [],
            pagination: result?.pagination ?? {},
          });
        })
        .catch((error) => {
          const errorData = error?.response?.data?.error ?? messages.error;
          reject(new Error(errorData));
        });
    });
  }

  get(pagination = {}) {
    const path = `${this.PATH}?${[
      `page=${pagination?.page ?? 1}`,
      `limit=${pagination?.limit ?? 10}`,
    ].join("&")}`;

    const http = {
      url: path,
      method: "GET",
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      error: "No se ha logrado recuperar el listado de permisos.",
    });
  }

  post(formData = {}) {
    const http = {
      url: this.PATH,
      method: "POST",
      data: formData,
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      success: "¡Se ha creado un nuevo permiso satisfactoriamente!",
      error: "Ha ocurrido un error al intentar crear un permiso.",
    });
  }

  put(formData = {}, itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "PUT",
      data: formData,
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      success: "¡Se ha actualizado un permiso correctamente!",
      error: "Ha ocurrido un error al intentar actualizar un permiso.",
    });
  }

  activate(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}/logico`,
      method: "PUT",
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      success: "¡Se ha reactivado un permiso correctamente!.",
      error: "Ha ocurrido un error al reactivar un permiso.",
    });
  }

  deactivate(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}/logico`,
      method: "DELETE",
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      success: "Se ha desactivado un permiso satisfactoriamente.",
      error: "Ha ocurrido un error al intentar desactivar un permiso.",
    });
  }

  delete(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}/bd`,
      method: "DELETE",
      headers: this.HEADERS,
    };

    return PermissionsService.httpMethod(http, {
      success: "Se ha eliminado un permiso correctamente.",
      error: "Ha ocurrido un error al intentar eliminar un permiso.",
    });
  }
}

export default PermissionsService;

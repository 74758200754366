// Mutaciones generales para el modulo de usuario

export const SetList = (state, payload) => {
  state.list = payload;
};

export const SetLogo = (state, payload) => {
  state.logo = payload;
};

export const ResetLogo = (state) => {
  state.logo = "";
};

export const SetColors = (state, payload) => {
  state.colors = payload;
};

export const SetTemplates = (state, payload) => {
  state.templates = payload;
};

export const SetDomain = (state, payload) => {
  state.domain = payload;
};

export const SetSender = (state, payload) => {
  state.sender = payload;
};

export const SetScreen = (state, payload) => {
  state.keys.screensDKeys = null;
  state.screens = null;
  const screenKeys = state.keys.base.screens;
  let screens = {},
    screenDescription = {};
  payload.forEach((element) => {
    !(element[screenKeys.section] in screens)
      ? (screens[element[screenKeys.section]] = [element])
      : screens[element[screenKeys.section]].push(element);
    if (!(element[screenKeys.section] in screenDescription)) {
      screenDescription[element[screenKeys.section]] =
        element[screenKeys.sectionName];
    }
  });
  state.keys.screensDKeys = screenDescription;
  state.screens = screens;
};

export const SetScreenValues = function (state, payload) {
  if (state.screens) {
    const screenKeys = state.keys.base.screens;
    payload.forEach((sr) => {
      let selected = state.screens[sr[screenKeys.section]]?.find(
        (e) => e[screenKeys.code] == sr[screenKeys.code]
      );
      if (selected) {
        selected[screenKeys.active] = sr[screenKeys.active];
      }
    });
  }
};

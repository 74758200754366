<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalParametrizacion v-model="dialog" v-on="on.form" v-bind="bind.form" />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalParametrizacion from "@/components/Modal/ModalParametrizacion.vue";
import { mapState, mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "ParametrizacionCrudPage",
  mixins: [PageMixin],
  data() {
    return {
      headers: [
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
        },
        { text: "Dominio", value: "dominio" },
        { text: "Valor", value: "valor" },
        { text: "Activo", value: "activo" },
        { text: "", align: "right", value: "actions", sortable: false },
      ],
    };
  },
  components: {
    TableMantenedor,
    ModalParametrizacion,
  },
  computed: {
    ...mapState("Parametrizacion", ["parametrizacion"]),
    items() {
      return this.parametrizacion;
    },
  },
  methods: {
    ...mapActions("Parametrizacion", [
      "getParametrizacion",
      "saveNewParametrizacion",
      "updateParametrizacion",
      "deleteParametrizacion",
    ]),

    setup() {
      this.loading = true;
      this.getParametrizacion().finally(() => {
        this.loading = false;
      });
    },

    saveItem(formData) {
      this.loadingForm = true;
      let method = this.saveNewParametrizacion,
        payload = {
          activo: formData.activo,
          dominio: formData.dominio,
          valor: formData.valor,
          descripcion: formData.descripcion,
        };
      if (this.selected) {
        console.log("2", this.selected);
        payload.id = this.selected._id;
        method = this.updateParametrizacion;
      }
      method(payload)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar una parametrización",
          description: "¿Está seguro que desea eliminar esta parametrización?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteParametrizacion(item._id)
              .then((response) => {
                this.snackbar(response, "success");
                this.setup();
              })
              .catch((response) => {
                this.snackbar(response);
              });
          }
        });
    },
  },
};
</script>

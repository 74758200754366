import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

export const GetSucursales = ({ getters }, query = {}) => {
  const params = {
    limit: query.limit,
    page: query.page,
    search: query.search,
  };
  let path = `${getters.GetEndpoint}?concesionario=${query.concesionario}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      params,
    })
      .then((response) => {
        const result = response.data.data.sucursales;
        resolve(result);
      })
      .catch((e) => reject(e.response.data));
  });
};

export const PostSucursal = ({ getters }, form) => {
  const path = getters.GetEndpoint;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: path,
      headers: bffHeaders,
      data: form,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha guardado la sucursal satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};
// PUT
export const PutSucursal = ({ getters }, form) => {
  const path = `${getters.GetEndpoint}/${form.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: path,
      headers: bffHeaders,
      data: {
        codigo: form.codigo,
        eliminado: form.eliminado,
        sucursal_descripcion: form.sucursal_descripcion,
        concesionario: form.concesionario,
        concesionario_descripcion: form.concesionario_descripcion,
        nombre: form.nombre,
        comuna: form.comuna,
        calle: form.calle,
        numero: form.numero,
        piso: form.piso,
      },
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha actualizado la sucursal satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
};

export const DeleteSucursal = ({ getters }, id) => {
  const path = `${getters.GetEndpoint}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha eliminado la sucursal correctamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};

import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

export const GetVendedores = ({ getters }, query = {}) => {
  const params = {
    limit: query.limit,
    page: query.page,
    search: query.search,
  };
  let path = `${getters.GetEndpoint}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      params,
    })
      .then((response) => {
        const result = response.data.data.vendedores;
        resolve(result);
      })
      .catch((e) => reject(e.response.data));
  });
};

export const GetVendedor = ({ getters }, form) => {
  let path = `${getters.GetEndpoint}?numero_documento=${form.numero_documento}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.vendedores;
        resolve(result);
      })
      .catch((e) => reject(e.response.data));
  });
};

export const PostVendedor = ({ getters }, form) => {
  const path = getters.GetEndpoint;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: path,
      headers: bffHeaders,
      data: form,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha guardado el vendedor satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};
// PUT
export const PutVendedor = ({ getters }, form) => {
  const path = `${getters.GetEndpoint}/${form.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: path,
      headers: bffHeaders,
      data: {
        codigo: form.codigo,
        vendedor_descripcion: form.vendedor_descripcion,
        sucursal: form.sucursal,
        sucursal_descripcion: form.sucursal_descripcion,
        numero_documento: form.numero_documento,
        nombre: form.nombre,
        primer_apellido: form.primer_apellido,
        segundo_apellido: form.segundo_apellido,
        email: form.email,
        telefono: form.telefono,
        ex_sucursal: form?.ex_sucursal,
      },
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha actualizado el vendedor satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};

export const DeleteVendedor = ({ getters }, form) => {
  const path = `${getters.GetEndpoint}/${form.id}/${form.sucursal}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha eliminado el vendedor correctamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};

export const setAllData = (state, payload) => {
  state.allData = payload;
};

export const setNewData = (state, data) => {
  state.allData = [data, ...state.allData];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateData = (state, data) => {
  state.allData = state.allData.map((item) =>
    item.id === data.id ? data : item
  );
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteItemData = (state, id) => {
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
  state.allData = state.allData.filter((canal) => canal.id !== id);
};

export const setDontSaveNewData = (state, mensajeError) => {
  state.openModalNew = true;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = mensajeError;
  state.errorModalNew = true;
};

export const setDontDeleteItemMessage = (state, mensajeError) => {
  state.openModalEliminar = true;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = mensajeError;
  state.errorModalDelete = true;
};

//MODAL NEW
export const setOpenModalNew = (state) => {
  state.openModalNew = true;
};

export const setCloseModalNew = (state) => {
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

//BUTTON LOADING MODAL NEW
export const setLoadButtonModalNew = (state) => {
  state.loadButtonModalNew = true;
};

//BUTTON LOADING MODAL DELETE
export const setLoadButtonModalDelete = (state) => {
  state.loadButtonModalDelete = true;
};

//MODAL DELETE
export const setOpenModalDelete = (state) => {
  state.openModalEliminar = true;
};

export const setCloseModalDelete = (state) => {
  state.openModalEliminar = false;
};

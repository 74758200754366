import { TableData, Icons, Labels } from "@/Mixins/PageMixinData.js";

export const PageMixin = {
  data: () => ({
    loading: false,
    loadingForm: false,
    search: "",
    selected: null,
    itemId: "_id",
    itemDisabled: "eliminado",
    dialog: false,
    filterMenu: false,
    filters: {},
    table: TableData,
    labels: Labels,
    icons: Icons,
    refs: {
      modal: "formDialog",
      confirm: "confirm",
    },
  }),
  components: {
    // Modal para confirmar una accion
    ConfirmModal: () => import("@/components/Modal/ConfirmModal.vue"),
  },
  created() {
    this.setPage();
    this.setup();
  },
  computed: {
    items: () => [],

    bind() {
      return {
        search: {
          class: "mt-0 align-self-end",
          [this.pagination ? "append-outer-icon" : "append-icon"]: this
            .pagination
            ? this.icons.searchClick
            : this.icons.search,
          label: this.labels.search,
          "single-line": true,
          "hide-details": true,
          clearable: true,
          dense: true,
        },
        divider: {
          vertical: true,
          class: "mx-3",
          inset: true,
        },
        refresh: {
          color: "primary",
          outlined: true,
          small: true,
          fab: true,
          class: "mr-3",
          loading: this.loading,
        },
        new: {
          color: "primary",
        },
        table: {
          loading: this.loading,
          "footer-props": this.table.footerProps,
          "loading-text": this.table.loadingText,
          "no-data-text": this.table.emptyText,
          class: "elevation-1",
          headers: this.headers,
          items: this.items,
        },
        form: {
          ref: "formDialog",
          "form-data": this.selected,
          loading: this.loadingForm,
        },
        detail: {
          ref: "detailDialog",
        },
        actions: {
          "x-small": true,
          fab: true,
          text: true,
          color: "primary",
        },
      };
    },

    on() {
      return {
        search: {
          "click:append": this.setup,
          "click:clear": this.setup,
        },
        refresh: {
          click: this.setup,
        },
        new: {
          click: this.openForm,
        },
        table: {
          edit: this.openForm,
          delete: this.deleteItem,
        },
        form: {
          close: this.closeForm,
          submit: this.saveItem,
        },
        detail: {
          close: this.closeDetail,
        },
        edit: (item) => {
          return {
            click: () => this.openForm(item),
          };
        },
        delete: (item) => {
          return {
            click: () => this.deleteItem(item),
          };
        },
      };
    },
    confirm() {
      return this.$refs[this.refs.confirm]?.show;
    },
  },
  methods: {
    // Funcion base para cargar data del componente
    setup: () => null,
    // Funcion para recuperar data base de la pagina
    setPage() {
      const pageNumber = this.$route.meta.page ?? this.page ?? "01";
      this.$store.commit("setPage", { page: pageNumber });
    },
    // Funcion para mostrar mensajes
    snackbar(text = "", color = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: text,
        top: true,
        right: true,
        color: color,
      });
    },
    // Funcion base para abrir el formulario del componente
    openForm(item = null) {
      if (item && !(item instanceof PointerEvent)) {
        this.selected = item;
      }
      this.dialog = true;
    },
    // Funcion base para cerrar el formulario del componente
    closeForm() {
      this.dialog = false;
      this.selected = null;
      this.$refs.formDialog?.reset();
    },
    closeDetail() {
      this.dialog = false;
    },
    // Funcion para guardar la data del formulario en el componente
    setFormData: (data) => ({ ...data }),
    saveItem(data) {
      this.loadingForm = true;
      let method = this.saveMethod();
      const formData = this.setFormData(data);
      if (this.selected) {
        method = this.updateMethod();
        formData.id = this.selected[this.itemId];
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((error) => {
          this.snackbar(this.handlerGetError(error));
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    saveMethod: () => null,
    updateMethod: () => null,
    // Funcion para eliminar un elemento de la tabla en el componente
    deleteItem(
      item,
      title = "Eliminar elemento",
      description = "Esta a punto de eliminar un elemento del listado ¿Desea proceder?"
    ) {
      const method = this.deleteMethod();
      this.confirm({
        title,
        description,
      }).then((answer) => {
        if (answer) {
          item.loading = true;
          method(item[this.itemId])
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(this.handlerGetError(error));
            })
            .finally(() => {
              item.loading = false;
            });
        }
      });
    },
    deleteMethod: () => null,
    // Funcion para activar/desactivar un elemento de la tabla en el componente
    activateItem(item) {
      item.loading = true;
      const method = this.activateMethod();
      method({
        id: item[this.itemId],
        value: item[this.itemDisabled],
      })
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(this.handlerGetError(error));
        })
        .finally(() => {
          item.loading = false;
        });
    },
    activateMethod: () => null,
    handlerGetError(error) {
      return (
        error.result?.data?.lista_mensaje?.[0]?.mensaje ||
        error.result?.message ||
        error.info
      );
    },
  },
};

export default PageMixin;

<template>
  <div class="text-center">
    <v-dialog
      width="500"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario Personas
          <v-spacer></v-spacer>

          <v-btn
            x-small
            color="primary"
            fab
            text
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-select
              v-model="form.pais"
              :items="paises"
              item-text="pais_descripcion"
              item-value="_id"
              label="País"
              persistent-hint
              :rules="[(v) => !!v || 'Debe seleccionar un país.']"
              single-line
            ></v-select>
            <v-text-field
              label="Código"
              :rules="[(v) => !!v || 'El campo Código es requerido.']"
              v-model="form.codigo"
            ></v-text-field>
            <v-text-field
              label="Descripción"
              :rules="[(v) => !!v || 'El campo descripcion es requerido.']"
              v-model="form.descripcion"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :loading="loading" @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalPersonas",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paises: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        descripcion: "",
        codigo: "",
        pais: "",
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setter();
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },

    setter() {
      this.form.descripcion = this.formData.descripcion;
      this.form.codigo = this.formData.codigo;
      this.form.pais = this.formData.pais_id;
    },

    reset() {
      this.form.descripcion = "";
      this.form.codigo = "";
      this.form.pais = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <div class="d-flex pa-3 align-center">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar..."
          dense
          hide-details
          class="align-self-end"
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer></v-spacer>

        <v-btn
          outlined
          color="primary"
          small
          :loading="loading"
          @click="getPlantillas()"
          fab
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn color="primary" class="ml-3" @click="modals.form = true">
          Nuevo
        </v-btn>
      </div>

      <!-- Table -->
      <div class="table-responsive mb-0">
        <v-data-table
          :items="itemsTable"
          :headers="fields"
          :loading="loading"
          loading-text="Cargando... espere un momento"
          :items-per-page="selectMostrar"
          :search="search"
          :footer-props="{
            'items-per-page-text': 'mostar',
            'items-per-page-options': [5, 10, 25, 50],
          }"
        >
          <template #item.plantilla="{ item }">
            {{
              options.plantillas.find((e) => e._id == item.plantilla)?.nombre ??
              "Desconocida"
            }}
          </template>

          <template #item.convenio="{ item }">
            {{
              options.convenios.find((e) => e.value == item.convenio)?.text ??
              item.convenio ??
              ""
            }}
          </template>

          <template #item.proceso="{ item }">
            {{
              options.procesos.find((e) => e.value == item.proceso)?.text ??
              item.proceso ??
              ""
            }}
          </template>

          <template #item.operacion="{ item }">
            {{
              options.operaciones.find((e) => e.value == item.operacion)
                ?.text ??
              item.operacion ??
              ""
            }}
          </template>

          <template #item.tipoEnvio="{ item }">
            {{
              options.tipoEnvio.find((e) => e.value == item.tipoEnvio)?.text ??
              item.tipoEnvio ??
              ""
            }}
          </template>

          <template v-slot:item.accion="{ item }">
            <!-- EDITAR -->
            <v-btn
              color="primary"
              fab
              text
              x-small
              @click="(selected = item), (modals.form = true)"
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>

            <!-- VER -->
            <v-btn
              color="blue-grey"
              fab
              text
              x-small
              @click="(selected = item), (modals.preview = true)"
            >
              <v-icon> mdi-eye-outline </v-icon>
            </v-btn>

            <!-- CODIGO -->
            <v-btn
              color="blue-grey"
              fab
              text
              x-small
              @click="(selected = item), (modals.requestBody = true)"
            >
              <v-icon> mdi-code-braces </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <config-form
      ref="formComponent"
      v-model="modals.form"
      :form-data="selected"
      @close="closeForm()"
      @submit="submitForm()"
      v-bind="bindData"
    />
    <preview-config
      v-model="modals.preview"
      :item="selected"
      v-bind="bindData"
      @close="(modals.preview = false), (selected = null)"
    />
    <RequestBody
      v-model="modals.requestBody"
      :item="selected"
      v-bind="bindData"
      @close="modals.requestBody = false"
    />
  </div>
</template>

<script>
import PreviewConfig from "@/views/Manager/notificaciones/forms/previewConfig.vue";
import ConfigForm from "@/views/Manager/notificaciones/forms/formConfig.vue";
import RequestBody from "@/views/Manager/notificaciones/forms/request-body.vue";

import NotificacionService from "@/services/manager/notifications/notificaciones.service.js";
import PlantillaService from "@/services/manager/notifications/plantillas.service.js";
import ClientService from "@/services/manager/notifications/client.service.js";

const notificacionService = new NotificacionService();
const plantillaService = new PlantillaService();
const clientService = new ClientService();

import SetPageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "ConfiguracionesNotifPage",
  mixins: [SetPageMixin],
  components: {
    ConfigForm,
    RequestBody,
    PreviewConfig,
  },
  data() {
    return {
      title: "Configurador de Notificación",
      selectMostrar: 10,
      loading: false,
      fields: [
        {
          value: "plantilla",
          sortable: false,
          text: "Plantilla",
        },
        {
          value: "convenio",
          sortable: false,
          text: "Convenio",
        },
        {
          value: "proceso",
          sortable: false,
          text: "Proceso",
        },
        {
          value: "operacion",
          sortable: false,
          text: "Operacion",
        },
        {
          value: "tipoEnvio",
          sortable: false,
          text: "Tipo Envío",
        },
        {
          value: "accion",
          sortable: false,
          align: "right",
          text: "",
        },
      ],
      selected: null,
      modals: {
        form: false,
        preview: false,
        requestBody: false,
      },
      options: {
        plantillas: [],
        convenios: [],
        procesos: [],
        operaciones: [],
        estados: [],
        tipoEnvio: [],
        clientes: [],
      },
      itemsTable: [],

      openConsultar: false,
      openEditar: false,
      openRBody: false,
      canal: {},
      plantilla: {},
      search: "",
    };
  },
  async mounted() {
    this.getPlantillas();
    this.getConvenio();
    this.getProceso();
    this.getOperacion();
    this.getEstado();
    this.getTipoEnvio();
    this.getclient();
    await this.getNotificaciones();
  },

  computed: {
    bindData() {
      return {
        processes: this.options.procesos,
        convenios: this.options.convenios,
        templates: this.options.plantillas,
        operations: this.options.operaciones,
        status: this.options.estados,
        deliveryList: this.options.tipoEnvio,
        clients: this.options.clientes,
      };
    },
  },

  methods: {
    // Obtener plantillas
    getPlantillas() {
      plantillaService
        .getAllPlantillas()
        .then((resp) => {
          this.options.plantillas = resp.data.data.data;
        })
        .catch((err) => console.log(`Error respuesta del servidor: ${err}`));
    },

    // Obtener Notificaciones
    getNotificaciones() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        notificacionService
          .getAllNotificaciones()
          .then((resp) => {
            this.itemsTable = resp.data.data.data;
            resolve("ok");
          })
          .catch((err) => reject(`Error respuesta del servidor: ${err}`))
          .finally(() => {
            this.loading = false;
          });
      });
    },

    // Obtener Convenios
    async getConvenio() {
      const convenio = notificacionService.convenioNotificaciones();
      await convenio.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.options.convenios.push({
            text: item.descripcion,
            value: item.valor,
            client: item.client,
          });
        });
      });
      convenio.catch((err) =>
        console.log(`Error respuesta del servidor: ${err}`)
      );
    },

    // Obtener Procesos
    async getProceso() {
      const proceso = notificacionService.procesoNotificaciones();
      await proceso.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.options.procesos.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      proceso.catch((err) =>
        console.log(`Error respuesta del servidor: ${err}`)
      );
    },

    // Obtener Operaciones
    async getOperacion() {
      const operacion = notificacionService.operacionNotificaciones();
      await operacion.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.options.operaciones.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      operacion.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },

    // Obtener Estados
    async getEstado() {
      const estado = notificacionService.estadoNotificaciones();
      await estado.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.options.estados.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      estado.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },

    // Obtener tipos de envios
    async getTipoEnvio() {
      const tipos = notificacionService.tipoEnvioNotificaciones();
      await tipos.then((resp) => {
        const respData = resp.data;
        respData.forEach((item) => {
          this.options.tipoEnvio.push({
            text: item.descripcion,
            value: item.valor,
          });
        });
      });
      tipos.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },

    async getclient() {
      const estado = clientService.client();
      await estado.then((resp) => {
        const respData = resp.data.result;
        respData.forEach((item) => {
          this.options.clientes.push({
            text: item.nombre,
            value: item._id,
          });
        });
      });
      estado.catch((err) => {
        console.log(
          (this.mensajeError = `Error respuesta del servidor: ${err}`)
        );
      });
    },

    closeForm() {
      const formComponent = this.$refs.formComponent;
      this.selected = null;
      formComponent.resetForm();
      formComponent.$refs.form.resetValidation();
      this.modals.form = false;
    },

    submitForm() {
      this.closeForm();
      this.getNotificaciones();
    },
  },
};
</script>

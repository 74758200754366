<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        v-bind="bind.search"
        @input="getPropuesta"
      ></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>
      <v-spacer></v-spacer>
    </v-card-title>

    <ModalDetalle
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :data="propuestas"
    />
    <v-data-table
      :items="propuesta"
      :headers="headers"
      :footer-props="{
        'items-per-page-text': 'mostar',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      loading-text="Cargando... espere un momento"
    >
      <template #item.fecha_creacion="{ item }">
        {{ item.fecha_creacion | formatoFecha }}
      </template>

      <template v-slot:item.actions="item">
        <v-btn color="blue-grey" fab x-small text @click="viewItem(item)">
          <v-icon> mdi-eye-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import ModalDetalle from "@/components/Modal/ModalDetalleCotProp.vue";
import { mapState, mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  mixins: [PageMixin],
  components: { ModalDetalle },
  data() {
    return {
      propuesta: [],
      headers: [
        { text: "Propuesta", value: "numero_propuesta" },
        { text: "Cotización", value: "numero_cotizacion" },
        { text: "Fecha", value: "fecha_creacion" },
        { text: "Usuario", value: "nombre_usuario" },
        { text: "Estado", value: "estado_descripcion" },
        { text: "", align: "right", value: "actions", sortable: false },
      ],
    };
  },
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  computed: {
    ...mapState("Propuestas", ["propuestas"]),

    items() {
      return [this.propuestas];
    },
  },
  methods: {
    ...mapActions("Propuestas", ["getPropuestas"]),

    getPropuesta() {
      this.loading = true;
      this.getPropuestas(this.search)
        .then((res) => {
          this.propuesta = [res];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    viewItem() {
      this.dialog = true;
    },
  },
};
</script>

export const setAllBancos = (state, data) => {
  state.bancos = data;
};

export const setNewBanco = (state, data) => {
  state.bancos = [data, ...state.bancos];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateBanco = (state, data) => {
  state.bancos = state.bancos.map((canal) =>
    canal.id === data.id ? data : canal
  );
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteBanco = (state, id) => {
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
  state.bancos = state.bancos.filter((canal) => canal.id !== id);
};

export const setDontSaveNewBanco = (state, mensajeError) => {
  state.openModalNew = true;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = mensajeError;
  state.errorModalNew = true;
};

export const setDontDeleteBanco = (state, mensajeError) => {
  state.openModalEliminar = true;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = mensajeError;
  state.errorModalDelete = true;
};

//MODAL NEW
export const setOpenModalNew = (state) => {
  state.openModalNew = true;
};

export const setCloseModalNew = (state) => {
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

//BUTTON LOADING MODAL NEW
export const setLoadButtonModalNew = (state) => {
  state.loadButtonModalNew = true;
};

//BUTTON LOADING MODAL DELETE
export const setLoadButtonModalDelete = (state) => {
  state.loadButtonModalDelete = true;
};

//MODAL DELETE
export const setOpenModalDelete = (state) => {
  state.openModalEliminar = true;
};

export const setCloseModalDelete = (state) => {
  state.openModalEliminar = false;
};

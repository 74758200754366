import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class OperationService {
  constructor() {
    this.PATH = endpoints.integrations.operaciones;
    this.HEADERS = bffHeaders;
  }

  static httpMethod(data = {}, messages = { success: "", error: "" }) {
    return new Promise((resolve, reject) => {
      axios(data)
        .then((response) => {
          const result = response.data?.data ?? [];
          if (result) {
            result.forEach((e) => {
              e.removing = false;
            });
          }
          resolve({
            message: messages?.success ?? "",
            result,
            pagination: response.data?.pagination ?? {},
          });
        })
        .catch((error) => {
          const errorData = error?.response?.data?.error ?? messages.error;
          reject(new Error(errorData));
        });
    });
  }

  get(pagination = {}, filter = {}) {
    let path = `${this.PATH}?${[
      `page=${pagination?.page ?? 1}`,
      `limit=${pagination?.limit ?? 10}`,
    ].join("&")}`;

    if (filter.transaccion) {
      path += `&transaccion=${filter.transaccion}`;
    }
    if (filter.cotizacion) {
      path += `&cotizacion=${filter.cotizacion}`;
    }
    if (filter.documento) {
      path += `&documento=${filter.documento}`;
    }
    if (filter.intencion) {
      path += `&intencion=${filter.intencion}`;
    }

    const http = {
      url: path,
      method: "GET",
      headers: this.HEADERS,
    };

    return OperationService.httpMethod(http, {
      error: "No se ha logrado recuperar el listado de operaciones.",
    });
  }

  delete(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "DELETE",
      headers: this.HEADERS,
    };

    return OperationService.httpMethod(http, {
      success: "Se ha eliminado una operación correctamente.",
      error: "Ha ocurrido un error al intentar eliminar una operación.",
    });
  }
}

export default OperationService;

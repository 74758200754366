<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="pagination.search"
        placeholder="Buscar una sucursal..."
        v-on="on.search"
        v-bind="bind.search"
        @keyup.enter="setup()"
      ></v-text-field>

      <v-divider v-bind="bind.divider"></v-divider>

      <!-- <v-menu
          v-model="filterMenu"
          :nudge-width="200"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab small outlined v-on="on" v-bind="attrs">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
            <v-autocomplete
                v-model="filters.fecha_ini"
                label="Fecha Inicio"
                :items="Countries"
                outlined
                dense
                item-value="codigo"
                item-text="pais_descripcion"
                item-disabled="eliminado"
                :loading="loadingCountries"
                clearable
                @change="getItems()"
              ></v-autocomplete>

              <v-autocomplete
                v-model="filters.fecha_fin"
                label="Fecha Termino"
                :items="ListType"
                outlined
                dense
                clearable
                @change="getItems()"
              ></v-autocomplete> 
            </v-card-text>
          </v-card>
        </v-menu>-->

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <v-data-table
      v-bind="bind.table"
      :page="pagination.page"
      :server-items-length="pagination.objects"
      :items-per-page="pagination.limit"
      @pagination="setPaginationFromTable($event)"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" fab text x-small @click="openForm(item)">
          <v-icon small> {{ icons.edit }} </v-icon>
        </v-btn>
        <v-btn color="error" fab text x-small @click="deleteItem(item)">
          <v-icon small> {{ icons.delete }} </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <confirm-modal ref="confirm" />

    <ModalHomologaciones v-model="dialog" v-on="on.form" v-bind="bind.form" />
  </v-card>
</template>

<script>
//import TableMantenedor from "@/components/TableMantenedores/TableMantenedorInteg.vue";
import ModalHomologaciones from "@/components/Modal/ModalHomologacion.vue";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";
import SetPageMixin from "@/Mixins/SetPageMixin.js";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [PaginationMixin, SetPageMixin],
  name: "HomologacionesForum",
  data() {
    return {
      headers: [
        { text: "Sucursal Forum", value: "entrada" },
        { text: "Sucursal Mclick", value: "salida_descripcion" },
        { text: "Descripción", value: "descripcion" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },

  components: {
    ModalHomologaciones,
  },
  computed: {
    ...mapGetters("Homologaciones", ["Items"]),

    items() {
      return this.Items;
    },
  },
  methods: {
    ...mapActions("Homologaciones", [
      "GetHomologaciones",
      "PostHomologacion",
      "PutHomologacion",
      "DeleteHomologacion",
    ]),

    setup() {
      this.loading = true;
      this.GetHomologaciones(this.pagination)
        .then((response) => {
          const pagination = response.pagination;
          this.setPaginationFromResponse(pagination);
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loading = false));
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.PostHomologacion;
      const formData = {
        salida: form.sucursal,
        canal: form.canal?._id,
        cliente: "forum",
        homologacion: "canal",
        entrada: form.homologacion,
        descripcion: form.homologacionDescripcion,
      };
      if (this.selected) {
        method = this.PutHomologacion;
        formData.id = this.selected._id;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar Homologación",
          description: "¿Seguro de querer eliminar la homologación?",
        })
        .then((answer) => {
          if (answer) {
            this.DeleteHomologacion(item._id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

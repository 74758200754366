<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalPersonas
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :paises="paises"
    />

    <confirm-modal ref="confirm" />

    <v-data-table :search="search" v-bind="bind.table" v-on="on.table">
      <template v-slot:[`item.pais_id`]="{ item }">
        {{ getCountry(item.pais_id) }}
      </template>
      <template #item.actions="{ item }">
        <v-btn v-bind="bind.actions" v-on="on.edit(item)"
          ><v-icon>{{ icons.edit }}</v-icon></v-btn
        >
        <v-btn
          color="error"
          v-bind="bind.actions"
          v-on="on.delete(item)"
          :disabled="item.eliminado"
          ><v-icon>{{ icons.delete }}</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <!-- <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" /> -->
  </v-card>
</template>

<script>
/* import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue"; */
import ModalPersonas from "@/components/Modal/ModalPersonas.vue";

import SetPage from "@/Mixins/SetPageMixin.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "CrudPersonas",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        { text: "Descripción", value: "descripcion" },
        { text: "Codigo", value: "codigo" },
        { text: "País", value: "pais_id" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  components: {
    //TableMantenedor,
    ModalPersonas,
  },
  computed: {
    ...mapState("Personas", ["allData", "paises"]),

    items() {
      return this.allData;
    },
  },
  methods: {
    ...mapActions("Personas", [
      "getAllData",
      "saveNewData",
      "updateData",
      "deleteItemData",
      "getPaises",
    ]),

    setup() {
      this.loading = true;
      this.getPaises();
      this.getAllData()
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.saveNewData;

      const formData = {
        pais_id: form.pais,
        descripcion: form.descripcion,
        codigo: form.codigo.toUpperCase().replace(/ /g, "_"),
      };

      if (this.selected) {
        formData.id = this.selected._id;
        method = this.updateData;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar persona",
          decription: "¿Quiere eliminar esta persona?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteItemData(item._id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
    getCountry(code = "") {
      let pm;
      try {
        pm = this.paises.find((e) => e._id === code);
        if (pm) {
          pm = pm.pais_descripcion;
        } else {
          pm = code;
        }
      } catch {
        pm = code;
      }
      return pm;
    },
  },
};
</script>

<template>
  <ListBitacora :query="query" :headers="headers" />
</template>

<script>
import ListBitacora from "@/components/Bitacora/ListBitacora.vue";

export default {
  name: "CrudTarifasIntegradorApp",
  data() {
    return {
      query: "app=api-integrador",
      headers: [
        { text: "Fecha", sortable: true, value: "creadoEl", width: "11%" },
        { text: "App", value: "app" },
        { text: "Funcion", value: "funcion" },
        { text: "Cotización", value: "key.numeroCotizacion" },
        { text: "Compañía", value: "key.companiaDescripcion" },
        { text: "Producto", value: "key.nombreProducto" },
        { text: "Vigencia", value: "key.mesesVigencia" },
        { text: "Evento", value: "tipoEvento" },
        /* { text: 'Estado', value: 'eliminado' }, */
        { text: "", value: "acciones", align: "right" },
      ],
    };
  },
  components: { ListBitacora },
};
</script>

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.permisos;

export const getPermisos = async ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.permisos;
        commit("setPermisos", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de permisos.");
      });
  });
};

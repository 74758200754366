import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class ParamService {
  constructor() {
    this.PATH = endpoints.manager.parametros.base;
    this.HEADERS = bffHeaders;
  }

  static httpMethod(httpData = {}, messages = { success: "", error: "" }) {
    return new Promise((resolve, reject) => {
      axios(httpData)
        .then((response) => {
          const { data } = response;
          resolve({
            message: messages?.success ?? "",
            result: data,
          });
        })
        .catch((error) => {
          const errorData = error?.response?.data?.error ?? messages.error;
          reject(new Error(errorData));
        });
    });
  }

  get() {
    const http = {
      url: this.PATH,
      method: "GET",
      headers: this.HEADERS,
    };

    return ParamService.httpMethod(http, {
      error: "No se ha logrado recuperar los Parametros.",
    });
  }

  post(formData = {}) {
    const http = {
      url: this.PATH,
      method: "POST",
      data: formData,
      headers: this.HEADERS,
    };

    return ParamService.httpMethod(http, {
      success: "¡Se ha creado un nuevo Parametro satisfactoriamente!",
      error: "Ha ocurrido un error al intentar crear un Parametro.",
    });
  }

  put(formData = {}, itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "PUT",
      data: formData,
      headers: this.HEADERS,
    };

    return ParamService.httpMethod(http, {
      success: "¡Se ha actualizado un Parametro correctamente!",
      error: "Ha ocurrido un error al intentar actualizar un Parametro.",
    });
  }

  delete(itemId = "") {
    const http = {
      url: `${this.PATH}/${itemId}`,
      method: "DELETE",
      headers: this.HEADERS,
    };

    return ParamService.httpMethod(http, {
      success: "Se ha eliminado un Parametro del listado",
      error: "Ha ocurrido un error al intentar eliminar un Parametro.",
    });
  }
}

export default ParamService;

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders as headers } from "@/helpers/headers.js";

export const GetConvenios = () => {
  const url = endpoints.manager.convenios;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers,
    })
      .then((response) => {
        const result = response.data.data?.result ?? [];
        result.unshift({
          canal_descripcion: "Todos",
          codigo: "todos",
        });
        resolve(result);
      })
      .catch(() => {
        reject("Ha ocurrido un error al intentar recuperar los convenios.");
      });
  });
};

export const DescargarSimulacionForum = (data) => {
  const url = endpoints.manager.informes.simulacionesForum;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      data,
      url,
      // responseType: "blob",
      responseType: "arraybuffer",
      headers,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        const error = e.response.data;
        let errorText =
          "Ha ocurrido un error al intentar descargar la simulación.";
        if (error?.data?.lista_mensaje?.length > 0) {
          errorText = error.data.lista_mensaje.map((a) => a.mensaje).join(", ");
        }
        reject(errorText);
      });
  });
};

export const DescargarSimulacionMclick = (data) => {
  const url = endpoints.manager.informes.simulacionesMclick;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      data,
      url,
      // responseType: "blob",
      responseType: "arraybuffer",
      headers,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        const error = e.response.data;
        let errorText =
          "Ha ocurrido un error al intentar descargar la simulación.";
        if (error?.data?.lista_mensaje?.length > 0) {
          errorText = error.data.lista_mensaje.map((a) => a.mensaje).join(", ");
        }
        reject(errorText);
      });
  });
};

export const DescargarAvisoPrivacidad = (data) => {
  const url = endpoints.manager.informes.avisoPrivacidad;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      data,
      url,
      responseType: "arraybuffer",
      headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha generado el archivo de simulaciones satisfactoriamente!",
          result: response,
        });
      })
      .catch((e) => {
        const error = e.response.data;
        let errorText =
          "Ha ocurrido un error al intentar descargar la simulación.";
        if (error?.data?.lista_mensaje?.length > 0) {
          errorText = error.data.lista_mensaje.map((a) => a.mensaje).join(", ");
        }
        reject({ info: errorText, result: error });
      });
  });
};

import { TableData } from "@/Mixins/PageMixinData.js";

export const PaginationMixin = {
  data: () => ({
    pagination: {
      objects: 0,
      page: 1,
      pages: 1,
      limit: 10,
      search: "",
    },
    filters: {},
    filterMenu: false,
    table: TableData,
    loading: false,
  }),
  computed: {
    tableBind() {
      return {
        "items-per-page": this.pagination.limit,
        page: this.pagination.page,
        "server-items-length": this.pagination.objects,
      };
    },
    tableOn() {
      return {
        pagination: this.setPaginationFromTable,
      };
    },
    searchOn() {
      return {
        "click:append-outer": this.filter,
        "click:clear": this.resetSearch,
      };
    },
  },
  methods: {
    setup: () => null,
    setPaginationFromResponse(pagination = null) {
      if (pagination) {
        this.pagination.page = pagination?.page ?? 1;
        this.pagination.pages = pagination?.totalPages ?? 1;
        this.pagination.objects = pagination?.totalDocs ?? 0;
      }
    },
    setPaginationFromTable(pagination = null) {
      if (pagination) {
        this.pagination.limit = pagination?.itemsPerPage ?? 10;
        this.pagination.page = pagination?.page ?? 1;
        this.setup();
      }
    },
    setOrResetPagination(pagination = null) {
      if (pagination) {
        this.setPaginationFromResponse(pagination);
      } else {
        this.resetPagination();
      }
    },
    resetPagination() {
      this.pagination.page = 1;
      this.pagination.pages = 1;
      this.pagination.limit = 10;
      this.pagination.objects = 0;
    },
    filter() {
      this.pagination.page = 1;
      this.setup();
    },
    resetSearch() {
      this.search = "";
      this.filter();
    },
    resetFilters() {
      for (const i in this.filters) {
        this.filters[i] = "";
      }
      this.filter();
    },
  },
};

export default {
  PaginationMixin,
};

<template>
  <list-bitacora v-bind="{ headers, query, tableHeaderDivider: true }">
    <template #table-header-left-1>
      <v-select
        :items="items"
        v-model="selected"
        item-value="code"
        item-text="name"
        label="APP"
        placeholder="Seleccione una app de bitacora..."
        hide-details
      ></v-select>
    </template>
  </list-bitacora>
</template>

<script>
import TableHeaders from "@/views/Manager/bitacoras/bitacora.table.data.js";
import ListBitacora from "@/components/Bitacora/ListBitacora.vue";
export default {
  name: "BitacorasPage",
  data: () => ({
    selected: 1,
  }),
  components: {
    ListBitacora,
  },
  computed: {
    items: () => TableHeaders,
    app() {
      return this.items.find((e) => e.code === this.selected);
    },
    headers() {
      return this.app?.headers ?? [];
    },
    query() {
      return this.app?.api ?? "";
    },
  },
};
</script>

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import { endpoints } from "@/helpers/endpoints.js";

export default {
  namespaced: true,
  state: {
    items: [],
    endpoint: endpoints.cruds.restriction,
  },
  actions,
  mutations,
  getters,
};

import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.tipoInspecciones;

export const getAllData = ({ commit }) => {
  return new Promise((resolve, reject) => [
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setAllData", result);
        resolve({ result });
      })
      .catch(() => {
        reject(
          "No se ha logrado recuperar el listado de tipos de inspecciones."
        );
      }),
  ]);
};

export const saveNewData = async ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewData", result);
        resolve({
          result,
          info: "Se ha creado un nuevo tipo de inspeccion satisfactoriamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar guardar un tipo de inspeccion."
        );
      });
  });
};

export const updateData = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateData", result);
        resolve({
          result,
          info: "Se ha actualizado un tipo de inspeccion correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar actualizar un tipo de inspeccion."
        );
      });
  });
};

export const deleteItemData = async ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteItemData", id);
        resolve({
          info: "Se ha eliminado un tipo de inspeccion correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "No se ha logrado eliminar el elemento."
        );
      });
  });
};

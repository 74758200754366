import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";
import { endpoints } from "@/helpers/endpoints.js";
const url = endpoints.cruds.mediosPago,
  urlTipoPago = endpoints.cruds.tipoPago,
  urlTiposMediosPago = endpoints.cruds.tipoMedioPago;

export const getTiempoDePagos = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${urlTipoPago}/empresas`,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setTiempoDePagos", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de tiempos de pago.");
      });
  });
};

export const getTiposMediosPago = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: urlTiposMediosPago, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("setTiposMediosPago", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de tiempos de pago.");
      });
  });
};

export const getAllData = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setAllData", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de medios de pago.");
      });
  });
};

export const saveNewData = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data: payload, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data.result;
        commit("setNewData", result);
        resolve({
          result,
          info: "Se ha creado un medio de pago satisfactoriamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar crear un nuevo medio de pago."
        );
      });
  });
};

export const updateData = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setUpdateData", result);
        resolve({
          result,
          info: "Se ha actualizado un medio de pago correctamente!",
        });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar actualizar un nuevo medio de pago."
        );
      });
  });
};

export const deleteItemData = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${payload}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteItemData", payload);
        resolve({ info: "Se ha eliminado un medio de pago correctamente!" });
      })
      .catch((error) => {
        reject(
          error.response.data.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar eliminar un medio de pago."
        );
      });
  });
};

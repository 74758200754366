<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalTiposTarjetas
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :card-list="cardList"
    />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalTiposTarjetas from "@/components/Modal/ModalTiposTarjetas.vue";

import SetPage from "@/Mixins/SetPageMixin.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "CrudTiposTarjeta",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        { text: "Grupo", value: "grupo" },
        {
          text: "Tipo de Tarjeta",
          align: "start",
          value: "descripcion",
        },
        { text: "Codigo", value: "codigo" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  components: {
    TableMantenedor,
    ModalTiposTarjetas: ModalTiposTarjetas,
  },
  computed: {
    ...mapState("TiposTarjetas", ["tiposTarjetas"]),

    items() {
      return this.tiposTarjetas;
    },
    cardList() {
      return [
        { grupo: "Visa", codigo: "Visa" },
        { grupo: "Mastercard", codigo: "Mastercard" },
        { grupo: "American Express", codigo: "American Express" },
      ];
    },
  },
  methods: {
    ...mapActions("TiposTarjetas", [
      "getTiposTarjetas",
      "saveNewTipoTarjeta",
      "updateTipoTarjeta",
      "deleteTipoTarjeta",
    ]),

    setup() {
      this.loading = true;
      this.getTiposTarjetas()
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.saveNewTipoTarjeta;

      const formData = {
        grupo: form.grupo,
        descripcion: form.descripcion,
        codigo: form.codigo.toUpperCase().replace(/ /g, "_"),
      };

      if (this.selected) {
        formData.id = this.selected.id;
        method = this.updateTipoTarjeta;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar un tipo de tarjeta",
          decription: "¿Quiere eliminar este tipo de tarjeta?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteTipoTarjeta(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

export const setAllTasaCambio = (state, data) => {
  state.tasaCambio = data.tasaCambio;
  state.loadingDataTable = data.loadingDataTable;
  state.errorCargandoDataTable = data.errorCargandoDataTable;
};

export const setNewTasaCambio = (state, data) => {
  state.tasaCambio = [data, ...state.tasaCambio];
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setUpdateTasaCambio = (state, data) => {
  state.tasaCambio = state.tasaCambio.map((canal) =>
    canal.id === data.id ? data : canal
  );
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.errorModalNew = false;
};

export const setDeleteTasaCambio = (state, id) => {
  state.openModalEliminar = false;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = "";
  state.errorModalDelete = false;
  state.tasaCambio = state.tasaCambio.filter((canal) => canal.id !== id);
};

export const setDontSaveNewTasaCambio = (state, mensajeError) => {
  state.openModalNew = true;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = mensajeError;
  state.errorModalNew = true;
};

export const setDontDeleteTasaCambio = (state, mensajeError) => {
  state.openModalEliminar = true;
  state.loadButtonModalDelete = false;
  state.messageErrorModalDelete = mensajeError;
  state.errorModalDelete = true;
};

//MODAL NEW
export const setOpenModalNew = (state) => {
  state.openModalNew = true;
};

export const setCloseModalNew = (state, mensaje) => {
  state.openModalNew = false;
  state.loadButtonModalNew = false;
  state.messageErrorModalNew = "";
  state.messageModalNew = mensaje;
  state.errorModalNew = false;
};

//BUTTON LOADING MODAL NEW
export const setLoadButtonModalNew = (state) => {
  state.loadButtonModalNew = true;
};

//BUTTON LOADING MODAL DELETE
export const setLoadButtonModalDelete = (state) => {
  state.loadButtonModalDelete = true;
};

//MODAL DELETE
export const setOpenModalDelete = (state) => {
  state.openModalEliminar = true;
};

export const setCloseModalDelete = (state) => {
  state.openModalEliminar = false;
};

export const setItems = (state, payload) => (state.items = payload);

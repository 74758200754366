export default [
  {
    ref: "41",
    name: "Informes",
    icon: "list-box",
    items: [
      {
        ref: "41-1",
        name: "Simulaciones",
        path: "ManagerQuotDownload",
        icon: "application-brackets-outline",
        text: "Búsqueda de Simulaciones",
      },
      {
        ref: "41-2",
        name: "Políticas",
        path: "ManagerPoliciestDownload",
        icon: "shield-lock-outline",
        text: "Informe de aviso de privacidad",
      },
    ],
  },
  {
    ref: "46",
    name: "APP Configurador",
    icon: "text-long",
    items: [
      {
        ref: "46-1",
        name: "Territorios",
        path: "Territorios",
        icon: "earth-box",
        text: "Configurador de territorios internos y externos.",
      },
    ],
  },
  {
    ref: "42",
    name: "Notificaciones",
    icon: "list-box",
    items: [
      {
        ref: "42-1",
        name: "Plantillas",
        path: "NotificationsTemplates",
        icon: "file-export-outline",
        text: "Mantenedor de Plantillas",
      },
      {
        ref: "42-2",
        name: "Configuraciones",
        path: "NotificationsConfig",
        icon: "file-document-edit-outline",
        text: "Mantenedor de Configuraciones",
      },
      {
        ref: "42-3",
        name: "Bitacoras",
        path: "NotificationsBitacora",
        icon: "file-clock-outline",
        text: "Mantenedor de Bitacoras",
      },
      {
        ref: "42-4",
        name: "Clientes",
        path: "NotificationsClients",
        icon: "account-file-text-outline",
        text: "Mantenedor de Clientes",
      },
      {
        ref: "42-5",
        name: "Parametros",
        path: "NotificationsParams",
        icon: "apps",
        text: "Mantenedor de Parametros",
      },
    ],
  },
  {
    ref: "43",
    name: "AUTH",
    icon: "shield-key-outline",
    items: [
      {
        ref: "43-1",
        name: "Permisos",
        path: "AuthPermissions",
        icon: "table-key",
        text: "Mantenedor de Permisos",
      },
      {
        ref: "43-2",
        name: "Servicios",
        path: "AuthServices",
        icon: "toolbox-outline",
        text: "Mantenedor de Servicios",
      },
      {
        ref: "43-4",
        name: "Aplicaciones",
        path: "AuthApps",
        icon: "home-account",
        text: "Mantenedor de Aplicaciones",
      },
      {
        ref: "43-5",
        name: "Endpoints",
        path: "AuthEndpoints",
        icon: "link-box-outline",
        text: "Mantenedor de Endpoints",
      },
      {
        ref: "43-6",
        name: "Roles",
        path: "AuthRoles",
        icon: "card-account-details-outline",
        text: "Mantenedor de Roles",
      },
      {
        ref: "43-7",
        name: "Usuarios",
        path: "AuthUsers",
        icon: "account-group-outline",
        text: "Mantenedor de Usuarios",
      },
    ],
  },
  {
    ref: "44",
    name: "Bitacoras",
    icon: "text-long",
    items: [
      {
        ref: "44-0",
        name: "Bitacoras",
        path: "GeneralBitacora",
        icon: "post-outline",
        text: "Mantenedor General de Bitacoras",
      },
      /* {
        ref: "44-1",
        name: "Detalle",
        path: "BitacorasDetail",
        icon: "hand-coin-outline",
        text: "Mantenedor de Detalle",
      }, */
      // {
      //   ref: "44-2",
      //   name: "Tarifas",
      //   path: "BitacorasTarifas",
      //   icon: "hand-coin-outline",
      //   text: "Mantenedor de Tarifas",
      // },
      // {
      //   ref: "44-3",
      //   name: "Tarifas Integrador",
      //   path: "BitacorasTarifasIntegrador",
      //   icon: "hand-coin",
      //   text: "Mantenedor de Tarifas Integrador",
      // },
      // {
      //   ref: "44-4",
      //   name: "Tass",
      //   path: "BitacorasTass",
      //   icon: "database-check-outline",
      //   text: "Mantenedor de Tass",
      // },
      // {
      //   ref: "44-5",
      //   name: "API-Ventas",
      //   path: "BitacorasVentas",
      //   icon: "cash-check",
      //   text: "Mantenedor de API-Ventas",
      // },
      // {
      //   ref: "44-6",
      //   name: "API Forum",
      //   path: "BitacorasForum",
      //   icon: "car-multiple",
      //   text: "Mantenedor de API Forum",
      // },
      // {
      //   ref: "44-7",
      //   name: "Documentos",
      //   path: "BitacorasDocuments",
      //   icon: "text-box-multiple-outline",
      //   text: "Mantenedor de Documentos",
      // },
    ],
  },
  {
    ref: "45",
    name: "Consultas",
    icon: "text-long",
    items: [
      {
        ref: "45-0",
        name: "Simulaciones",
        path: "CotizacionesPropuestas",
        icon: "store-search-outline",
        text: "Ver toda la información de cotizaciones y propuestas",
      },
    ],
  },
];

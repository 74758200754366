import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class ClientService {
  constructor() {
    this.API = endpoints.manager.client;
    this.HEADER = bffHeaders;
  }

  client = async () =>
    new Promise((resolve, reject) => {
      axios({
        url: this.API,
        method: "GET",
        headers: this.HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  clientID = async (id) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.API}/${id}`,
        method: "GET",
        headers: this.HEADER,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  static httpMethod(data = {}, messages = { success: "", error: "" }) {
    return new Promise((resolve, reject) => {
      axios(data)
        .then((response) => {
          const { result } = response.data;
          resolve({
            message: messages?.success ?? "",
            result,
          });
        })
        .catch((error) => {
          const errorData = error?.response?.data?.error ?? messages.error;
          reject(new Error(errorData));
        });
    });
  }

  get() {
    const http = {
      url: this.API,
      method: "GET",
      headers: this.HEADER,
    };

    return ClientService.httpMethod(http, {
      error: "No se ha logrado recuperar el listado de los clientes.",
    });
  }

  post(formData = {}) {
    const http = {
      url: this.API,
      method: "POST",
      data: formData,
      headers: this.HEADER,
    };

    return ClientService.httpMethod(http, {
      success: "¡Se ha creado un nuevo cliente satisfactoriamente!",
      error: "Ha ocurrido un error al intentar crear un Cliente.",
    });
  }

  put(formData = {}, itemId = "") {
    const http = {
      url: `${this.API}/${itemId}`,
      method: "PUT",
      data: formData,
      headers: this.HEADER,
    };

    return ClientService.httpMethod(http, {
      success: "¡Se ha actualizado un cliente correctamente!",
      error: "Ha ocurrido un error al intentar actualizar un Cliente.",
    });
  }
}

export default ClientService;

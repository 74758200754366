import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

const url = endpoints.cruds.modulos;

export const getModulos = async ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("setModulos", result);
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de módulos.");
      });
  });
};

export const saveNewModulo = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data: payload, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("setNewModulo", result);
        resolve({
          result,
          info: "Se ha guardado el módulo satisfactoriamente!",
        });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado guardar el módulo.";
        reject(mensaje);
      });
  });
};

export const updateModulo = async ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      data: payload,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data;
        commit("setUpdateModulo", result);
        resolve({
          result,
          info: "Se ha actualizado el módulo correctamente!",
        });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado actualizar el módulo.";
        reject(mensaje);
      });
  });
};

export const deleteModulo = async ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then(() => {
        commit("setDeleteModulo", id);
        resolve({ info: "Se ha eliminado el módulo correctamente!" });
      })
      .catch((error) => {
        let mensaje =
          error.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
          "No se ha logrado eliminar el módulo.";
        reject(mensaje);
      });
  });
};

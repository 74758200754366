import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: "#2160df",
          darken4: "#223A65",
        },
        secondary: {
          base: "#DFE8F6",
          lighten1: "#F4F7FC",
        },
        info: {
          base: "#00c2ff",
        },
        background: "#F4F7FC",
      },
    },
  },
});

import Manager from "@/views/Manager/index.vue";
// INFORMES
import QuotView from "@/views/Manager/informes/QuotDownload.vue";
import PolicyView from "@/views/Manager/informes/PoliciesDownload.vue";
// NOTIFICACIONES
import TemplateView from "@/views/Manager/notificaciones/Plantillas.vue";
import ConfigView from "@/views/Manager/notificaciones/Configuraciones.vue";
import BitacoraView from "@/views/Manager/notificaciones/Bitacoras.vue";
import ClientsView from "@/views/Manager/notificaciones/Clientes.vue";
import ParamsView from "@/views/Manager/notificaciones/Parametros.vue";
//AUTH
import PermissionsView from "@/views/Manager/auth/Permisos.vue";
import ServicesView from "@/views/Manager/auth/Servicios.vue";
import AppsView from "@/views/Manager/auth/Apps.vue";
import EndpointsView from "@/views/Manager/auth/Endpoints.vue";
import RolesView from "@/views/Manager/auth/Roles.vue";
import UsersView from "@/views/Manager/auth/Users.vue";
//BITACORAS
//import DetailView from "@/views/Manager/bitacoras/Detalle.vue";
import DocumentsView from "@/views/Manager/bitacoras/Documentos.vue";
import ForumView from "@/views/Manager/bitacoras/Forum.vue";
import TarifasIntegradorView from "@/views/Manager/bitacoras/Tarifas-Integrador.vue";
import TarifasView from "@/views/Manager/bitacoras/Tarifas.vue";
import TassView from "@/views/Manager/bitacoras/Tass.vue";
import VentasView from "@/views/Manager/bitacoras/Ventas.vue";
import GeneralBitacoraView from "@/views/Manager/bitacoras/bitacoras.vue";
//CONSULTAS
import CotizacionesPropuestas from "@/views/Manager/consultas";
// APP CONFIGURADOR
import Territorios from "@/views/Manager/configurador/Territorios.vue";

export const routes = [
  {
    path: "manager",
    name: "ManagerMain",
    component: Manager,
    meta: {
      page: "400",
    },
  },
  // INFORMES VIEWS
  {
    path: "descargar-simulaciones",
    name: "ManagerQuotDownload",
    component: QuotView,
    meta: {
      page: "410",
    },
  },
  {
    path: "descargar-politicas",
    name: "ManagerPoliciestDownload",
    component: PolicyView,
    meta: {
      page: "411",
    },
  },
  // NOTIFICATIONS VIEWS
  {
    path: "notificaciones-plantillas",
    name: "NotificationsTemplates",
    component: TemplateView,
    meta: {
      page: "420",
    },
  },
  {
    path: "notificaciones-configuraciones",
    name: "NotificationsConfig",
    component: ConfigView,
    meta: {
      page: "421",
    },
  },
  {
    path: "notificaciones-bitacoras",
    name: "NotificationsBitacora",
    component: BitacoraView,
    meta: {
      page: "422",
    },
  },
  {
    path: "notificaciones-clientes",
    name: "NotificationsClients",
    component: ClientsView,
    meta: {
      page: "423",
    },
  },
  {
    path: "notificaciones-parametros",
    name: "NotificationsParams",
    component: ParamsView,
    meta: {
      page: "424",
    },
  },
  // AUTH VIEWS
  {
    path: "auth-permisos",
    name: "AuthPermissions",
    component: PermissionsView,
    meta: {
      page: "430",
    },
  },
  {
    path: "auth-servicios",
    name: "AuthServices",
    component: ServicesView,
    meta: {
      page: "431",
    },
  },
  {
    path: "auth-aplicaciones",
    name: "AuthApps",
    component: AppsView,
    meta: {
      page: "433",
    },
  },
  {
    path: "auth-endpoints",
    name: "AuthEndpoints",
    component: EndpointsView,
    meta: {
      page: "434",
    },
  },
  {
    path: "auth-roles",
    name: "AuthRoles",
    component: RolesView,
    meta: {
      page: "435",
    },
  },
  {
    path: "auth-usuarios",
    name: "AuthUsers",
    component: UsersView,
    meta: {
      page: "436",
    },
  },
  //BITACORA VIEWS
  /* {
    path: "bitacoras-detalle",
    name: "BitacorasDetail",
    component: DetailView,
    meta: {
      page: "440",
    },
  }, */
  {
    path: "bitacoras-documentos",
    name: "BitacorasDocuments",
    component: DocumentsView,
    meta: {
      page: "440",
    },
  },
  {
    path: "bitacoras-forum",
    name: "BitacorasForum",
    component: ForumView,
    meta: {
      page: "441",
    },
  },
  {
    path: "bitacoras-tarifas-integrador",
    name: "BitacorasTarifasIntegrador",
    component: TarifasIntegradorView,
    meta: {
      page: "442",
    },
  },
  {
    path: "bitacoras-tarifas",
    name: "BitacorasTarifas",
    component: TarifasView,
    meta: {
      page: "443",
    },
  },
  {
    path: "bitacoras-tass",
    name: "BitacorasTass",
    component: TassView,
    meta: {
      page: "444",
    },
  },
  {
    path: "bitacoras-ventas",
    name: "BitacorasVentas",
    component: VentasView,
    meta: {
      page: "445",
    },
  },
  {
    path: "bitacoras",
    name: "GeneralBitacora",
    component: GeneralBitacoraView,
    meta: {
      page: "449",
    },
  },
  //CONSULTAS
  {
    path: "cotizaciones-propuestas",
    name: "CotizacionesPropuestas",
    component: CotizacionesPropuestas,
    meta: {
      page: "450",
    },
  },
  {
    path: "territorios",
    name: "Territorios",
    component: Territorios,
    meta: {
      page: "451",
    },
  },
];

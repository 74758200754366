<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <Modal
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :tiempo-pago-items="tiempoDePagos"
      :payments="payments"
    />

    <confirm-modal ref="confirm" />

    <v-data-table v-bind="bind.table" :search="search">
      <template v-slot:[`item.codigo`]="{ item }">
        {{ getPaymentType(item.codigo) }}
      </template>
      <template v-slot:[`item.tiempo_pago`]="{ item }">
        <v-chip label outlined color="primary">
          {{
            (item.tiempo_pago
              ? item.tiempo_pago.descripcion || item.tiempo_pago
              : "Desconocido") ||
            item.tiempo_pago ||
            "Desconocido"
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.tipo_pago`]="{ item }">
        {{ item.tipo_pago?.descripcion }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" text fab x-small @click="openForm(item)">
          <v-icon small>{{ icons.edit }}</v-icon>
        </v-btn>
        <v-btn color="error" text fab x-small @click="deleteItem(item)">
          <v-icon small>{{ icons.delete }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Modal from "@/components/Modal/ModalMediosDepago.vue";
import { mapState, mapActions } from "vuex";

import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudMediosPago",
  mixins: [SetPage],
  data() {
    return {
      payments: [],
      headers: [
        {
          text: "Descripción",
          align: "start",
          value: "medio_descripcion",
        },
        { text: "Código", value: "codigo" },
        { text: "Tiempo", value: "tiempo_pago" },
        { text: "Tipo", value: "tipo_pago" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  components: {
    Modal,
  },
  computed: {
    ...mapState("MediosDePago", ["allData", "tiempoDePagos"]),
    items() {
      return this.allData;
    },
  },
  methods: {
    ...mapActions("MediosDePago", [
      "getAllData",
      "getTiempoDePagos",
      "getTiposMediosPago",
      "saveNewData",
      "updateData",
      "deleteItemData",
    ]),
    async setup() {
      this.loading = true;
      this.getTiempoDePagos();
      this.getTiposMediosPago().then(
        (res) =>
          (this.payments = res.sort((a, b) =>
            a.descripcion.localeCompare(b.descripcion)
          ))
      );
      await this.getAllData();
      this.loading = false;
    },

    saveItem(formData) {
      this.loadingForm = true;
      let payload = {
        codigo: formData.codigo.toUpperCase().replace(/ /g, "_"),
        medio_descripcion: formData.descripcion,
        tiempo_pago: formData.tiempo,
        tipo_pago: formData.tipo,
        recordable: formData.recordable,
      };
      let method = this.saveNewData;

      if (this.selected) {
        payload.id = this.selected.id;
        method = this.updateData;
      }

      payload.tiempo_pago =
        this.tiempoDePagos?.find((e) => e.codigo == formData.tiempo) ??
        formData.tiempo;

      payload.tipo_pago = this.payments.find((e) => e.valor == formData.tipo);
      if (payload.tipo_pago)
        payload.tipo_pago = {
          id_tipo_pago: payload.tipo_pago._id,
          dominio: payload.tipo_pago.dominio,
          descripcion: payload.tipo_pago.descripcion,
          codigo: payload.tipo_pago.valor,
        };

      method(payload)
        .then((response) => {
          this.closeForm();
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar medio de pago?",
          description: "Esta a punto de eliminar este medio de pago",
        })
        .then((answer) => {
          if (answer) {
            this.deleteItemData(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },

    getPaymentType(code = "") {
      let pm;
      try {
        pm = this.payments.find((e) => e.value === code);
        if (pm) {
          pm = pm.text;
        } else {
          pm = code;
        }
      } catch {
        pm = code;
      }
      return pm;
    },
  },
};
</script>

<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>

      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">
        {{ labels.new }}
      </v-btn>
    </v-card-title>

    <Modal
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :inspection-list="tiposInspeccion"
    />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import Modal from "@/components/Modal/ModalInspeccionTipos.vue";
import { mapState, mapActions } from "vuex";

import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudTiposInspecciones",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        {
          text: "Inspección",
          align: "start",
          filterable: true,
          sortable: true,
          value: "descripcion",
        },
        {
          text: "Tipo",
          align: "start",
          filterable: true,
          sortable: true,
          value: "tipo_inspeccion",
        },
        { text: "Codigo", value: "codigo", filterable: true, sortable: true },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  components: {
    TableMantenedor,
    Modal,
  },
  computed: {
    ...mapState("InspeccionesTipos", ["allData"]),

    items() {
      return this.allData;
    },
    tiposInspeccion() {
      return [
        {
          label: "Auto-inspección",
          value: "AUTO",
        },
        {
          label: "Inspección Local",
          value: "LOCAL",
        },
        {
          label: "Inspección a Domicilio",
          value: "DOMICILIO",
        },
      ];
    },
  },
  methods: {
    ...mapActions("InspeccionesTipos", [
      "getAllData",
      "saveNewData",
      "updateData",
      "deleteItemData",
    ]),

    setup() {
      this.loading = true;
      this.getAllData()
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveItem(form) {
      this.loadingForm = true;
      let method = this.saveNewData;
      if (this.selected) {
        method = this.updateData;
        form.id = this.selected.id;
      }
      method(form)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar tipo de inspección",
          description: "¿Desea eliminar este tipo de inspección?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteItemData(item.id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

export const messagesKeys = {
  code: "codigo_accion",
  list: "lista_mensaje",
  message: "mensaje",
};

export const paginationKeys = {
  offset: "desde",
  current: "pagina",
  pages: "paginas",
  objects: "total",
  limit: "limite",
  filter: "filtro",
};

export default {
  messagesKeys,
};

<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalTiposDocumentos
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :paises="paises"
    />

    <confirm-modal ref="confirm" />

    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalTiposDocumentos from "@/components/Modal/ModalTiposDocumentos.vue";

import SetPage from "@/Mixins/SetPageMixin.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "CrudTiposDocumentos",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        { text: "Código País", value: "pais_codigo" },
        {
          text: "Tipo de Documento",
          align: "start",
          value: "descripcion",
        },
        { text: "Código", value: "codigo" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  components: {
    TableMantenedor,
    ModalTiposDocumentos: ModalTiposDocumentos,
  },
  computed: {
    ...mapState("TiposDocumentos", ["tiposDocumentos", "paises"]),
    items() {
      return this.tiposDocumentos;
    },
  },
  methods: {
    ...mapActions("TiposDocumentos", [
      "getTiposDocumentos",
      "saveNewTipoDocumento",
      "updateTipoDocumento",
      "deleteTipoDocumento",
      "getPaises",
    ]),

    setup() {
      this.loading = true;
      this.getPaises();
      this.getTiposDocumentos()
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.saveNewTipoDocumento;

      const formData = {
        pais_id: form.pais_id,
        descripcion: form.descripcion,
        codigo: form.codigo.toUpperCase().replace(/ /g, "_"),
        orden: form.orden,
      };

      if (this.selected) {
        formData.id = this.selected._id;
        method = this.updateTipoDocumento;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar un tipo de documento",
          decription: "¿Quiere eliminar este tipo de documento?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteTipoDocumento(item._id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

// Mutaciones de los Canales
export const SetChannels = (state, payload) => {
  state.channels = payload;
};

export const SetChannelOrder = (state, payload = true) => {
  const keys = state.keys.base;
  state.channels = state.channels.sort((a, b) => {
    const caseA = a[keys.description]?.toUpperCase(),
      caseB = b[keys.description]?.toUpperCase();
    if (caseA < caseB) {
      return payload ? -1 : 1;
    }
    if (caseA > caseB) {
      return payload ? 1 : -1;
    }
    return 0;
  });
};

// Mutacion de las Url de acceso
export const setAccessUrl = (state, payload) => {
  state.accessUrls = payload;
};
// Mutaciones de la informacion general del canal
export const SetGeneralInfo = (state, payload) => {
  state.generalInfo = payload;
};

export const SetCountries = (state, payload) => {
  state.generalInfo.countries = payload;
};

export const SetChannelsType = (state, payload) => {
  state.generalInfo.channelsType = payload;
};

export const SetCompanies = (state, payload) => {
  state.generalInfo.companies = payload;
};

export const ResetCompanies = (state) => {
  state.generalInfo.companies = [];
};

export const SetPaymentMethods = (state, payload) => {
  const keys = state.keys.generalInfo.paymentMethod.data;
  payload.forEach((e) => {
    var switchValue = e[keys.mainKey][keys.code] || e[keys.mainKey];
    switch (switchValue) {
      case keys.free:
        e[keys.unlimitedRate] = "1";
        break;
      case keys.bannual:
        e[keys.annualRate] = "1";
        e[keys.bannualRate] = "1";
        break;
      default:
        break;
    }
  });
  state.generalInfo.paymentMethods = payload;
};

export const UpdatePaymentMethods = (state, payload) => {
  const keys = state.keys.generalInfo.paymentMethod;
  for (var i in state.generalInfo.paymentMethods) {
    let item = payload.find(
      (e) => state.generalInfo.paymentMethods[i][keys.value] === e[keys.value]
    );
    if (item) {
      delete item[state.keys.generalInfo.id]; // Quitar el id (Opcional)
      state.generalInfo.paymentMethods[i] = item;
    }
  }
};

// == Mutaciones de la informacion avanzada del canal ==
export const SetAdvanceInfo = (state, payload) => {
  state.advanceInfo = payload;
};

export const SetSignatureType = (state, payload) => {
  state.advanceInfo.signature = payload;
};

export const SetInspections = (state, payload) => {
  state.advanceInfo.inspections = payload;
};

export const SetInspectionsBusiness = (state, payload) => {
  state.advanceInfo.inspections.business = payload;
};

export const SetInspectionsType = (state, payload) => {
  state.advanceInfo.inspections.types = payload;
};

// == Mutaciones de la configuracion avanzada del canal ==
export const SetAdvanceConfig = (state, payload) => {
  state.advanceConfig = payload;
};

// == Mutaciones de los productos del canal ==
export const SetProducts = (state, payload) => {
  state.products = payload;
};

export const ResetProducts = (state) => {
  state.products = [];
};
// == Mutaciones de los usuarios del canal ==
export const SetUsers = (state, payload) => {
  state.users.list = payload;
};

import { homeBreadCrumb, managerIndexBreadCrumb } from "./breadcrumbs.js";

export const Manager = [
  // ManagerClick
  {
    ref: "400",
    title: "Gestor de los Módulos del ManagerClick",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Módulos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "410",
    title: "Informe Simulaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Simulaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "411",
    title: "Informe aviso de privacidad",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Aviso de privacidad",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "420",
    title: "Notificaciones - Plantillas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Plantillas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "421",
    title: "Notificaciones - Configuraciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Configuraciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "422",
    title: "Notificaciones - Bitacoras",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Bitacora",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "423",
    title: "Notificaciones - Clientes",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Clientes",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "424",
    title: "Notificaciones - Parametros",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Parametros",
        disabled: true,
        href: "#",
      },
    ],
  },
  // Auth pages
  {
    ref: "430",
    title: "AUTH - Permisos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Permisos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "431",
    title: "AUTH - Servicios",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Servicios",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "433",
    title: "AUTH - Aplicaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Aplicaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "434",
    title: "AUTH - Endpoints",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Endpoints",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "435",
    title: "AUTH - Roles",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Roles",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "436",
    title: "AUTH - Usuarios",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Usuarios",
        disabled: true,
        href: "#",
      },
    ],
  },
  //BITACORAS
  {
    ref: "440",
    title: "Bitacoras - Documentos",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Documentos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "441",
    title: "Bitacoras - API Forum",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "API Forum",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "442",
    title: "Bitacoras - Tarifas Integrador",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Tarifas Integrador",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "443",
    title: "Bitacoras - Tarifas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Tarifas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "444",
    title: "Bitacoras - Tass",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Tass",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "445",
    title: "Bitacoras - API Ventas",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "API Ventas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "449",
    title: "Mantenedor General de Bitacoras",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Bitacoras",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "450",
    title: "Visualizador de Simulaciones",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Cotizaciones y Propuestas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "451",
    title: "Configurador de territorios",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      managerIndexBreadCrumb,
      {
        text: "Territorios",
        disabled: true,
        href: "#",
      },
    ],
  },
];

export default Manager;

<template>
  <div class="text-center">
    <v-dialog
      width="500"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario de inspección de empresas
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            fab
            text
            x-small
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre de la empresa"
                  :rules="[(v) => !!v || 'El campo Nombre es requerido.']"
                  v-model="form.descripcion"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Plazo Máximo Inspección"
                  type="number"
                  :rules="[(v) => !!v || 'El campo plazo máximo es requerido.']"
                  v-model="form.plazo_maximo"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="form.es_gestionable"
                  :true-value="true"
                  :false-value="false"
                  label="Es Gestionable"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Url Servicio"
                  :rules="[(v) => !!v || 'El campo url es requerido.']"
                  v-model="form.url_servicio"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Código"
                  v-model="form.codigo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :loading="loading" @click="submit">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "FormInspeccionEmpresa",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        descripcion: "",
        plazo_maximo: 0,
        es_gestionable: false,
        url_servicio: "",
        codigo: "",
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  computed: {
    formRef() {
      return this.$refs.form;
    },
  },
  methods: {
    submit() {
      if (this.formRef?.validate()) {
        this.$emit("submit", this.form);
      }
    },
    setForm() {
      this.form.descripcion = this.formData.descripcion;
      this.form.plazo_maximo = this.formData.plazo_maximo_inspeccion;
      this.form.es_gestionable = this.formData.es_gestionable;
      this.form.url_servicio = this.formData.url_servicio;
      this.form.codigo = this.formData.codigo;
    },
    reset() {
      this.form.descripcion = "";
      this.form.plazo_maximo = 0;
      this.form.es_gestionable = false;
      this.form.url_servicio = "";
      this.form.codigo = "";
      this.formRef.resetValidation();
    },
  },
};
</script>

<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <!-- CAMPO FECHA DESDE -->
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.fecha_desde"
                        label="Fecha desde..."
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'Debe seleccionar una fecha de inicio',
                        ]"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.fecha_desde"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- CAMPO FECHA HASTA -->
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.fecha_hasta"
                        label="Fecha hasta..."
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="[
                          (v) => !!v || 'Debe seleccionar una fecha de fin',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.fecha_hasta"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Convenio"
                    v-model="form.convenio"
                    :loading="loadingConvenios"
                    :items="convenios"
                    item-text="canal_descripcion"
                    item-value="codigo"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loading" @click="submit()" text
              ><v-icon left>mdi-download</v-icon> Descargar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageMixin from "@/Mixins/SetPageMixin.js";
import {
  GetConvenios,
  DescargarAvisoPrivacidad,
} from "@/services/manager/informes.service.js";
export default {
  name: "QuotDownloadPage",
  mixins: [PageMixin],
  data: () => ({
    menu: false,
    menu2: false,
    loading: false,
    loadingConvenios: false,
    convenios: [],
    form: {
      fecha_desde: "",
      fecha_hasta: "",
      convenio: "todos",
    },
  }),
  created() {
    this.setConvenios();
  },
  methods: {
    setConvenios() {
      this.loadingConvenios = true;
      GetConvenios()
        .then((response) => {
          this.convenios = response;
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingConvenios = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        DescargarAvisoPrivacidad(this.form)
          .then((response) => {
            this.downloadFromResponse(response.result)
              .then(() => {
                this.snackbar(response.info, "success");
              })
              .catch((e) => {
                throw { info: e };
              });
          })
          .catch((error) => {
            this.snackbar(error.info);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    downloadFromResponse(response) {
      let fileName = `aviso_privacidad_${this.form.fecha_desde}-${this.form.fecha_hasta}.xlsx`;
      return new Promise((resolve, reject) => {
        try {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Crea un enlace para la descarga
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Añade el enlace al cuerpo y haz clic en él
          document.body.appendChild(link);
          link.click();

          // Limpia después de la descarga
          document.body.removeChild(link);
          resolve();
        } catch {
          reject("No se ha logrado leer el archivo desde el servidor.");
        }
      });
    },
  },
};
</script>

<template>
  <v-data-table
    :loading="loading"
    :loading-text="loadingText"
    :items="items"
    :headers="headers"
    :search="search"
    :footer-props="footerProps"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn color="primary" text fab x-small @click="editItem(item)">
        <v-icon> {{ icons.edit }} </v-icon>
      </v-btn>
      <v-btn color="error" fab text x-small @click="deleteItem(item)">
        <v-icon> {{ icons.delete }} </v-icon>
      </v-btn>
    </template>

    <template #item.activate="{ item }">
      <v-switch
        :input-value="item?.[activateKey] ?? 'activo'"
        :true-value="true"
        :false-value="false"
        dense
        inset
        color="primary"
        :hint="item?.[activateKey] ? 'Activo' : 'Inactivo'"
        persistent-hint
        :loading="item.loading"
        @change="$emit('activate', { e: $event, item })"
      ></v-switch>
    </template>
  </v-data-table>
</template>

<script>
import { Icons } from "@/Mixins/PageMixinData.js";
export default {
  name: "TableMantenedor",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    footerProps: {
      type: Object,
      default: () => {},
    },
    loadingText: {
      type: String,
      default: "Cargando...",
    },
    activateKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    icons: () => Icons,
  },
  methods: {
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
  },
};
</script>

<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
      <v-divider v-bind="bind.divider"></v-divider>
      <v-spacer></v-spacer>
      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>
      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalRoles
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :modulos="modulos"
      :permisos="permisos"
    />
    <confirm-modal ref="confirm" />
    <TableMantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalRoles from "@/components/Modal/ModalRoles.vue";
import { mapState, mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  mixins: [PageMixin],
  components: { TableMantenedor, ModalRoles },
  props: ["modulos", "permisos"],
  data() {
    return {
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Slug", value: "slug" },
        { text: "Activo", value: "es_activo" },
        { text: "", align: "right", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("Roles", ["roles"]),

    items() {
      return this.roles;
    },
  },
  methods: {
    ...mapActions("Roles", [
      "getRoles",
      "saveNewRol",
      "updateRol",
      "deleteRol",
    ]),

    setup() {
      this.loading = true;
      this.getRoles().finally(() => {
        this.loading = false;
      });
    },

    saveItem(formData) {
      this.loadingForm = true;
      let method = this.saveNewRol;
      let payload = {
        nombre: formData.nombre,
        slug: formData.slug,
        esActivo: formData.esActivo,
        modulos: formData.modulos.map((modulo) => {
          return {
            idModulo: modulo.id_modulo,
            permisos: modulo.permisos,
          };
        }),
      };
      if (this.selected) {
        payload.id = this.selected._id;
        method = this.updateRol;
      }
      method(payload)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
          this.closeForm();
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirm
        .show({
          title: "Eliminar el rol",
          description: "Proceder a eliminar el rol?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteRol(item._id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((e) => {
                this.snackbar(e);
              });
          }
        });
    },
  },
};
</script>

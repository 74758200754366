<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        v-bind="bind.search"
        placeholder="Buscar una tasa de cambio...."
      ></v-text-field>

      <v-divider v-bind="bind.divider"></v-divider>

      <v-spacer></v-spacer>

      <v-btn v-bind="bind.refresh" v-on="on.refresh">
        <v-icon>{{ icons.refresh }}</v-icon>
      </v-btn>

      <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
    </v-card-title>

    <ModalTasaCambio
      v-model="dialog"
      v-on="on.form"
      v-bind="bind.form"
      :money-items="monedas"
    />

    <confirm-modal ref="confirmDialog" />

    <table-mantenedor :search="search" v-bind="bind.table" v-on="on.table" />
  </v-card>
</template>

<script>
import TableMantenedor from "@/components/TableMantenedores/TableMantenedor.vue";
import ModalTasaCambio from "@/components/Modal/ModalTasaCambio.vue";
import { mapActions } from "vuex";

import SetPage from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudTasaCambio",
  mixins: [SetPage],
  data() {
    return {
      headers: [
        {
          text: "Moneda Origen",
          align: "start",
          value: "moneda_origen_descripcion",
        },
        {
          text: "Moneda Destino",
          align: "start",
          value: "moneda_destino_descripcion",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Valor", value: "valor" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      tableItems: [],
      monedas: [],
    };
  },

  components: {
    TableMantenedor,
    ModalTasaCambio: ModalTasaCambio,
  },
  computed: {
    items() {
      return this.tableItems;
    },
  },

  methods: {
    ...mapActions("TasaCambio", ["GET", "POST", "PUT", "DELETE", "getMoneda"]),

    async setup() {
      this.loading = true;
      await this.getMoneda().then((data) => (this.monedas = data));
      this.getItems();
    },
    getItems() {
      this.loading = true;
      //Establecer parametros
      this.GET(this.pagination)
        .then((response) => {
          const result = response.result;
          result.forEach((e) => {
            e.moneda_destino_descripcion =
              this.monedas.find((n) => n.codigo === e.codigo_moneda_destino)
                ?.moneda_descripcion ??
              e.codigo_moneda_destino ??
              "Desconocido";
            e.moneda_origen_descripcion =
              this.monedas.find((n) => n.codigo === e.codigo_moneda_origen)
                ?.moneda_descripcion ??
              e.codigo_moneda_origen ??
              "Desconocido";
          });
          this.tableItems = response.result;
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loading = false));
    },

    saveItem(form) {
      this.loadingForm = true;
      let method = this.POST;
      let payload = {
        codigo_moneda_origen: !form?.monedaOrigen?.codigo
          ? form.monedaOrigen
          : form.monedaOrigen.codigo,
        codigo_moneda_destino: !form?.monedaDestino.codigo
          ? form.monedaDestino
          : form?.monedaDestino.codigo,
        fecha: form.fecha,
        valor: form.valor,
      };
      if (this.selected) {
        method = this.PUT;
        payload.id = this.selected._id;
      }
      method(payload)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.$refs.confirmDialog
        .show({
          title: "Eliminar una Tasa de cambio",
          description: "¿Esta seguro de querer eliminar esta Tasa de cambio?",
        })
        .then((result) => {
          if (result) {
            this.DELETE(item._id)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((error) => {
                this.snackbar(error);
              });
          }
        });
    },
  },
};
</script>

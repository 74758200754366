import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import * as messages from "@/assets/lang/es/messages.json";
import { baseKeys } from "@/store/resources/customizationkeys";

export default {
  namespaced: true,
  state: {
    list: [],
    templates: [],
    colors: [],
    logo: "",
    domain: "",
    messages: messages.data.customization,
    keys: {
      base: baseKeys,
      screensDKeys: null,
    },
    screens: null,
    sender: null,
  },
  actions,
  mutations,
  getters,
};

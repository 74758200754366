import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
const url = endpoints.cruds.personas;
const urlPaises = endpoints.general.countries;
import { bffHeaders } from "@/helpers/headers.js";

export const getAllData = ({ commit }) => {
  return new Promise((resolve, reject) => [
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("setAllData", result);
        resolve({ result });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "No se ha logrado recuperar el listado de personas."
        );
      }),
  ]);
};

export const saveNewData = async (context, data) => {
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, headers: bffHeaders, data })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: result.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "Ha ocurrido un error al intentar guardar una persona."
        );
      });
  });
};

export const updateData = async (context, payload) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${url}/${payload.id}`,
      headers: bffHeaders,
      data: {
        descripcion: payload.descripcion,
        codigo: payload.codigo,
        pais_id: payload.pais_id,
      },
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: result.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "Ha ocurrido un error al intentar actualizar una persona."
        );
      });
  });
};

export const deleteItemData = async (context, id) => {
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: `${url}/${id}`, headers: bffHeaders })
      .then((res) => {
        resolve({
          info: res.data?.data?.lista_mensaje?.[0]?.mensaje,
        });
      })
      .catch((error) => {
        reject(
          error.response.data?.message ??
            "No se ha logrado eliminar el elemento."
        );
      });
  });
};
//e.pais_descripcion.charAt(0).toUpperCase() + e.pais_descripcion.slice(1).toLowerCase()
export const getPaises = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: urlPaises, headers: bffHeaders })
      .then((response) => {
        const result = response?.data?.data?.data?.sort((a, b) =>
          a.pais_descripcion.localeCompare(b.pais_descripcion)
        );
        commit(
          "setPaises",
          result.map((e) => {
            return {
              codigo: e.codigo,
              moneda_destino: e.moneda_destino,
              valor_impuesto: e.valor_impuesto,
              _id: e._id,
              pais_descripcion:
                e.pais_descripcion.charAt(0).toUpperCase() +
                e.pais_descripcion.slice(1).toLowerCase(),
            };
          })
        );
        resolve(result);
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de paises.");
      });
  });
};

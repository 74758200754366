<template>
  <div>
    <v-card>
      <div class="d-flex align-center pa-3">
        <v-text-field
          v-model="search"
          dense
          label="Buscar..."
          hide-details
          class="align-self-end"
          append-icon="mdi-magnify"
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-btn
          color="primary"
          outlined
          fab
          small
          class="mr-3"
          @click="setup()"
          :loading="loading"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" @click="dialog = true"> Nuevo </v-btn>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'mostar',
          'items-per-page-options': [5, 10, 25, 50],
        }"
      >
        <template #item.activo="{ item }">
          <v-switch
            :input-value="item.activo"
            :true-value="true"
            :false-value="false"
            dense
            inset
            color="primary"
            :hint="item.activo ? 'Activo' : 'Inactivo'"
            persistent-hint
            :loading="item.loading"
            @change="activateItem($event, item)"
          ></v-switch>
        </template>

        <template #item.createdAt="{ item }">
          {{ item.createdAt | formatoFecha }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="handlerSelectItem(item)"
          >
            <v-icon small> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      @click:outside="handlerCloseDialog()"
      @keydown.esc="handlerCloseDialog()"
    >
      <v-card>
        <v-card-title primary-title>
          Formulario de Clientes <v-spacer></v-spacer>
          <v-btn color="primary" text fab x-small @click="handlerCloseDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="clientForm">
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre del Cliente"
                  :rules="[(val) => !!val || 'El campo Nombre es obligatorio.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.clientId"
                  label="Código"
                  :rules="[(val) => !!val || 'El campo Código es obligatorio.']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="formLoading"
            @click="handlerActionForm()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ClientService from "@/services/manager/notifications/client.service.js";
import SetPageMixin from "@/Mixins/SetPageMixin.js";
import dayjs from "dayjs";

const Service = new ClientService();

export default {
  mixins: [SetPageMixin],
  name: "CrudClientes",
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      title: "Listado de Clientes",
      tableItems: [],
      headers: [
        {
          value: "nombre",
          sortable: true,
          text: "Nombre",
        },
        {
          value: "clientId",
          sortable: false,
          text: "Código",
        },
        {
          value: "createdAt",
          sortable: true,
          text: "Fech. Creación",
        },
        {
          value: "activo",
          sortable: true,
          text: "Estado",
        },
        {
          value: "actions",
          sortable: false,
          align: "right",
          text: "",
        },
      ],
      loading: false,
      search: "",
      dialog: false,
      selected: null,
      form: {
        nombre: "",
        clientId: "",
        activo: true,
      },
      formLoading: false,
    };
  },
  created() {
    this.setup();
  },
  computed: {
    items() {
      return this.tableItems;
    },
  },
  methods: {
    setup() {
      this.loading = true;
      Service.get()
        .then((response) => {
          this.tableItems = response.result;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlerCloseDialog() {
      this.resetForm();
      this.dialog = false;
    },

    handlerActionForm() {
      const isValid = this.$refs.clientForm.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },

    handlerSelectItem(item) {
      this.selected = item;
      this.form.nombre = item.nombre;
      this.form.clientId = item.clientId;
      this.form.activo = item.activo;

      this.dialog = true;
    },

    save() {
      this.formLoading = true;
      Service.post(this.form)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.setup();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    update() {
      const idKey = "_id";
      this.formLoading = true;
      Service.put(this.form, this.selected?.[idKey] ?? "")
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: "success",
          });
          this.setup();
          this.handlerCloseDialog();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    activateItem(value, item) {
      item.loading = true;
      const idKey = "_id";
      const form = {
        activo: value,
        nombre: item.nombre,
        clientId: item.clientId,
      };
      Service.put(form, item?.[idKey] ?? "")
        .then(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: value
              ? "El cliente ha sido reactivado"
              : "Se ha desactivado el cliente",
            top: true,
            right: true,
            color: value ? "success" : "primary",
          });
          this.setup();
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          item.loading = false;
        });
    },

    resetForm() {
      this.selected = null;
      this.form.nombre = "";
      this.form.clientId = "";
      this.form.activo = true;
      this.$refs.clientForm.resetValidation();
    },
  },
};
</script>

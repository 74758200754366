export const userPosition = {
  default: "Sin cargo",
  1: "Administrador",
  2: "Supervisor",
  3: "Vendedor",
};

export const userState = {
  default: {
    text: "Desconocido",
    color: "grey",
    icon: "help",
  },
  A: {
    text: "Aprobado",
    color: "success",
    icon: "check",
  },
  REC: {
    text: "Rechazado",
    color: "error",
    icon: "close",
  },
  PEN: {
    text: "Pendiente",
    color: "warning",
    icon: "check",
  },
};

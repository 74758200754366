import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

export const GetConcesionariosTabla = ({ commit, getters }, query = {}) => {
  const params = {
    limit: query.limit,
    page: query.page,
    nombre_concesionario: query?.concesionario,
    num_doc_concesionario: query?.rutConcesionario,
    num_doc_vendedor: query?.rutVendedor,
    nombre_sucursal: query?.sucursal,
  };
  let path = `${getters.GetEndpoint}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      params,
    })
      .then((response) => {
        const result = response?.data?.data?.concesionarios;
        commit("setItems", result.data);
        resolve(result);
      })
      .catch((e) => reject(e.response.data));
  });
};

export const PostConcesionario = ({ getters }, form) => {
  const path = getters.GetEndpointConcesionarios;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: path,
      headers: bffHeaders,
      data: form,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha guardado el concesionario satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};
// PUT
export const PutConcesionario = ({ getters }, form) => {
  console.log(form);
  const path = `${getters.GetEndpointConcesionarios}/${form.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: path,
      headers: bffHeaders,
      data: {
        codigo: form.codigo,
        concesionario_descripcion: form.concesionario_descripcion,
        eliminado: form.eliminado,
        nombre: form.nombre,
        numero_documento: form.numero_documento,
        razon_social: form.razon_social,
      },
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha actualizado el concesionario satisfactoriamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};

export const DeleteConcesionario = ({ getters }, id) => {
  const path = `${getters.GetEndpointConcesionarios}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha eliminado el concesionario correctamente";
        resolve({ message, result });
      })
      .catch((e) => reject(e.response.data));
  });
};

export const GetConcesionarios = ({ getters }, query = {}) => {
  const params = {
    limit: query.limit,
    page: query.page,
    search: query.search,
  };
  let path = `${getters.GetEndpointConcesionarios}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      params,
    })
      .then((response) => {
        const result = response.data.data.concesionarios;
        resolve(result);
      })
      .catch((e) => reject(e.response.data));
  });
};

export const GetLocations = ({ getters }) => {
  let path = `${getters.GetEndpointComunas}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
    })
      .then((response) => {
        const result = response.data.data.result.map((it) => {
          return {
            label: it.comuna_descripcion,
            value: it.comuna,
          };
        });
        resolve(result);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
};

<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    scrollable
  >
    <v-card>
      <v-card-title>
        Configuración
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small fab text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list two-line>
          <v-list-item-group color="primary">
            <!-- CLIENTE -->
            <v-list-item v-for="(i, index) in items" :key="index">
              <v-list-item-icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="i.text"> </v-list-item-title>
                <v-list-item-subtitle v-text="i.value"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="i.action">
                <v-btn
                  color="primary"
                  fab
                  text
                  x-small
                  @click="i.action(i)"
                  :loading="i.loadingAction"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
    <consultar-plantilla
      v-model="templatePreview"
      @close="templatePreview = false"
      :item="dataPlantilla"
    />
  </v-dialog>
</template>

<script>
import ConsultarPlantilla from "@/views/Manager/notificaciones/forms/consultar-plantilla.vue";

import PlantillaService from "@/services/manager/notifications/plantillas.service.js";
const plantillaService = new PlantillaService();
export default {
  name: "PreviewConfigModal",
  components: {
    ConsultarPlantilla,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },

    processes: {
      type: Array,
      default: () => [],
    },
    convenios: {
      type: Array,
      default: () => [],
    },
    templates: {
      type: Array,
      default: () => [],
    },
    operations: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Array,
      default: () => [],
    },
    deliveryList: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    templatePreview: false,
    dataPlantilla: null,
  }),
  computed: {
    items() {
      return [
        {
          text: "Cliente",
          value:
            this.clients.find((e) => e.value == this.item?.client)?.text ??
            this.item?.client ??
            "",
          action: null,
          loadingAction: false,
        },
        {
          text: "Convenio",
          value:
            this.convenios.find((e) => e.value == this.item?.convenio)?.text ??
            this.item?.convenio ??
            "",
          action: null,
          loadingAction: false,
        },
        {
          text: "Plantilla",
          value:
            this.templates.find((e) => e._id == this.item?.plantilla)?.nombre ??
            "",
          action: this.modelTemplate,
          loadingAction: false,
        },
        {
          text: "Proceso",
          value:
            this.processes.find((e) => e.value == this.item?.proceso)?.text ??
            this.item?.proceso ??
            "",
          action: null,
          loadingAction: false,
        },
        {
          text: "Operación",
          value:
            this.operations.find((e) => e.value == this.item?.operacion)
              ?.text ??
            this.item?.operacion ??
            "",
          action: null,
          loadingAction: false,
        },
        {
          text: "Estado",
          value:
            this.status.find((e) => e.value == this.item?.estado)?.text ??
            "Desconocido",
          action: null,
          loadingAction: false,
        },
        {
          text: "Tipo de envío",
          value:
            this.deliveryList.find((e) => e.value == this.item?.tipoEnvio)
              ?.text ??
            this.item?.tipoEnvio ??
            "",
          action: null,
          loadingAction: false,
        },
      ];
    },
  },
  methods: {
    modelTemplate(item) {
      item.loadingAction = true;
      plantillaService
        .getIdPlantillas(this.item.plantilla)
        .then((resp) => {
          this.dataPlantilla = resp.data.data.data;
          this.templatePreview = true;
        })
        .finally(() => {
          item.loadingAction = false;
        });
    },
  },
};
</script>

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import {
  baseKeys,
  companyKeys,
  generalInfoKeys,
  advanceInfoKeys,
  advanceConfigKeys,
  productKeys,
} from "@/store/resources/channelkeys.js";

import * as messages from "@/assets/lang/es/messages.json";

export default {
  namespaced: true,
  state: {
    channels: [],
    accessUrls: [],
    generalInfo: {
      countries: [],
      channelsType: [],
      companies: [],
      paymentMethods: [],
    },
    advanceInfo: {
      signature: [],
      inspections: {
        business: [],
        types: [],
      },
    },
    advanceConfig: {},
    products: [],
    users: {
      list: [],
    },
    keys: {
      base: baseKeys,
      company: companyKeys,
      generalInfo: generalInfoKeys,
      advanceInfo: advanceInfoKeys,
      advanceConfig: advanceConfigKeys,
      products: productKeys,
    },
    messages: messages.data.channel,
  },
  actions,
  mutations,
  getters,
};

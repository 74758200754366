<template>
  <v-container>
    <v-card :loading="loadingCountries">
      <v-card-title primary-title>
        <v-autocomplete
          label="País"
          placeholder="Seleccione un país..."
          v-model="countrySelected"
          :loading="loadingCountries"
          :items="Countries"
          hide-details
          item-disabled="eliminado"
          item-text="pais_descripcion"
          item-value="codigo"
        ></v-autocomplete>
        <v-divider v-bind="bind.divider"></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          v-bind="bind.refresh"
          v-on="on.refresh"
          :loading="loadingCountries"
        >
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

    <!-- REGIONES -->
    <h3 class="text-h6 mt-6 mb-2">Información del Territorio</h3>

    <v-card :loading="loading">
      <v-card-title>
        <v-text-field
          v-model="search"
          v-bind="bind.search"
          label="Buscar en el listado"
          dense
          placeholder="Escriba el nombre de un elemento para buscar..."
        ></v-text-field>
        <v-divider v-bind="bind.divider"></v-divider>
        <p class="mb-0 subtitle-2">
          <span>{{ textLabels.state }}</span
          ><v-icon>mdi-menu-right</v-icon> <span>{{ textLabels.district }}</span
          ><v-icon>mdi-menu-right</v-icon>
          <span>{{ textLabels.city }}</span>
        </p>
        <v-spacer></v-spacer>
        <v-btn
          v-bind="bind.refresh"
          :outlined="false"
          text
          @click="handlerSelectCountry(countrySelected)"
        >
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
        <v-btn v-bind="bind.new" text small v-on="on.new" :disabled="error"
          >Nuevo elemento <v-icon right>mdi-plus</v-icon></v-btn
        >
      </v-card-title>

      <v-fade-transition mode="out-in">
        <template v-if="items.length > 0">
          <v-treeview
            :search="search"
            :items="items"
            hoverable
            item-children="elementos"
            item-text="nombre"
            item-key="_id"
            return-object
            open-on-click
          >
            <template #append="{ item }">
              <v-btn v-bind="bind.actions" v-on="on.edit(item)"
                ><v-icon>{{ icons.edit }}</v-icon></v-btn
              >
              <v-btn color="error" v-bind="bind.actions" v-on="on.delete(item)"
                ><v-icon>{{ icons.delete }}</v-icon></v-btn
              >
            </template>
          </v-treeview>
        </template>
        <v-card-text class="text-center" v-else>
          No hay elementos para mostrar
        </v-card-text>
      </v-fade-transition>
    </v-card>

    <confirm-modal :ref="refs.confirm" />
    <Form
      v-model="dialog"
      v-bind="bind.form"
      v-on="on.form"
      :text-labels="textLabels"
      :item-list="items"
      @set-level="lvl = $event"
    />
  </v-container>
</template>

<script>
import PageMixin from "@/Mixins/SetPageMixin.js";
import { mapGetters, mapActions, mapMutations } from "vuex";

import Form from "@/components/Forms/cruds/Territorios.vue";
export default {
  name: "CrudTerritorios",
  mixins: [PageMixin],
  data: () => ({
    countrySelected: null,
    loadingCountries: false,
    lvl: 1,
    error: false,
  }),
  components: {
    Form,
  },
  watch: {
    countrySelected: function (val) {
      this.handlerSelectCountry(val);
    },
  },
  computed: {
    ...mapGetters("Territorios", [
      "Items",
      "Countries",
      "GetText",
      "GetCountry",
    ]),
    items() {
      return this.Items;
    },
    textLabels() {
      return this.GetText?.[this.countrySelected ?? "CL"];
    },
  },
  methods: {
    ...mapMutations("Territorios", ["setCountry"]),
    ...mapActions("Territorios", [
      "GET_COUNTRIES_LIST",
      "GET",
      "POST_STATE",
      "POST_DISTRICT",
      "POST_CITY",
      "PUT_STATE",
      "PUT_DISTRICT",
      "PUT_CITY",
      "DELETE_STATE",
      "DELETE_DISTRICT",
      "DELETE_CITY",
    ]),

    async setup() {
      this.loadingCountries = true;
      this.countrySelected = null;
      try {
        await this.GET_COUNTRIES_LIST();
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loadingCountries = false;
      if (this.Countries.length > 0 && !this.countrySelected) {
        this.countrySelected = this.GetCountry || this.Countries[0].codigo;
      }
    },
    async handlerSelectCountry(value = "") {
      const item = value || this.countrySelected;
      if (item) {
        this.setCountry(item);
        this.loading = true;
        try {
          await this.GET(item);
          this.error = false;
        } catch (error) {
          this.error = true;
          this.snackbar(error.info);
        }
        this.loading = false;
      }
    },

    setFormData(data) {
      const {
        codigo_pais,
        codigo_region,
        codigo_provincia,
        nivel,
        ...payload
      } = data;
      const formData = {
        data: payload,
      };

      switch (nivel) {
        case 1:
          formData.codigo_pais = codigo_pais || this.countrySelected;
          break;
        case 2:
          formData.codigo_region = codigo_region;
          break;
        case 3:
          formData.codigo_provincia = codigo_provincia;
          break;
      }

      return formData;
    },

    saveMethod() {
      let Method;
      switch (this.lvl) {
        case 1:
          Method = this.POST_STATE;
          break;
        case 2:
          Method = this.POST_DISTRICT;
          break;
        case 3:
          Method = this.POST_CITY;
          break;
      }
      return Method;
    },

    updateMethod() {
      let Method;
      switch (this.selected?.nivel) {
        case 1:
          Method = this.PUT_STATE;
          break;
        case 2:
          Method = this.PUT_DISTRICT;
          break;
        case 3:
          Method = this.PUT_CITY;
          break;
      }
      return Method;
    },

    deleteItem(
      item,
      title = "Eliminar territorio",
      description = "Esta a punto de eliminar parte de un territorio del listado ¿Desea proceder?"
    ) {
      const method = this.deleteMethod(item);
      this.confirm({
        title,
        description,
      }).then((answer) => {
        if (answer) {
          item.loading = true;
          method(this.deleteId(item))
            .then((response) => {
              this.snackbar(response.info, "success");
              this.setup();
            })
            .catch((error) => {
              this.snackbar(this.handlerGetError(error));
            })
            .finally(() => {
              item.loading = false;
            });
        }
      });
    },

    deleteId(item) {
      let data;
      switch (item?.nivel) {
        case 1:
          data = {
            pais: item.codigo_pais || this.countrySelected,
            region: item._id,
          };
          break;
        case 2:
          data = {
            region: item.codigo_region,
            provincia: item._id,
          };
          break;
        case 3:
          data = {
            provincia: item.codigo_provincia,
            comuna: item._id,
          };
          break;
      }
      return data;
    },

    deleteMethod(item = null) {
      let Method;
      switch (item?.nivel) {
        case 1:
          Method = this.DELETE_STATE;
          break;
        case 2:
          Method = this.DELETE_DISTRICT;
          break;
        case 3:
          Method = this.DELETE_CITY;
          break;
      }
      return Method;
    },
  },
};
</script>

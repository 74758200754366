<template>
  <div class="text-center">
    <v-dialog
      max-width="500"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario de Módulos
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre"
                  :rules="[(v) => !!v || 'El campo Nombre es requerido']"
                  v-model="form.nombre"
                ></v-text-field>
                <v-text-field
                  label="Slug"
                  :rules="[(v) => !!v || 'El campo Slug es requerido']"
                  v-model="form.slug"
                  @blur="slug"
                ></v-text-field>
                <v-switch label="Activo" v-model="form.esActivo"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :loading="loading" @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const slugify = require("slugify");

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        nombre: "",
        slug: "",
        esActivo: false,
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },
    setForm() {
      this.form.nombre = this.formData?.nombre ?? "";
      this.form.slug = this.formData?.slug ?? "";
      this.form.esActivo = this.formData?.es_activo === "Si" ? true : false;
    },
    reset() {
      this.form.nombre = "";
      this.form.slug = "";
      this.form.esActivo = false;
      this.$refs.form.resetValidation();
    },
    slug() {
      this.form.slug = slugify(this.form.slug, { lower: true });
    },
  },
};
</script>

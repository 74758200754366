// import Manager from "./managerPages";

export default [
  // MODULOS DE NEGOCIOS
  {
    ref: "1-1",
    title: "Módulos de Negocios",
    type: "title",
  },
  {
    // Caja de caracteristicas del canal
    ref: "03",
    path: "ChannelFeature",
    disabled: false,
    title: "Características del Canal",
    name: "Canal",
    text: "Administra toda la información de un canal o sucursal desde un solo lugar.",
    type: "box",
    icons: {
      menu: "home",
      index: "home-city-outline",
    },
  },
  {
    // Caja de personalizacion corporativa
    ref: "04",
    path: "Customization",
    disabled: false,
    title: "Personalización Corporativa",
    name: "Personalización",
    text: "Crea y gestiona diversos temas corporativos para personalizar los canales.",
    type: "box",
    icons: {
      menu: "shape",
      index: "compare",
    },
  },
  {
    // Caja de configuracion de usuarios
    ref: "10",
    path: "UserConfig",
    disabled: false,
    title: "Administración de usuarios",
    name: "Usuarios",
    text: "Crea y gestiona los usuarios de la plataforma de ventas.",
    type: "box",
    icons: {
      menu: "account-multiple",
      index: "account-group-outline",
    },
  },

  //CONFIGURACION
  {
    ref: "2-1",
    type: "divider",
  },
  {
    ref: "1-2",
    title: "Configuración",
    type: "title",
  },
  {
    // Caja de mantenedores
    ref: "20",
    path: "Mantenedores",
    disabled: false,
    title: "Gestor de contenido",
    name: "Mantenedores",
    text: "Gestionar el contenido y las herramientas de negocios (Mantenedores).",
    type: "box",
    icons: {
      menu: "wrench-cog-outline",
      index: "table-cog",
    },
  },
  {
    // Caja de Integración
    ref: "30",
    path: "Integraciones",
    disabled: false,
    title: "Gestor de integraciones",
    name: "Integraciones",
    text: "Gestionar todo lo relacionado a las integraciones con diferentes sistemas.",
    type: "box",
    icons: {
      menu: "integrated-circuit-chip",
      index: "integrated-circuit-chip",
    },
  },
  {
    // Caja de ManagerClick
    ref: "40",
    path: "ManagerMain",
    disabled: false,
    title: "Gestor ManagerClick",
    name: "Manager",
    text: "Gestionar todos los módulos del sistema anteriormente llamado ManagerClick.",
    type: "box",
    // items: Manager,
    icons: {
      menu: "shield-crown-outline",
      index: "shield-crown",
    },
  },
];

// Getters generales del modulo de usuarios
export const GetUsers = (state) => {
  return state.users; // Obtener listado de todos los usuarios
};

// Metodos de obtener textos para mensajes o notificaciones
export const GetUserMessages = (state) => {
  return state.messages;
};

// Metodos para obtener llaves claves
export const GetUserKeys = (state) => {
  return state.keys.base;
};

export const GetUserState = (state) => {
  return state.state;
};

export const GetUserPosition = (state) => {
  return state.position;
};

import Integracion from "@/views/Integracion/index.vue";
import HomologacionesForum from "@/views/Integracion/Forum/Homologaciones.vue";
import OperationForum from "@/views/Integracion/Forum/Operations.vue";
import QuotDownload from "@/views/Integracion/Forum/QuotDownload.vue";

export const routes = [
  {
    path: "integracion",
    name: "Integraciones",
    component: Integracion,
    meta: {
      page: "30",
    },
  },
  {
    path: "homologacionesForum",
    name: "HomologacionesForum",
    component: HomologacionesForum,
    meta: {
      page: "501",
    },
  },
  {
    path: "/integraciones/forum/operaciones",
    name: "OperacionesForum",
    component: OperationForum,
    meta: {
      page: "502",
    },
  },
  {
    path: "/integraciones/forum/descargar-simulaciones",
    name: "QuotDownloadForum",
    component: QuotDownload,
    meta: {
      page: "503",
    },
  },
];

export default routes;

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
import * as TextLabels from "@/views/Manager/configurador/text.json";

import { endpoints } from "@/helpers/endpoints.js";

export default {
  namespaced: true,
  state: {
    items: [],
    countries: [],
    country: "",
    endpoint: endpoints.manager.territorios,
    text: TextLabels.data,
  },
  actions,
  mutations,
  getters,
};

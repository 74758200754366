<template>
  <div class="text-center">
    <v-dialog
      width="500"
      :persistent="loading"
      :value="value"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card>
        <v-card-title>
          Formulario de Tasa de Cambio

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            x-small
            fab
            @click="$emit('close')"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-autocomplete
              class="mt-3"
              v-model="form.monedaOrigen"
              :items="moneyItems"
              item-text="moneda_descripcion"
              item-value="codigo"
              label="Moneda Origen"
              :rules="[(v) => !!v || 'Debe seleccionar una moneda de origen.']"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.monedaDestino"
              :items="moneyItems"
              item-text="moneda_descripcion"
              item-value="codigo"
              label="Moneda Destino"
              :rules="[(v) => !!v || 'Debe seleccionar una moneda de destino.']"
            ></v-autocomplete>
            <v-text-field
              label="Fecha"
              v-model="form.fecha"
              :rules="[(v) => !!v || 'Debe especificar una fecha.']"
              v-mask="dateMaskNewFormat"
            ></v-text-field>
            <v-text-field
              label="Valor"
              v-model="form.valor"
              :rules="[
                (v) => !!v || 'Debe especificar un valor para la moneda.',
              ]"
              type="number"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :loading="loading" @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { valorMask, dateMaskNewFormat } from "@/store/resources/masks.js";

export default {
  name: "ModalTasaCambio",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    moneyItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: true,
      form: {
        monedaOrigen: null,
        monedaDestino: null,
        fecha: "",
        valor: null,
      },
    };
  },
  watch: {
    value: function (newVal) {
      if (newVal && this.formData) {
        this.setForm();
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },

    setForm() {
      this.form.monedaDestino = this.formData.codigo_moneda_destino;
      this.form.monedaOrigen = this.formData.codigo_moneda_origen;
      this.form.fecha = this.formData.fecha;
      this.form.valor = this.formData.valor;
    },

    reset() {
      this.form.monedaDestino = null;
      this.form.monedaOrigen = null;
      this.form.fecha = "";
      this.form.valor = null;
      this.$refs.form.reset();
    },
  },
  computed: {
    valorMask: () => valorMask,
    dateMaskNewFormat: () => dateMaskNewFormat,
  },
};
</script>
